import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';

import { Box, Heading, HStack, Image, Stack, Text, Flex, Spacer, Icon, Tabs, TabList, Tab, useBreakpointValue, Button, TabPanels, TabPanel, Grid, Link, Spinner, Center} from '@chakra-ui/react';
import { FaInstagram, FaFacebook, FaTwitter, FaLink } from 'react-icons/fa'
import { useNavigate, useLocation } from 'react-router-dom';
import UserHomeRecent from './UserHomeRecent';
import UserHomePopular from './UserHomePopular';
import UserPosts from './UserPosts';
import UserArchives from './UserArchives';
import UserPurchased from './UserPurchased';
import UserLikes from './UserLikes';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';

// const endpoint = "https://nextswan.com";

function User (props) {
    const [profile, setProfile] = React.useState({
        userName: "",
        userId: "",
        description: "",
        twitterLink: "",
        instagramLink: "",
        facebookLink: "",
        otherLink: "",
        favorite: 0
    });
    const [UserIcon, setUserIcon] = React.useState("https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png")
    const [isme, setIsme] = useState(false);
    const [loading, setLoading] = useState(true);

    //URLのパスを取得する。現状'/yuji'のような形で取得される
    // コンソールで順番試してみたところuseLocationはレンダリング中に発生するぽい & useEffectが起きた後にも起きるぽい
    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        const access_db = async () => {
            const userid = location.pathname.split("/")[1];
            const data = new FormData();
            data.append("user_id", userid);
            
            await axios.post('/api/user_profile/', data, { withCredentials: true })
                .then(res => {
                    if (res.request.status === 200) {
                        if (res.data.error) {
                            alert("ユーザが存在しません");
                            return 0;
                        }
                        setProfile({
                                userName: res.data.username,
                                userId: res.data.user_id,
                                description: res.data.introduction,
                                twitterLink: res.data.SNS_Twitter,
                                instagramLink: res.data.SNS_Instagram,
                                facebookLink: res.data.SNS_Facebook,
                                otherLink: res.data.SNS_Other,
                                favorite: res.data.favorite,
                        });
                        setIsme(res.data.is_me);
                        if (res.data.icon) setUserIcon("https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/"+res.data.user_uuid+res.data.icon+"?"+new Date().getTime());
                     }
                })
                .finally(() => setLoading(false));
            }
        access_db();
    }, [])

    const navigate = useNavigate();
    //ボタンのレスポンシブに使用する
    const buttonSize = useBreakpointValue(['sm', 'sm', 'md']);
    const headingSize = useBreakpointValue(['md', 'md', 'md', 'lg']);
    const isMobile = useBreakpointValue({ base: true, md: false });

    if (loading) {
        return(
            <div>
                <HeaderPlane />
                <Center h="100vh">
                    <Spinner 
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='pink.500'
                    size='xl'
                    />
                </Center>
            </div>
        )
    }else{
        return(
        <>
            <HeadBlock title={profile.userName} description={profile.userName + 'のユーザーページ'} />
            <HeaderPlane />
            <Box mt={8} mx={{base:'20px', md:'100px'}} mb="10px">
                 <Flex>
                    <Stack>
                        <HStack spacing={{base:2, lg:5 }}>
                            <Box minW={{base: '75px', sm: '75px', md: '80px'}}>
                                <Image borderRadius="full" src={UserIcon} boxSize={{base:'75px', sm:'75px', md: '80px'}} style={{ objectFit: 'cover'}} mr={1}/>
                            </Box>
                            { !isMobile && 
                                <Heading as="h2" size={headingSize} pl={2} >
                                    { profile.userName }
                                </Heading>
                            }
                            <Spacer />
                            <Box minW={{base: '230px', sm: '340px', md: '160px'}}>
                            { isme && 
                                <Button
                                    display='flex'
                                    justifyContent='flex-end'
                                    bg={"#E9B5D2"}
                                    color={'white'}
                                    colorScheme='pink'
                                    variant='solid'
                                    size={buttonSize}
                                    onClick={() => navigate('/' + profile.userId + '/edit')}
                                    _focus={{ boxShadow: "none"}}
                                >プロフィール編集</Button>
                            }
                            </Box>
                        </HStack>
                        { isMobile && 
                            <Heading as="h2" size={headingSize} maxW='80vw' pt={2}>
                                { profile.userName }
                            </Heading>
                        }
                        <Text fontSize={headingSize} color='gray.400'>
                            @{ profile.userId }
                        </Text>
                        <Text fontSize={headingSize} maxW='80vw' color='gray.600'>
                            { profile.description }
                        </Text>
                        <Flex w='flex' pb={1}>
                            <Text color='gray' mr={1}>いいね</Text>
                            <Heading size="sm" mt='2px' >{profile.favorite}</Heading>
                            <Spacer w='5px'/>
                            {/* リンクの登録があれば該当のSNSアイコンを出す */}
                            <HStack spacing={{base:2, md:'10px'}} pb={3}>
                            { profile.twitterLink && 
                                <Link href={profile.twitterLink} _focus={{ boxShadow: "none"}}>
                                    <Icon as={FaTwitter} color='#606060' w={{base:5, md:6}} h={{base:5, md:6}} />
                                </Link> 
                            }
                            { profile.instagramLink && 
                                <Link href={profile.instagramLink} _focus={{ boxShadow: "none"}}>
                                    <Icon as={FaInstagram} color='#606060' w={{base:5, md:6}} h={{base:5, md:6}} />
                                </Link> 
                            }
                            { profile.facebookLink && 
                                <Link href={profile.facebookLink} _focus={{ boxShadow: "none"}}>
                                    <Icon as={FaFacebook} color='#606060' w={{base:5, md:6}} h={{base:5, md:6}} />
                                </Link>
                            }
                            { profile.otherLink && 
                                <Link href={profile.otherLink} _focus={{ boxShadow: "none"}}>
                                    <Icon as={FaLink} color='#606060' w={{base:5, md:6}} h={{base:5, md:6}} />
                                </Link> 
                            }
                            </HStack>
                            <Spacer/>
                        </Flex>
                    </Stack>
                </Flex>
                {/* <hr /> */}
                <Tabs align='left' colorScheme='pink'>
                    <TabList overflowX='auto' flexWrap='nowrap' h='44px'>
                        <Tab h='42px' whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>ホーム</Tab>
                        <Tab h='42px' whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>投稿</Tab>
                        {/* 複数をまとめたい */}
                        { isme && <Tab h='42px' whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>非公開</Tab> }
                        { isme && <Tab h='42px' whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>購入済み</Tab> }
                        { isme && <Tab h='42px' whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>いいね</Tab> }
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Heading as="h3" size="md" pt={{base: 4, lg: 7}} pb={{base: 3, lg: 6}} >最近の投稿</Heading>
                            <Grid
                                justifyItems={{base: 'left', lg: 'center'}}
                                alignItems={'center'}
                                overflowX='auto'
                                flexWrap='nowrap'
                                templateColumns={{base: 'repeat(0, 1fr)', lg: 'repeat(5, 1fr)'}}
                                gap='auto'
                            >
                                <UserHomeRecent/>
                            </Grid>
                            <Spacer />
                            <Heading as="h3" size="md" pt={{base: 4, lg: 7}} pb={{base: 3, lg: 6}} >人気の投稿</Heading>
                            <Grid
                                justifyItems={{base: 'left', lg: 'center'}}
                                alignItems={'center'}
                                overflowX='auto'
                                flexWrap='nowrap'
                                templateColumns={{base: 'repeat(0, 1fr)', lg: 'repeat(5, 1fr)'}}
                                gap='auto'
                            >
                                <UserHomePopular/>
                            </Grid>
                        </TabPanel>
                        <TabPanel>
                            <Heading as="h3" size="md" pt={{base: 4, lg: 7}} pb={{base: 3, lg: 6}} >投稿一覧</Heading>
                            <Grid
                                justifyItems={{base: 'left', lg: 'center'}}
                                alignItems={'center'}
                                overflowX='auto'
                                flexWrap='nowrap'
                                templateColumns={{base: 'repeat(0, 1fr)', lg: 'repeat(5, 1fr)'}}
                                gap='auto'
                            >
                                <UserPosts/>
                            </Grid>
                        </TabPanel>
                        <TabPanel>
                            <Heading as="h3" size="md" pt={{base: 4, lg: 7}} pb={{base: 3, lg: 6}} >非公開にした投稿</Heading>
                            <Grid
                                justifyItems={'center'}
                                alignItems={'center'}
                                overflowX='auto'
                                flexWrap='nowrap'
                                templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(5, 1fr)'}}
                                gap='auto'
                            ><UserArchives />
                            </Grid>
                        </TabPanel>
                        <TabPanel>
                            <Heading as="h3" size="md" pt={{base: 4, lg: 7}} pb={{base: 3, lg: 6}} >購入済みの投稿</Heading>
                            <Grid
                                justifyItems={'center'}
                                alignItems={'center'}
                                overflowX='auto'
                                flexWrap='nowrap'
                                templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(5, 1fr)'}}
                                gap='auto'
                            ><UserPurchased />
                            </Grid>
                        </TabPanel>
                        <TabPanel>
                            <Heading as="h3" size="md" pt={{base: 4, lg: 7}} pb={{base: 3, lg: 6}} >いいねした投稿</Heading>
                            <Grid
                                justifyItems={'center'}
                                alignItems={'center'}
                                overflowX='auto'
                                flexWrap='nowrap'
                                templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(5, 1fr)'}}
                                gap='auto'
                            ><UserLikes />
                            </Grid>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </>
    )};
}

export default User;