import React, { memo, useState, useEffect} from "react";
import axios from 'axios'
import {
    Stack,
    Link,
    Flex,
    Image,
    HStack,
    useBreakpointValue,
    Button,
    Box,
    Icon,
    Spacer,
    VStack,
    Text,
    Center
} from "@chakra-ui/react";
import { Link as ReachLink, useNavigate } from "react-router-dom";
import Logo1 from "../../../images/nextswanLogo.png";
import SettingsButton from "../../atoms/button/SettingsButton";
import { FaSearch } from 'react-icons/fa';
// const endpoint = "https://nextswan.com";

export const HeaderPlane = memo((props) => {
    localStorage.setItem("is_login", "false");
    const [login, setLogin] = useState(JSON.parse(localStorage.getItem("is_login").toLowerCase()));
    const [userId, setUserId ] = useState("");
    const buttonSize = useBreakpointValue(['sm', 'sm', 'md']);
    const isMobile = useBreakpointValue({ base: true, lg: false });
    const navigate = useNavigate();
    const splitPath = window.location.pathname.slice(1);

    useEffect(() => {
        const is_login = async () => {
            
            await axios.get('/api/is_login/', {  withCredentials: true})
                .then(res => {
                    setLogin(!res.data.is_authenticated);
                    setUserId(res.data.id)
                    // console.log(res.data);
                    localStorage.setItem("is_login", !res.data.is_authenticated);
                });
        }
        is_login();
    }, [])

    const create_post = async () => {
        await axios.get('/api/create-post/', { withCredentials: true })
        .then(res => {
            if (res.request.status === 200) {
                navigate('/edit/' + res.data.post_id + '/');
            }
        });
    }

    const userClick = () => {
        navigate('/' + userId);
        window.location.reload(); // 他人のページから自分のページへ行く時、変化しないので救済措置的にreload()を行う
    }

    return (
        <>
        <HStack>
            <Flex
                as="nav"
                align="center"
                justify="space-between"
                w="100%"
                padding={{base: 1, md: 2}}
                bg="white"
                color="black"
                boxShadow='inset 0 -2px rgba(0, 0, 0, 0.05)'
                borderBottom='0 "CCC" 0 0 rgba(0, 0, 0, 0.05)'
                {...props}
            >
                <Flex align="center" ml={-8} >
                    <Link as={ReachLink} to="/" _focus={{ boxShadow: "none"}} >
                        <Image src={Logo1} alt="" width={{ base: 209, md: 313 }} height={{ base: '40px', md: '60px' }}/>
                    </Link>
                </Flex>
                <Stack
                    direction={{ base: "column", md: "row" }}
                    width={{ base: "auto", md: "auto" }}
                    alignItems="center"
                    flexGrow={1}
                    mt={{ base: 4, md: 0 }}
                >
                </Stack>
                {login ? (
                    <HStack>
                        <Link href='/s' _focus={{ boxShadow: "none"}}>
                            <Icon as={FaSearch} mt='6px' width={{ base: 7, md: 9 }} height={{ base: 7, md: 9 }} p='2px' color="#CC5083" />
                        </Link>
                        <Spacer/>
                        <Link  align="center" pr={{ base: 1, md: 2 }} onClick={() => navigate('/login?next=' + splitPath)} _focus={{ boxShadow: "none"}}
                            >ログイン
                        </Link>
                        <Button
                            bg={"#CC5083"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            align="center"
                            onClick={() => navigate('/signup?next=' + splitPath)}
                            size={buttonSize}
                            _focus={{ boxShadow: "none"}}
                        >会員登録
                        </Button>
                    </HStack>
                ) : (
                    <HStack>
                        <Link href='/s' _focus={{ boxShadow: "none"}}>
                            <Icon as={FaSearch} mt='6px' width={{ base: 7, md: 9 }} height={{ base: 7, md: 9 }} p='2px' color="#CC5083" />
                        </Link>
                        <Box cursor='pointer'>
                            <SettingsButton onClick={userClick}/>
                        </Box>
                        <Button
                            bg={"#CC5083"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            align="center"
                            size={buttonSize}
                            onClick={create_post}
                            _focus={{ boxShadow: "none"}}
                        >投稿
                        </Button>
                    </HStack>
                )}
            </Flex>
        </HStack>
        { isMobile ? (
            <VStack spacing={0} bgColor='pink.100' borderBottomColor='black' mb={1} >
                <Text fontSize='xs' pt='1px' >Nextswanは2023年10月末をもってサービスを終了します。ご愛顧ありがとうございました。</Text>
            </VStack>
            ) : (
            <Center bgColor='pink.100' mb={1} boxShadow='inset 0 0px rgba(0, 0, 0, 0.05)' borderBottom='0 "CCC" 0 0 rgba(0, 0, 0, 0.05)' >
                <Text fontSize='xs' pt='1px' >
                    Nextswanは2023年10月末をもってサービスを終了します。ご愛顧ありがとうございました。
                </Text>
            </Center>
            )
        }
        </>
    );
});

