import React from 'react';
import { Tab, TabList, Tabs, Box, TabPanels, TabPanel, Center, Spinner} from '@chakra-ui/react';
import ReleasedBox from './ReleasedBox';
import DraftBox from './DraftBox';

export default function PostStateTabs(props) {
    return(
        <Box paddingBottom="30px" overflow='auto' >
            {/* <Switch size='md' onChange={handleChange} />ログイン */}
            <Tabs isFitted defaultIndex={props.number} isManual align='center' w={{base:'335px' , md:'450px'}} colorScheme='pink' overflowX='auto' flexWrap='nowrap'>
                <TabList>
                    {/* {user ? <Tab as={ReachLink} to="/">おすすめ</Tab> : '' } */}
                    <Tab whiteSpace='nowrap' number={0} _focus={{ boxShadow: "none"}}>公開</Tab>
                    <Tab whiteSpace='nowrap' number={1} _focus={{ boxShadow: "none"}}>下書き</Tab>
                </TabList>
                { props.loading ? (
                    <Center h="100vh">
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='pink.500'
                            size='xl'
                        />
                    </Center>
                ) : (
                <TabPanels>
                    <TabPanel>
                        {props.public_posts.map((public_post) => (
                            <ReleasedBox title={public_post.title} postDate={public_post.postDate} postid={public_post.postId} />
                        ))}
                    </TabPanel>
                    <TabPanel>
                        {props.draft_posts.map((draft_post) => (
                            <DraftBox title={draft_post.title} postDate={draft_post.postDate} postid={draft_post.postId} />
                        ))}
                    </TabPanel>
                </TabPanels>
                )}
            </Tabs>
        </Box>
    );
};