import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Heading, HStack, Input, InputGroup, InputRightElement, Link, Select, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { CheckCircleIcon, ViewIcon, ViewOffIcon, WarningIcon } from '@chakra-ui/icons';
import { Link as ReachLink, useNavigate } from 'react-router-dom';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import { AboutSignup, AboutPoint, AboutStep } from '../about/About';

// const endpoint = "https://nextswan.com";

export default function LogIn() {
    const [show, setShow] = useState(false);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [check, setCheck] = React.useState(false);
    const [next, setNext] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const [year, setYear] = useState(2000);
    const [month, setMonth] = useState(1);
    const [day, setDay] = useState(1);
    const [max_day, setMax_day] = useState([31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]);
    const [days, setDays] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]);
    const [isR18, setIsR18] = useState(false);
    const [checkR18, setCheckR18] = useState(true);

    React.useEffect(() => {
        const url = new URL(window.location.href);
        const params = url.searchParams;
        setNext(params.get('next'));
    }, []);



    const years = [];
    let now = new Date()
    for (let i=1900;i<=now.getFullYear();i++){
        years.push(i);
    }

    const check_birth = (y, m, d) => {
        let birth_int = Number(String(y) + String("0" + m).slice(-2) + String("0" + d).slice(-2));
        let now_int = Number(now.getFullYear()) + ("0" + (now.getMonth() + 1)).slice(-2) + ("0" + (now.getDate())).slice(-2);
        let age = (now_int - birth_int) / 10000;
        if (age < 18) {
            setIsR18(true);
            setCheckR18(false);
        }
        else if (age >= 18) {
            setIsR18(false);
            setCheckR18(true);
        }
    }

    const handleClick = () => setShow(!show);
    const navigate = useNavigate();

    const emailchange = (e) => {
        setEmail(() => e.target.value);
    }

    const change_R18 = (e) => {
        setCheckR18(!checkR18);
    }

    const change_check = (e) => {
        setCheck(!check);
    }

    const [availablePassword, setAvailablePassword] = React.useState({
        passwordLength: false, /*最低8文字*/
        common: false, /*よく使われる*/
        onlyNumber: false, /*数字のみ*/
        familiarEmail: false /*email(&ユーザー名)と似ている*/
    })

    const enterClick = (e) => {
        if (e.key === 'Enter') {
            send_data();
        }
    }

    const passwordchange = (e) => {
        let password = e.target.value
        setAvailablePassword({
        passwordLength: password.length >= 8,
        common: true,
        onlyNumber: isNaN(password),
        familiarEmail: true,
        });
        setPassword(() => password);
    }

    const change_year = (e) => {
        if ((e.target.value % 4 === 0 && e.target.value % 100 !== 0) ||  e.target.value % 400 === 0) {
            setMax_day([31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]);
        }
        setYear(e.target.value);
        check_birth(e.target.value, month, day);
    }

    const change_month = (e) => {
        let temp_days = [];
        for (let i=1;i<=max_day[Number(e.target.value)];i++) temp_days.push(i);
        setDays(temp_days);
        setMonth(Number(e.target.value) + 1);
        check_birth(year, Number(e.target.value) + 1, day);
    }

    const change_day = (e) => {
        setDay(e.target.value);
        check_birth(year, month, e.target.value);
    }

    const to_login = () => {
        if (next) navigate('/login?next=' + next);
        else navigate('/login');
    }

    const send_data = async () => {
        if (availablePassword.passwordLength && availablePassword.onlyNumber && check && checkR18) {
            setLoading(true);

            const data = new FormData();
            data.append("email", email);
            data.append("password1", password);
            data.append("password2", password);
            data.append('year', year);
            data.append('month', month);
            data.append('day', days);
            await axios.post('/api/signup/', data)
            .then(res => {
                if (res.request.status === 201) {
                    axios.get('/api/mypage/', { withCredentials: true})
                    .then(res => {
                        if (res.request.status === 200) {
                            localStorage.setItem('myuserid', res.data.userid);
                            if (next==='login') navigate('/');
                            else if (next) navigate('/' + next + '/');
                            else navigate('/' + res.data.userid + '/');
                        };
                    });
                }
            })
            .catch(res => {
                if (res.request.status === 400) {
                    alert('メールアドレスが無効/すでに使われているか、パスワードが安全ではありません。');
                }
            })
            .finally(() => setLoading(false));
        }
    }

    const isMobile = useBreakpointValue({ base: true, lg: false })
    return(
        <Box >
            <HeaderPlane/>
            <Flex justify="center" height="flex" mx={{base: '10px', md: '20px', xl: '100px'}} >
                <HStack spacing={76}>
                    { isMobile ? ""
                    :
                        <Stack maxW='500px' top={0} >
                            <AboutSignup titleSize='md' /*titleW='470px'*/ fontSize='md' compact={true} />
                        </Stack>
                    }
                    <VStack spacing={6} py={4} px={3} align='stretch' w={{base: '98vw', sm: '450px'}}>
                        <Box h='40px' />
                        <Heading as="h1" size="lg" textAlign="center">
                            新規登録
                        </Heading>
                        <FormControl isRequired>
                            <FormLabel htmlFor='email'>メールアドレス</FormLabel>
                            <Input id='email' type='email' onChange={emailchange} value={email} focusBorderColor='#CC5083'/>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel htmlFor='password'>パスワード</FormLabel>
                            <InputGroup>
                                <Input id='password' type={show ? 'text' : 'password'} onChange={passwordchange} onKeyPress={(e) => enterClick(e)} value={password} focusBorderColor='#CC5083'/>
                                <InputRightElement width='4.5rem'>
                                    <Button h='1.75rem' size='sm' onClick={handleClick} _focus={{ boxShadow: "none"}}>
                                        {show ? <ViewIcon /> : <ViewOffIcon /> }
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            { availablePassword.passwordLength && availablePassword.common && availablePassword.onlyNumber && availablePassword.familiarEmail
                            ? (
                                <HStack>
                                    <CheckCircleIcon color='teal.400'/>
                                    <Text color='teal.400'>このパスワードは使用可能です。</Text>
                                </HStack>
                            ) : ""
                            }
                            { password!=='' && !(availablePassword.passwordLength && availablePassword.common && availablePassword.onlyNumber && availablePassword.familiarEmail)
                                ? (
                                    <HStack>
                                        <WarningIcon color='red.500'/>
                                        <Text color='red.500'>このパスワードは使用できません。</Text>
                                    </HStack>
                                ) : ""
                            }
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel htmlFor='email'>生年月日</FormLabel>
                            <HStack>
                                <Select onChange={change_year} minW='105px' >
                                    {years.map((value, key) => {
                                    if (value===2000) return <option value={value} key={key} selected>{value}</option>
                                    else return <option value={value} key={key}>{value}</option>
                                    })}
                                </Select>
                                <Text>年</Text>
                                <Select onChange={change_month}>
                                {max_day.map((value, key) => {
                                    return <option value={key} key={key}>{key+1}</option>
                                    })}
                                </Select>
                                <Text>月</Text>
                                <Select onChange={change_day}>
                                {days.map((value, key) => {
                                    return <option value={value} key={key}>{value}</option>
                                    })}
                                </Select>
                                <Text>日</Text>
                            </HStack>
                        </FormControl>
                        { isR18 ? 
                            <FormControl isRequired>
                                <Checkbox isRequired colorScheme="pink" _focus={{ boxShadow: "none"}} onChange={change_R18}>
                                    保護者の方の同意を得ている
                                </Checkbox>
                            </FormControl>
                        : "" }
                        <FormControl isRequired>
                            <Checkbox isRequired colorScheme="pink" _focus={{ boxShadow: "none"}} onChange={change_check}>
                                <Link as={ReachLink} color={'#909090'} to="/term" target="_blank" rel="noreferrer">
                                    利用規約
                                </Link>
                                に同意
                            </Checkbox>
                        </FormControl>
                        { loading ? (
                            <Button
                                bg={"#CC5083"}
                                color={'white'}
                                colorScheme='pink'
                                variant='solid'
                                onClick={send_data}
                                _focus={{ boxShadow: "none"}}
                                disabled={email === "" || password === "" || check === false || checkR18 === false  }
                                isLoading
                            >登録
                            </Button>
                        ) : (
                            <Button
                                bg={"#CC5083"}
                                color={'white'}
                                colorScheme='pink'
                                variant='solid'
                                onClick={send_data}
                                _focus={{ boxShadow: "none"}}
                                disabled={email === "" || password === "" || check === false || checkR18 === false }
                            >登録
                            </Button>
                        )}
                        <Text align="center">
                            ログインは {''}
                            <Link color='teal.500' onClick={to_login} _focus={{ boxShadow: "none"}}>
                                こちら
                            </Link>
                        </Text>
                        <Box h='80px' />
                    </VStack>
                </HStack>
            </Flex>
            <br/>
            <br/>
            <Box mx={{base: '20px', md: '120px'}}>
                <AboutPoint/>
                <AboutStep/>
            </Box>
        </Box>
    )
}