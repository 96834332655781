// Nextswan marketのトップページ
import { Grid, Box, Center, Spinner, useBreakpointValue, Flex } from '@chakra-ui/react';
import React from 'react';
import axios from 'axios';
import { MarketHeader } from './marketConponents/MarketHeader';
import MarketTabs from './marketConponents/MarketTabs';
import { SideBar } from '../../components/atoms/sideBar/SideBar';
import { MobileSideBar } from '../../components/atoms/sideBar/MobileSideBar';
import MarketPostBox from './marketConponents/MarketPostBox';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';

export default function MarketNewest() {
    // ここからreturnまで現行Nextswanのパクリ
    let Posts = [];
    const [posts, setPosts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        const load_post = async () => {
            await axios.get('/api/list/market/new/', { withCredentials: true })
                .then(res => {
                    if (res.request.status === 200) {
                        // console.log(res.data);
                        for (let i=0;i<res.data.count;i++) {
                            let data = res['data'][i]
                            // console.log(data);
                            Posts.push({
                                postId: data.id,
                                title: data.title,
                                date: data.create_time,
                                imageUrl: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + data.post_id + "." + (data.thumbnail_url ? data.thumbnail_url : "png"),
                                likescount: data.favorite,
                                price: data.price,
                                icon: (data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + data.uuid + data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                                soldOut: data.soldOut,
                            });
                            setPosts(Posts);
                        }       
                    }
                })
                .finally(() => setLoading(false));
            }
        load_post()
    }, []);

    const isMobile = useBreakpointValue({ base: true, lg: false })

    return(
        <>
            <HeadBlock title='新着 - Nextswan リセール' />
            <MarketHeader />
            <Flex>
                { isMobile ? "" : <SideBar mt={0} index="1" flex='24%' /> }
                <Box flex='76%'>
                    { isMobile ? <MobileSideBar number={1} color='orange'/> : ""}
                    <MarketTabs number={1}/>
                    { loading ? (
                        <Center h="100vh" w='100%' >
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='#E67D5A'
                                size='xl'
                            />
                        </Center>
                    ) : (
                    // <HStack textAlign={'center'}>
                        <Grid px={5} display='grid' justifyItems={'center'} alignItems={'center'} templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl:'repeat(4, 1fr)'}} gap={3} >             
                            <MarketPostBox posts={posts} color='#E67D5A' />
                        </Grid>
                    // </HStack>
                    )}
                </Box>
            </Flex>
        </>
    );
}