import React, { memo, useEffect } from 'react';
import { Link as ReachLink } from 'react-router-dom';
import {AspectRatio, Box, Center, Flex, Grid, GridItem, Heading, HStack, Icon, Image, Spacer, Spinner, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import SettingsMenu from './settingsAtom/SettingsMenu';
import { FaRegHeart } from 'react-icons/fa';
import { ShowTime } from '../../components/atoms/ShowTime';
import axios from 'axios';

const PurchasedBox = memo(() => {
    let Posts = [];
    const [posts, setPosts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const isMobile = useBreakpointValue({ base: true, lg: false });

    useEffect(() => {
        setLoading(true);
        const load_post = async () => {
            await axios.get('/api/list/purchase/', { withCredentials: true })
                .then(res => {
                    if (res.request.status === 200) {
                        // console.log(res.data)
                        for (let i=0;i<res.data.count;i++) {
                            let data = res['data'][i]
                            Posts.push({
                                postId: data.id,
                                title: data.title,
                                date: data.create_time,
                                imageUrl: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + data.id + "." + (data.thumbnail_url ? data.thumbnail_url : "png"),
                                likescount: data.favorite,
                            });
                            setPosts(Posts);
                        }
                     }
                })
                .finally(() => setLoading(false));
            }
        load_post()
    }, [])
    
    if (loading) {
        return(
        <Center>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='pink.500'
                size='xl'
            />
        </Center>
        )
    } else if (isMobile){
        return (
            posts.map((post, key) => (
                <HStack as={ReachLink} to={'/p/' + post.postId} key={key} pb={3}>
                    <Box position={"relative"} w={{base: '40vw', md:'30vw'}} mr={2} >
                        <AspectRatio ratio={16 / 9}>
                            <Image
                                borderRadius={2}
                                src={post.imageUrl}
                                alt=""
                            />
                        </AspectRatio>
                    </Box>
                    <Box w={{base: '46vw', md:'35vw'}}>
                        <Stack lineHeight='normal'>
                            <Box>
                                <Text fontSize="sm" fontWeight="bold" textAlign={"left"} noOfLines={2}>
                                    {post.title}
                                </Text>
                            </Box>
                            <HStack>
                                <Icon as={FaRegHeart} color={'#CC5083'} w={4} h={4} ml={1} />
                                <Text color={'#CC5083'} pr={1} >{post.likescount}</Text>
                                <ShowTime date0 = {post.date} />
                            </HStack>
                        </Stack>
                    </Box>
                </HStack>
            ))
        )
    } else {
        return (
            posts.map((post, key) => (
                <GridItem as={ReachLink} to={'/p/' + post.postId} key={key}>
                    <Box w={{base:'300px' , lg: '230px'}} h={{base:'270px' , lg: '210px'}} mx='1px' overflow='auto' _hover={{ cursor: "pointer", opacity: 0.8 }}>
                            <AspectRatio ratio={16 / 9}>
                                <Image
                                    borderRadius={4}
                                    boxSize="100px"
                                    src={post.imageUrl}
                                    alt=""
                                />
                            </AspectRatio>
                        <Box>
                            <HStack>
                                <Stack height={12} lineHeight='normal'>
                                    <Box height={10}>
                                        <Text fontSize="sm" fontWeight="bold" textAlign={"left"} noOfLines={2}>
                                            {post.title}
                                        </Text>
                                    </Box>
                                    <HStack>
                                        <Icon as={FaRegHeart} color={'#CC5083'} w={4} h={4} ml={1} />
                                        <Text color={'#CC5083'}>{post.likescount}</Text>
                                        <Spacer />
                                        <ShowTime date0 = {post.date} />
                                    </HStack>
                                </Stack>
                            </HStack>
                        </Box>
                    </Box>
                </GridItem>
            ))
        );
    };
});

const Purchased = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false });
    return(
        <>
            <HeaderPlane />
            <Flex justify="center" height="flex" mx={{base: '20px', md: '100px'}}>
                { isMobile ? "" :
                <Stack mr='100px' >
                    <Box h={16} />
                    <SettingsMenu mt={100} news={0} purchased={1} contents={0} account={0} dashboard={0} />
                </Stack>
                }
                <VStack mx={8} py={4} w={450}>
                    <Heading as="h3" size="lg" pt={{base: 4, lg: 7}} pb={{base: 3, lg: 6}}>
                        購入したコンテンツ
                    </Heading>
                    <Grid
                        justifyItems={{base: 'left', lg: 'center'}}
                        alignItems={'center'}
                        overflowX='auto'
                        flexWrap='nowrap'
                        templateColumns={{base: 'repeat(0, 1fr)', lg: 'repeat(2, 1fr)'}}
                        gap={3}
                    >
                        <PurchasedBox/>
                    </Grid>
                </VStack>
            </Flex>
        </>
    );
};
export default Purchased;