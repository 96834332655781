// リセールヘッダーの設定ボタン。
import React, { useState } from 'react';
import axios from 'axios';
import { SettingsIcon } from '@chakra-ui/icons';
import { Button, Icon, Link, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Spacer, VStack} from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';
import { FaCircle } from 'react-icons/fa';

export default function MarketSettingsButton( {onClick}, {color = "#E67D5A"} ) {
    //未読のお知らせがあれば通知する
    const [news, setNews] = useState(false);
    React.useEffect(() => {
        const load_check_notification = async () => {
            await axios.get("api/check_read/", {withCredentials: true})
            .then(res => {
                setNews(res.data.none_read);
            });
        }
        load_check_notification();
    }, []);

    return(
        <Popover>
            <PopoverTrigger>
                <SettingsIcon w={{ base: 7, md: 9 }} h={{ base: 7, md: 9 }} color={color} m={2}/>
            </PopoverTrigger>
            <PopoverContent w='200px'>
                <PopoverArrow />
                <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                <PopoverHeader align='center' fontWeight='semibold'>設定</PopoverHeader>
                <Link onClick={onClick}>
                    <Button w="100%" bgColor='white' _focus={{ boxShadow: "none"}}>
                        マイページ
                    </Button>
                </Link>
                <Link as={ReachLink} to="/m/news" >
                    <Button w="100%" bgColor='white' _focus={{ boxShadow: "none"}}>
                        お知らせ
                        {/* 未読のお知らせがあれば丸アイコンで通知 */}
                        { news ? (
                            <VStack pl={1} >
                                <Icon as={FaCircle} w={2} h={2} color='#E67D5A' />
                                <Spacer/>
                            </VStack>
                        ) : "" }
                    </Button>
                </Link>
                <Link as={ReachLink} to="/m/bookmark" >
                    <Button w="100%" bgColor='white' _focus={{ boxShadow: "none"}}>
                        ブックマーク
                    </Button>
                </Link>
                <Link as={ReachLink} to="/m/settings/listing" >
                    <Button w="100%" bgColor='white' _focus={{ boxShadow: "none"}}>
                        出品管理
                    </Button>
                </Link>
                <Link as={ReachLink} to="/m/settings/history" >
                    <Button w="100%" bgColor='white' _focus={{ boxShadow: "none"}}>
                        購入履歴
                    </Button>
                </Link>
                <Link as={ReachLink} to="/settings/account" >
                    <Button w="100%" bgColor='white' _focus={{ boxShadow: "none"}}>
                        アカウント設定
                    </Button>
                </Link>
            </PopoverContent>
        </Popover>
    );
};