import React from 'react';
import { Tab, TabList, Tabs, Box, Icon} from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';
import { FaHome, FaFire, FaGraduationCap, FaSuitcase, FaBook } from 'react-icons/fa';

export default function OriginalTabs(props) {
    /* ログインしているかを簡易的にスイッチで切り替える 
    const [user, setUser] = React.useState(false)
    const handleChange = () => setUser(!user); 
    
    なぜか？ おすすめは登録者には出さない → 急上昇にリダイレクト*/

    // const vw = window.outerWidth;
    // const tabMinW = vw - 250;

    return(
        <Box paddingBottom="30px">
            {/* <Switch size='md' onChange={handleChange} />ログイン */}
            <Tabs minW='76vw' maxW='100vw' defaultIndex={props.number} isManual align='left' colorScheme='pink' overflowX='auto' flexWrap='nowrap'>
                <TabList>
                    {/* {user ? <Tab as={ReachLink} to="/">おすすめ</Tab> : '' } */}
                    <Tab as={ReachLink} to="/" whiteSpace='nowrap' _focus={{ boxShadow: "none"}}><Icon as={FaHome} w={6} h={6} mr={1} />おすすめ</Tab>
                    <Tab as={ReachLink} to="/trend" whiteSpace='nowrap' _focus={{ boxShadow: "none"}}><Icon as={FaFire} w={5} h={5} mr={1} />人気</Tab>
                    {/* <Tab as={ReachLink} to="/newest" whiteSpace='nowrap' _focus={{ boxShadow: "none"}}><Icon as={FaBullhorn}w={5} h={5}  mr={2} />新着</Tab> */}
                    <Tab as={ReachLink} to="/t/academia" whiteSpace='nowrap' _focus={{ boxShadow: "none"}}><Icon as={FaGraduationCap} w={6} h={6} mr={1} />アカデミア</Tab>
                    <Tab as={ReachLink} to="/t/business" whiteSpace='nowrap' _focus={{ boxShadow: "none"}}><Icon as={FaSuitcase} w={5} h={5} mr={1} />ビジネス</Tab>
                    <Tab as={ReachLink} to="/t/career" whiteSpace='nowrap' _focus={{ boxShadow: "none"}}><Icon as={FaBook} w={5} h={5} mr={1} />キャリア</Tab>
                    {/* <Tab as={ReachLink} to="/t/technology" whiteSpace='nowrap' _focus={{ boxShadow: "none"}}><Icon as={FaCogs} w={6} h={6} mr={1} />テクノロジー</Tab> */}
                    {/* <Tab as={ReachLink} to="/t/lifestyle" whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>ライフスタイル</Tab> */}
                </TabList>
            </Tabs>
        </Box>
    )
}