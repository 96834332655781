import { Heading, HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export function Feature(props) {
    const {number, title, line1, line2, line3, line4, line5, width} = props;
    return(
        <>
        <Stack>
            <HStack>
                <Heading size='lg' justify='left' >{number}</Heading>
                <Heading size='md' justify='left' >{title}</Heading>
            </HStack>
        </Stack>
        <Text fontSize='sm' align='left' w={width}>
            {line1}<span class="marker_line40">{line2}</span>{line3}<span class="marker_line40">{line4}</span>{line5}
         </Text>
        </>
    );
};

// export const Feature01 = () => {
//     <Feature
//         width="400px"
//         number="01"
//         title="良いコンテンツは価格が上がる"
//         line1="いいねやシェアなど読み手の評価に応じて、コンテンツの価格がリアルタイムに変わります。5いいね未満の投稿は無料で読め、5いいね以降から有料となります。"
//     />
// }

// export const Feature02 = () => {
//     <Feature
//         width="400px"
//         number="02"
//         title="購入したコンテンツはあとで売れる"
//         line1="著者が許可したコンテンツは「Nextswan リセール」で出品し、他のユーザーと売買できます。その際、販売額の15%が著者にロイヤリティーとして支払われます。"
//     />
// }
