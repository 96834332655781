import React from 'react';
import { Link as ReachLink } from "react-router-dom";
import { Box, Flex, Heading, Icon, Link, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import SettingsMenu from './settingsAtom/SettingsMenu';
import axios from 'axios';
import { ShowTime } from '../../components/atoms/ShowTime';
import { FaFilm } from 'react-icons/fa';

//出品管理ページ本体のコード
const News = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false });
    const [news, setNews] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const load_notification = async () => {
            await axios.get("/api/notification/", {withCredentials: true})
            .then(res => {
                let notification = [];
                for (let i=0;i<res.data.count;i++) {
                    let data = res['data'][i]
                    console.log(data);
                    notification.push({
                        username: data.username,
                        is_market: data.is_market,
                        post_name: data.post_name,
                        post_id: data.post_id,
                        price: data.price,
                        limit: data.limit,
                        is_sold: data.is_sold,
                        is_comment: data.is_comment,
                        is_purchase: data.is_purchase,
                        is_resale: data.is_resale,
                        is_creator: data.is_creator,
                        time: data.time,
                        read: data.read,
                        movie: data.is_movie,
                    });
                }
                setNews(notification);
            });
        }
        load_notification();
    }, []);

    return(
        <>
            <HeaderPlane plasement="top"/>
            <Flex justify="center" height="flex" size="full" mx='20px'>
                { isMobile ? "" :
                <Stack mr='100px'>
                    <Box h={16} />
                    <SettingsMenu mt={100} news={1} contents={0} account={0} dashboard={0} />
                </Stack>
                }
                <VStack mx={8} py={4} align='stretch' maxW={450} minW={{base: '', md: '450px'}}>
                    <Heading align='center' py={5} >お知らせ</Heading>
                    {news.map((value, key) => {
                        return(
                            <Box key={key}>
                                <hr/>
                                <Stack pt={2} >
                                {value.is_market && value.is_purchase && value.is_creator && !value.is_sold ?
                                    <Text as={ReachLink} to={'/m/' + value.post_id} fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                        「{value.post_name}」がNextswan リセールで{value.price}円で取引されました。
                                    </Text>
                                : "" }
                                {value.is_market && value.is_purchase && !value.is_creator && !value.is_sold ?
                                    <Text as={ReachLink} to={'/m/' + value.post_id} fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                        Nextswan リセールに出品した「{value.post_name}」が{value.price}円で購入されました。
                                    </Text>
                                : "" }
                                {value.is_comment ?
                                    <Text as={ReachLink} to={'/m/' + value.post_id} fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                        あなたが出品した「{value.post_name}」にコメントがあります。
                                    </Text>
                                : "" }
                                {value.movie ?
                                    <Text as={ReachLink} to={'/p/' + value.movie} fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                        投稿したコンテンツのショート動画が完成しました。<Link as={ReachLink} to={"/p/" + value.movie}>こちらのページ</Link>に移り、ページ内の<Icon as={FaFilm}></Icon>アイコンを押すとダウンロードできます。
                                    </Text>
                                : "" }
                                {/* {value.is_market && value.is_comment && !value.is_creator ?
                                    <Text as={ReachLink} to={'/m/' + value.post_id} fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                        あなたがNextswan リセールでコメントした「{value.post_name}」にコメントがあります。
                                    </Text>
                                : "" } */}
                                {value.is_market && value.is_sold ?
                                    <Text as={ReachLink} to={'/m/' + value.post_id} fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                        お気に入り登録していた「{value.post_name}」がNextswan リセールで売り切れました。
                                    </Text>
                                : "" }
                                {!value.is_market && value.is_purchase && value.price!==0 ?
                                    <Text as={ReachLink} to={'/p/' + value.post_id} fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                        「{value.post_name}」が{value.price}円で購入されました。
                                    </Text>
                                : "" }
                                {!value.is_market && value.is_purchase && value.price===0 ?
                                    <Text as={ReachLink} to={'/p/' + value.post_id} fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                        「{value.post_name}」が有料になりました。
                                    </Text>
                                : "" }
                                {!value.is_market && value.is_resale ?
                                    <Text as={ReachLink} to={'/p/' + value.post_id} fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                        「{value.post_name}」のリセールが開始されました。
                                    </Text>
                                : "" }
                                {!value.is_market && value.is_sold ?
                                    <Text as={ReachLink} to={'/p/' + value.post_id} fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                        「{value.post_name}」が売り切れました(発行部数: {value.limit})。
                                    </Text>
                                : "" }
                                <ShowTime date0={value.time} size='xs' />
                                </Stack>
                            </Box>
                        );
                    })};
                </VStack>
            </Flex>
        </>
    );
};

export default News;