import React, { useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Button, FormControl, FormLabel, Input, InputGroup, InputRightElement, Spacer, VStack, Text, HStack } from "@chakra-ui/react";
import { HeaderPlane } from '../../../components/organisms/layout/HeaderPlane';
import { CheckCircleIcon, ViewIcon, ViewOffIcon, WarningIcon } from '@chakra-ui/icons';

const NewPasswordInput = () => {
    const [loading, setLoading] = React.useState(false);
    const [show1, setShow1] = useState(false);
    const handleClick1 = () => setShow1(!show1);
    const navigate = useNavigate();
    const [password1, setPassword1] = React.useState('');
    const [availablePassword, setAvailablePassword] = React.useState({
        passwordLength: false, /*最低8文字*/
        common: false, /*よく使われる*/
        onlyNumber: false, /*数字のみ*/
        familiarEmail: false /*email(&ユーザー名)と似ている*/
    })
    const passwordchange1 = (e) => {
        let password = e.target.value
        setAvailablePassword({
        passwordLength: password.length >= 8,
        common: true,
        onlyNumber: isNaN(password),
        familiarEmail: true,
        });
        setPassword1(() => e.target.value);
    }

    const enterClick = (e) => {
        if (e.key === 'Enter') {
            send_data()
        }
    }

    const send_data = async () => {
        setLoading(true);
        const data = new FormData();
        data.append("password", password1);
        const url = new URL(window.location.href);
        const params = url.searchParams;
        data.append("token", params.get('token'));
        await axios.post('/api/password_reset/confirm/', data, { withCredentials: true })
        .then(res => {
            if (res.request.status === 200) {
                navigate("/settings/password/complete");
            };
        })
        .catch(res => {
            if (res.request.status === 400) {
                alert('このパスワードは無効です。');
            }
        })
        .finally(() => setLoading(false));
    }

    return(
        <>
            <HeaderPlane />
            <Flex mt={10} justify="center" height="flex" size="full">
                <VStack spacing={6} py={4} px={10} align='stretch' w={450}>
                    <Heading as="h1" size="lg" textAlign="center">
                        安全なパスワードの作成
                    </Heading>
                    <Text fontSize='sm' color='gray.700' pt='10px' >
                        パスワードは8文字以上で、アルファベットを含むものにしてください。よく使われる文字列、メールアドレスと似た文字列は使用できません。
                    </Text>
                    <FormControl isRequired>
                        <FormLabel htmlFor='password'>新しいパスワード</FormLabel>
                        <InputGroup>
                            <Input id='password' type={show1 ? 'text' : 'password'} onChange={passwordchange1} onKeyPress={(e) => enterClick(e)} value={password1} focusBorderColor='#CC5083'/>
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick1} _focus={{ boxShadow: "none"}}>
                                    {show1 ? <ViewIcon /> : <ViewOffIcon /> }
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        { availablePassword.passwordLength && availablePassword.common && availablePassword.onlyNumber && availablePassword.familiarEmail
                            ? (
                                <HStack>
                                    <CheckCircleIcon color='teal.400'/>
                                    <Text color='teal.400'>このパスワードは使用可能です。</Text>
                                </HStack>
                            ) : ""
                        }
                        { password1 !== '' && !(availablePassword.passwordLength && availablePassword.common && availablePassword.onlyNumber && availablePassword.familiarEmail)
                            ? (
                                <HStack>
                                    <WarningIcon color='red.500'/>
                                    <Text color='red.500'>このパスワードは使用できません。</Text>
                                </HStack>
                            ) : ""
                        }
                    </FormControl>
                    <Spacer />
                    { loading ? (
                        <Button
                            bg={"#CC5083"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            variant='solid'
                            onClick={send_data}
                            _focus={{ boxShadow: "none"}}
                            disabled={password1 === ""}
                            isLoading
                        >パスワードを再設定する</Button>
                    ) : (
                        <Button
                            bg={"#CC5083"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            variant='solid'
                            onClick={send_data}
                            _focus={{ boxShadow: "none"}}
                            disabled={password1 === ""}
                        >パスワードを再設定する</Button>
                    )}
                </VStack>
            </Flex>
        </>
    )
};
export default NewPasswordInput