// 出品条件の詳細を設定するページ。
import { AspectRatio, Box, Button, ButtonGroup, Center, Flex, FormControl, Avatar, Link, Heading, Image, NumberInput, NumberInputField, Spacer, Spinner, Stack, Text, HStack, useBreakpointValue, Popover, PopoverTrigger, PopoverContent, PopoverCloseButton, PopoverArrow } from '@chakra-ui/react'
import axios from 'axios';
import React from 'react'
import { Link as ReachLink, useLocation, useNavigate } from 'react-router-dom';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';
import { MarketHeaderSub } from './marketConponents/MarketHeader';
import { ShowPriceBlack, ShowPriceBlack2 } from '../../components/atoms/ShowPriceS';
import { QuestionOutlineIcon } from '@chakra-ui/icons';

function Listing() {

    // この辺から80行目くらいまではNextswan本体のパクリなので変更お願いシアmす
    const [postid, setPostid] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [price, setPrice] = React.useState('');
    const [profile, setProfile] = React.useState({
        username: '',
        description: '',
        icon: '',
    });
    const [post, setPost] = React.useState({
        title: '',
        body: '',
        date: '',
        is_purchase: '',
        is_login: '',
        is_me: '',
        userid: '',
        thumbnail: '',
        price: '',
    });
    // const [post_info, setPost_info] = React.useState({
    //     price: '',
    //     favorite: '',
    //     move: [],
    // });

    const location = useLocation();
    const navigate = useNavigate()

    React.useEffect(() => {
        setLoading(true);
        let post_id = location.pathname.split("/")[2]
        setPostid(post_id);
        const load_post = async () => {
            const post_data = new FormData();
            post_data.append("post_id", post_id);
            await axios.post("/api/article/", post_data, { withCredentials: true })
            .then(res => {
            if (res.request.status === 200) {
                // console.log(res.data);
                setPost({
                    title: res.data.title,
                    body: res.data.body,
                    date: res.data.create_time,
                    is_purchase: res.data.is_purchase,
                    is_login: res.data.is_login,
                    userid: res.data.userid,
                    thumbnail: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + post_id + "." + (res.data.thumbnail_url ? res.data.thumbnail_url : "png"),
                    is_me: res.data.is_me,
                    price: res.data.price,
                });
                // setPost_info({
                //     price: res.data.price,
                //     favorite: res.data.like,
                //     move: res.data.move,
                // });
                setProfile({
                    username: res.data.username,
                    description: res.data.user_intro,
                    icon: (res.data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + res.data.uuid + res.data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                });
            }
            })
            .finally(() => setLoading(false));
        }
        load_post();
    }, []);

    const change_price = (e) => {
        setPrice(e);
    }

    const cancel = async () => {
        navigate('/m/listing');
    }

    const send_data = async () => {
        const data = new FormData()
        data.append('post_id', postid);
        data.append('price', price);
        await axios.post('/api/listing/', data, { withCredentials: true })
        .then(res => {
            navigate('/m/'+res.data.market_id);
        });
    }
    const isMobile = useBreakpointValue({ base: true, md: false });
    const titleSize = useBreakpointValue({ base: 'md', lg: 'lg' });

    if (loading) {
        return(
        <Center h="100vh">
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='#E67D5A'
                size='xl'
            />
        </Center>
        )
    } else {
        return (
            <div pt={5}>
                <HeadBlock title="出品設定"/>
                <MarketHeaderSub/>
                <Heading align='center' pt={6} pb={2} >出品設定</Heading>
                <Flex pt={5} justify="center" mx={{base: '20px', md: '100px'}}>            
                    <AspectRatio w="640px" ratio={16 / 9}>
                        <Image src={post.thumbnail} style={{ objectFit: 'cover', width: '100%' }} />
                    </AspectRatio>
                </Flex>
                <Center pt="30px" mx={{base: '20px', md: '100px'}}>
                    <Stack maxW={{base: "95vw", md: '640px'}}>
                        <Heading size={titleSize} flexWrap='wrap' >{ post.title }</Heading>
                        <Flex pt="5px" align="center">
                            <Flex>
                                <Avatar size="xs" src={ profile.icon } mt={2} />
                                <Link as={ReachLink} to={'/' + post.userid} _focus={{ boxShadow: "none"}}>
                                    <Text size="lg" mx={3} pt={2} maxW='540px' noOfLines={2} >{ profile.username }</Text>
                                </Link>
                            </Flex>
                        </Flex>
                        {/* 価格設定 */}
                        {isMobile ? (
                            <Box pt={10}>
                                <Text as="h1" fontSize="2xl">価格を設定してください。<br></br></Text>
                                <HStack>
                                    <Text size="sm">
                                        (プライマリー価格
                                    </Text>
                                    <Popover isLazy trigger="hover">
                                        <PopoverTrigger>
                                            <QuestionOutlineIcon
                                                cursor='pointer'
                                                color="#797979"
                                                mx={2}
                                            />
                                        </PopoverTrigger>
                                        <PopoverContent w='240px' align='center'>
                                            <PopoverArrow />
                                            <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                                            <Text align='center'>プライマリー価格とは、</Text>
                                            <Text align='center'>Nextswanで直接</Text>
                                            <Text align='center'pb={2}>購入する場合の価格です。</Text>
                                        </PopoverContent>
                                    </Popover>
                                    <Text size="sm">:&nbsp;</Text>
                                    <ShowPriceBlack2 price={post.price}/>
                                    <Text size="sm">
                                        )
                                    </Text>
                                </HStack>
                            </Box>
                        ) : (
                            <Flex pt={10} w='640px'>
                                <Text as="h1" fontSize="2xl">
                                    価格を設定してください。
                                </Text>
                                <Text size="sm" pt={2}>
                                    (プライマリー価格
                                </Text>
                                <Popover isLazy trigger="hover">
                                    <PopoverTrigger>
                                        <QuestionOutlineIcon
                                            cursor='pointer'
                                            color="#797979"
                                            mx={2}
                                            mt={3}
                                        />
                                    </PopoverTrigger>
                                    <PopoverContent w='240px' align='center'>
                                        <PopoverArrow />
                                        <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                                        <Text align='center' pt={2} >プライマリー価格とは、</Text>
                                        <Text align='center' pt={2} >Nextswanで直接</Text>
                                        <Text align='center' pt={2} pb={2}>購入する場合の価格です。</Text>
                                    </PopoverContent>
                                </Popover>
                                <Text size="sm" pt={2}>:&nbsp;</Text>
                                <ShowPriceBlack price={post.price}/>
                                <Text size="sm" pt={2}>
                                    )
                                </Text>
                            </Flex>
                        )}
                        <Box h='16px'/>
                        {isMobile ?
                            <Stack>
                                <Heading as="h1" fontSize="2xl" mr={2} mt={4}>リセール価格:&nbsp;</Heading>
                                <Flex>
                                    <Spacer/>
                                        {/* 300円から10万円までの範囲で価格を設定できる */}
                                        <FormControl maxW='270px' isRequired>
                                            <NumberInput max={1000000} min={300} size='lg' w='270px' focusBorderColor='#E67D5A' onChange={change_price}>
                                                <NumberInputField variant='flushed' id='price' placeholder="300円以上にしてください。" />
                                            </NumberInput>
                                        </FormControl>
                                        <Heading as="h1" fontSize="2xl" ml={2} mt={4} >円</Heading>
                                    <Spacer/>
                                </Flex>
                            </Stack>
                        :
                            <Flex>
                                <Spacer/>
                                {isMobile || <Heading as="h1" fontSize="2xl" mr={2} mt={4}>リセール価格:&nbsp;</Heading>}
                                {/* 300円から10万円までの範囲で価格を設定できる */}
                                <HStack align='end'>
                                    <FormControl maxW='270px' isRequired>
                                        <NumberInput max={1000000} min={300} size='lg' w='270px' focusBorderColor='#E67D5A' onChange={change_price}>
                                            <NumberInputField variant='flushed' id='price' placeholder="300円以上にしてください。" align='right'  />
                                        </NumberInput>
                                    </FormControl>
                                    <Heading as="h1" fontSize="2xl" ml={2} mt={7} >円</Heading>
                                </HStack>
                            </Flex>
                        }
                        <Box h={3}/>
                        <Flex>
                            <Spacer/>
                            <HStack align='end'>
                                <Text as="h1" fontSize="md" mr={2} color='gray.700'>販売利益(35%):</Text>
                                <Text fontSize="lg" color='gray.700' minW='50px' align='right'>
                                    {Math.trunc(price * 0.35).toLocaleString()}
                                </Text>
                                <Text as="h1" fontSize="md" color='gray.700' ml={2}>円</Text>
                            </HStack>
                        </Flex>
                        <Flex>
                            <Spacer/>
                            <HStack align='end'>
                                <Text as="h1" fontSize="md" mr={2} color='gray.700'>著者へのロイヤリティ(55%):&nbsp;</Text>
                                <Text fontSize="lg" color='gray.700' minW='50px' align='right'>
                                    {Math.trunc(price * 0.55).toLocaleString()}
                                </Text>
                                <Text as="h1" fontSize="md" color='gray.700' ml={2}>円</Text>
                            </HStack>
                        </Flex>
                        {/* <Box h='30px' /> */}
                        {/* <Box
                            px={2}
                            py={1}
                            _hover='none'
                            cursor='unset'
                            bg={"#DDFFDD"}
                            color={'black'}
                            size='sm'
                            borderRadius={10}
                            _focus={{ boxShadow: "none"}}
                        >
                            <HStack>
                                <Icon as={FaExclamationCircle} w={6} h={6} mr={1} color='teal.400' />
                                <Stack spacing='-2px'>
                                    <Text align="left" size="md" >
                                        出品したコンテンツが購入されたら、収益の78%が出品者に支払われます。
                                    </Text>
                                    <Text align="left" size="md" >
                                        著者にはロイヤリティとして15%が支払われます。
                                    </Text>
                                </Stack>
                            </HStack>
                        </Box>*/}
                        <Box h='50px' />
                        <ButtonGroup display='flex' justifyContent='center'>
                            <Button
                                type='button'
                                colorScheme='black'
                                variant='outline'
                                size='lg'
                                color='black'
                                _focus={{ boxShadow: "none"}}
                                onClick={cancel}
                            >キャンセル</Button>
                            <Box w='100px' />
                            <Button
                                type='submit'
                                bg="#E67D5A"
                                size='lg'
                                color='white'
                                variant='solid'
                                _hover={{ opacity: 0.8 }}
                                _focus={{ boxShadow: "none"}}
                                onClick={send_data}
                            >出品</Button>
                        </ButtonGroup>
                    </Stack>
                </Center>
            </div>
        )
    }
}

export default Listing