import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';
import { About } from './pages/about/About';
import LogIn from './pages/login/LogIn';
import CardSettings from './pages/settings/CardSettings';
import ContentsSettings from './pages/settings/ContentsSettings';
import TransferSettings from './pages/settings/TransferSettings';
import UserEdit from './pages/settings/UserEdit';
import SignUp from './pages/signup/SignUp';
import Top from './pages/top/Top';
import New from './pages/post/New';
import User from './pages/user/User'
import Logout from  './pages/user/Logout';
import ProfileEdit from './pages/user/ProfileEdit';
import PostDetail from './pages/post/PostDetail';
import DashboardMain from './pages/settings/DashboardMain';
import Trend from './pages/top/Trend';
import Company from './pages/others/Company';
import Commerce from './pages/others/Commerce';
import Terms from './pages/others/Terms';
import Policy from './pages/others/Policy';
import { Box } from '@chakra-ui/react';
import MarketTop from './pages/market/MarketTop';
import MarketPostDetail from './pages/market/MarketPostDetail';
import ChooseListing from './pages/market/ChooseListing';
import SettingsListing from './pages/market/marketSettings/SettingsListing';
import MarketNews from './pages/market/marketSettings/MarketNews';
import MarketHistory from './pages/market/marketSettings/MarketHistory';
import MarketNewest from './pages/market/MarketNewest';
import News from './pages/settings/News';
import Purchased from './pages/settings/Purchased';
import Bookmark from './pages/market/marketSettings/marketBookmark';
import ListingEdit from './pages/market/ListingEdit';
import Listing from './pages/market/Listing';
import History from './pages/settings/History';
import Preview from './pages/post/Preview';
import Search from './pages/search/Search';
import MarketSearch from './pages/market/MarketSearch';
import Newest from './pages/top/Newest';
import TagAcademia from './pages/top/TagAcademia';
import TagBusiness from './pages/top/TagBusiness';
import TagCareer from './pages/top/TagCareer';
import TagTech from './pages/top/TagTech';
import PasswordChange from './pages/settings/password/PasswordChange';
import PasswordReset from './pages/settings/password/PasswordReset';
import NewPasswordInput from './pages/settings/password/NewPasswordnput';
import PasswordComplete from './pages/settings/password/PasswordComplete';
import SendEmail from './pages/settings/password/SendEmail';
import AfterPost from './pages/post/AfterPost';

const useTracking = () => {
  const listen = useNavigate()
  const trackingId = "G-ZPWL558HSZ"
 
  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return
      if (!trackingId) {
        console.log(
          'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
        )
        return
      }
      window.gtag('config', trackingId, { page_path: location.pathname })
    })
    return unlisten
  }, [trackingId, listen])
}

function App() {
  // useTracking()

  return (
    <Box minH='100vh'>
        <Routes>
            <Route path='/' element={<Top />} />
            <Route path='/trend' element={<Trend />} />
            <Route path='/newest' element={<Newest />} />
            <Route path='/t/academia' element={<TagAcademia />} />
            <Route path='/t/business' element={<TagBusiness />} />
            <Route path='/t/career' element={<TagCareer />} />
            <Route path='/t/technology' element={<TagTech />} />
            <Route path='/login' element={<LogIn />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/about' element={<About />} />
            <Route path='/news' element={<News />} />
            <Route path='/s' element={<Search />} />
            <Route path='/p/purchased' element={<Purchased />} />
            <Route path='/p/share/:post_id' element={<AfterPost />} />
            <Route path='/p/:post_id' element={<PostDetail />} />
            <Route path='/edit/:post_id/preview' element={<Preview />} />
            <Route path='/edit/:post_id' element={<New />} />
            <Route path='/settings/account' element={<UserEdit />} />
            <Route path='/settings/history' element={<History />} />
            <Route path='/settings/card' element={<CardSettings />} />
            <Route path='/settings/transfer' element={<TransferSettings />} />
            <Route path='/settings/contents' element={<ContentsSettings />} />
            <Route path='/settings/dashboard' element={<DashboardMain />} />
            <Route path='/settings/password' element={<PasswordChange />} />
            <Route path='/settings/password/reset' element={<PasswordReset />} />
            <Route path='/settings/password/input' element={<NewPasswordInput />} />
            <Route path='/settings/password/complete' element={<PasswordComplete />} />
            <Route path='/settings/password/send' element={<SendEmail />} />
            <Route path='/term' element={<Terms />} />
            <Route path='/policy' element={<Policy />} />
            <Route path='/company' element={<Company />} />
            <Route path='/commerce' element={<Commerce />} />
            <Route path='/m' element={<MarketTop />} />
            <Route path='/m/newest' element={<MarketNewest />} />
            <Route path='/m/bookmark' element={<Bookmark />} />
            <Route path='/m/listing' element={<ChooseListing />} />
            <Route path='/m/s' element={<MarketSearch />} />
            <Route path='/m/news' element={<MarketNews />} />
            <Route path='/m/settings/listing' element={<SettingsListing />} />
            <Route path='/m/settings/history' element={<MarketHistory />} />
            <Route path='/m/:listing_id/listing' element={<Listing />} />
            <Route path='/m/:listing_id/edit' element={<ListingEdit />} />
            <Route path='/m/:listing_id' element={<MarketPostDetail />} />
            <Route path='/:username/edit' element={<ProfileEdit />} />
            <Route path='/:username' element={<User />} />
        </Routes>   
    </Box>
  );
}

export default App;
