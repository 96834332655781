import { AspectRatio, Box, Center, GridItem, HStack, Icon, Image, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import React, { memo, useEffect } from 'react';
import { FaRegHeart } from 'react-icons/fa';
import { Link as ReachLink } from 'react-router-dom';
import axios from 'axios'
import { ShowTime } from "../../components/atoms/ShowTime";


const UserLikes = memo(() => {
    let Posts = [];
    const [posts, setPosts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        const load_post = async () => {
            await axios.get('/api/list/like/', { withCredentials: true })
                .then(res => {
                    if (res.request.status === 200) {
                        for (let i=0;i<res.data.count;i++) {
                            let data = res['data'][i]
                            Posts.push({
                                postId: data.id,
                                title: data.title,
                                date: data.create_time,
                                imageUrl: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + data.id + "." + (data.thumbnail_url ? data.thumbnail_url : "png"),
                                likescount: data.favorite,
                                icon: (data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + data.uuid + data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                                userName: data.username,
                            });
                            setPosts(Posts);
                        }       
                     }
                })
                .finally(() => setLoading(false));
            }
        load_post()
    }, [])
    
    if (loading) {
        return(
        <Center>
            <Spinner 
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='pink.500'
                size='xl'
            />
        </Center>
        )
    } else {
        return (
            posts.map((post, key) => (
                <GridItem as={ReachLink} to={'/p/' + post.postId} key={key}>
                    <Box w={{base:'300px', lg: '230px'}} h={{base:'309px', lg: '236px'}} mx='1px' _hover={{ cursor: "pointer", opacity: 0.8 }}>
                        <Box textAlign="center" position={"relative"}>
                            <AspectRatio ratio={16 / 9}>
                                <Image
                                    borderRadius={4}
                                    boxSize="100px"
                                    src={post.imageUrl}
                                    alt=""
                                />
                            </AspectRatio>
                            {/* <Box bg={"#CC5083"} align="center" borderRadius={4} size='sm' boxShadow='dark-lg' position={"absolute"} bottom={3} right={3} >
                                    { post.price===0 ? (<Text p={1} color={'white'}>無料</Text>) : (<ShowPriceS price={post.price}/>) }
                            </Box> */}
                        </Box>
                        <Box>
                            <HStack>
                                <Image src={post.icon} alt="" borderRadius="50%" width={{ base: 8, md: 6 }} height={{ base: 8, md: 6 }} style={{ objectFit: 'cover'}} ml={1} />
                                <Stack height={12} lineHeight='normal'>
                                    <Box height={10}>
                                        <Text fontSize="sm" fontWeight="bold" textAlign={"left"} mt={1} mr={0.5} maxW={{base:'272px', lg: '210px'}} noOfLines={2}>
                                            {post.title}
                                        </Text>
                                    </Box>
                                    <Text fontSize="xs" fontWeight="gray" textAlign={"left"}
                                    >{post.userName}
                                    </Text>
                                    <HStack>
                                        <Icon as={FaRegHeart} color={'#CC5083'} w={4} h={4} ml={1}/>
                                        <Text color={'#CC5083'}>{post.likescount}</Text>
                                        <Spacer/>
                                        <ShowTime pr={4} date0 = {post.date} />
                                    </HStack>
                                </Stack>
                            </HStack>
                        </Box>
                    </Box>
                </GridItem>
            ))
        );
    };
});

export default UserLikes;