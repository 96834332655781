import React from 'react';
import { Button, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { FaCaretRight } from 'react-icons/fa';
import { Link as ReachLink } from 'react-router-dom';

export default function MarketSettingsMenu(props) {
    const { news, bookmark, listing, history } = props;

    return(
        <VStack alignItems='center'>
            <Text align='center' fontSize='xl' fontWeight='semibold' mb={4}>設定</Text>
            <HStack>
                {news ? (<Icon as={FaCaretRight} />) : (<></>) }
                <Link as={ReachLink} to="/m/news">
                    <Button w="200px" bgColor='white' variant='ghost' _focus={{ boxShadow: "none"}}>
                        お知らせ
                    </Button>
                </Link>
            </HStack>
            <HStack>
                {bookmark ? (<Icon as={FaCaretRight} />) : (<></>) }
                <Link as={ReachLink} to="/m/bookmark">
                    <Button w="200px" bgColor='white' variant='ghost' _focus={{ boxShadow: "none"}}>
                        ブックマーク
                    </Button>
                </Link>
            </HStack>
            <HStack>
                {listing ? (<Icon as={FaCaretRight} />) : (<></>) }
                <Link as={ReachLink} to="/m/settings/listing">
                    <Button w="200px" bgColor='white' variant='ghost' _focus={{ boxShadow: "none"}}>
                        出品管理
                    </Button>
                </Link>
            </HStack>
            <HStack>
                {history ? (<Icon as={FaCaretRight} />) : (<></>) }
                <Link as={ReachLink} to="/m/settings/history">
                    <Button w="200px" bgColor='white' variant='ghost' _focus={{ boxShadow: "none"}}>
                        購入履歴
                    </Button>
                </Link>
            </HStack>
        </VStack>
    );
};