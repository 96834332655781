import { Grid, Box, Center, Spinner, useBreakpointValue, Flex, Input, InputRightElement, InputGroup } from '@chakra-ui/react';
import React from 'react';
import axios from 'axios'
import { MarketHeader } from './marketConponents/MarketHeader';
import { SideBar } from '../../components/atoms/sideBar/SideBar';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';
import { MobileSideBar } from '../../components/atoms/sideBar/MobileSideBar';
import { Search2Icon } from '@chakra-ui/icons';
import MarketPostBox from './marketConponents/MarketPostBox';

export default function MarketSearch() {
    let Posts = [];
    const [posts, setPosts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [query, setQuery] = React.useState([]);
    const isMobile = useBreakpointValue({ base: true, lg: false })

    const enterClick = (e) => {
        if (e.key === 'Enter') {
            send_data();
        }
    }

    React.useEffect(() => {
        
        const load_post = async () => {
            let url = new URL(window.location.href);
            let params = url.searchParams;
            let q = params.get('q');
            if (!q) {
                return 0;
            }
            setLoading(true);
            setQuery(q);
            let data = new FormData();
            data.append('query', decodeURI(q));
            await axios.post('/api/market/search/', data, { withCredentials: true })
                .then(res => {
                    if (res.request.status === 200) {
                        // console.log(res.data);
                        for (let i=0;i<res.data.count;i++) {
                            let data = res['data'][i]
                            // console.log(data);
                            Posts.push({
                                postId: data.id,
                                title: data.title,
                                date: data.create_time,
                                imageUrl: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + data.post_id + "." + (data.thumbnail_url ? data.thumbnail_url : "png"),
                                likescount: data.favorite,
                                price: data.price,
                                icon: (data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + data.uuid + data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                                userName: data.username,
                            });
                            setPosts(Posts);
                        }
                    }
                })
                .finally(() => setLoading(false));
            }
        load_post()
    }, []);

    const change_query = (e) => setQuery(e.target.value);

    const send_data = async () => {
        if (query == null) return 0;
        window.location.href = ('/m/s?q='+query);
    }

    return(
        <>
            <HeadBlock title="Nextswan リセール" description="Nextswan上で購入したコンテンツをリセールすることができる場所です。"/>
            <MarketHeader/>
            <Flex>
                { isMobile ? "" : <SideBar mt={0} index="1" flex='24%' /> }
                <Box mx={0} flex='76%'>
                    { isMobile ? <MobileSideBar number={1} color='pink'/> : ""}
                    <Flex mt={5} mx={30} >
                        {/* <InputGroup maxW='100%'>
                            <Input borderRadius={50} minW='100%' focusBorderColor='#E67D5A' size='lg' onChange={change_query} value={query} onKeyPress={(e) => enterClick(e)}/>
                            <InputRightElement children={<Search2Icon color='#E67D5A' />} cursor='pointer' mt={1} mr={3} onClick={send_data} />
                        </InputGroup> */}
                        <InputGroup maxW='100%'>
                            <Input borderRadius={50} focusBorderColor='#E67D5A' size='lg' minW='100%' placeholder='キーワードを入力' pl={5} onChange={change_query} value={query} onKeyPress={(e) => enterClick(e)}/>
                            <InputRightElement children={<Search2Icon color='#E67D5A' w={5} h={5} />} cursor='pointer' mt={1} mr={3} onClick={send_data} />
                        </InputGroup>
                    </Flex>
                    { loading ? (
                        <Center h="100vh" w='100%'>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='#E67D5A'
                                size='xl'
                            />
                        </Center>
                    ) : (
                        <Box textAlign={'center'} mx='20px' mt='30px' >
                            <Grid justifyItems={'center'} alignItems={'center'} templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl:'repeat(4, 1fr)'}} gap={2} >             
                                <MarketPostBox posts = {posts}/>
                            </Grid>
                        </Box>
                    )}
                </Box>
            </Flex>
        </>
    );
}