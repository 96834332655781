//売上管理ページの完成形。

import React from 'react';
import { 
    Box, 
    Flex, 
    Stack, 
    useBreakpointValue, } from "@chakra-ui/react";
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import SettingsMenu from './settingsAtom/SettingsMenu';
import DashboardCore from './DashboardCore';

const DashboardMain = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false });

    return(
        <>
            <HeaderPlane plasement="top"/>
            <Flex justify="center" height="flex" size="full" mx='20px'>
                { isMobile ? "" :
                <Stack mr='100px'>
                    <Box h={16} />
                    <SettingsMenu mt={100} news={0} contents={0} account={0} dashboard={1}/>
                </Stack>
                }
                <DashboardCore />
            </Flex>
        </>
    );
};

export default DashboardMain;