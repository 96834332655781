// サイドバー
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import React from 'react'
import { SideBarData } from './SideBarData'

export function SideBar(props) {
  return (
    <Flex
      pos='sticky'
      top={0}
      h='100vh'
      boxShadow='2px 0 0 0 rgba(0, 0, 0, 0.05)'
      w='250px'
      flexDir='column'
      justifyContent="space-between"
    >
      <VStack>
        <Box h='auto' p={0} w='100%'>
          {SideBarData.map((value, key) => {
            return (
              <Box
                key={key}
                id={ window.location.pathname === value.link ? "active" : "" }
                w='100%'
                h='60px'
                bgColor={ props.index === value.index ? "gray.100" : "white" }
                display='flex'
                color='gray'
                justifyContent='center'
                alignItems='center'
                cursor='pointer'
                _hover={{ opacity: 0.8 }}
                onClick={()=>{
                  window.location.pathname = value.link;
                }}
              >
                <Box flex='24%' display='grid' placeItems='center' >{value.icon}</Box>
                <Text flex='76%' fontSize='lg' mr={3} fontWeight='bold' color={value.color} >{value.title}</Text>
              </Box>
            )
          })}
        </Box>
      </VStack>
    </Flex>
  )
}