import { Text, Link, Wrap, WrapItem, VStack, Box } from '@chakra-ui/react';
import React from 'react';
// import { Link as ReachLink } from 'react-router-dom';

const Footer = () => {
    return (
        <VStack style={{
            // position: "fixed",
            left: 0,
            bottom: 0,
            right: 0
          }}>
            <Box h={10}/>
            <Wrap spacing="24px" justify="center" p="30px 10px 10px" >
                <WrapItem>
                    <Link href="/term" target="_blank" rel="noreferrer" _focus={{ boxShadow: "none"}}><Text fontSize="sm">利用規約</Text></Link>
                </WrapItem>
                <WrapItem>
                    <Link href="/policy" target="_blank" rel="noreferrer" _focus={{ boxShadow: "none"}}><Text fontSize="sm">プライバシーポリシー</Text></Link>
                </WrapItem>
                <WrapItem>
                    <Link href="/commerce" _focus={{ boxShadow: "none"}}><Text fontSize="sm">特商法表記</Text></Link>
                </WrapItem>
                <WrapItem>
                    <Link target="_blank" rel="noreferrer" href="/company" _focus={{ boxShadow: "none"}}><Text fontSize="sm">運営会社</Text></Link>
                </WrapItem>
                <WrapItem>
                    <Link target="_blank" rel="noreferrer" href="https://forms.gle/WisnHj89LxKyHTXN8" _focus={{ boxShadow: "none"}}>
                        <Text fontSize="sm">お問い合わせ</Text>
                    </Link>
                </WrapItem>
                <WrapItem>
                    <Link target="_blank" rel="noreferrer" href="https://nextswan.notion.site/nextswan/Nextswan-ad74aee98ea04489b99bd72ce7e631fa" _focus={{ boxShadow: "none"}}>
                        <Text fontSize="sm">ヘルプセンター</Text>
                    </Link>
                </WrapItem>
            </Wrap>
            <Text pb={3} color='gray.500' >&copy; 2022 Meltly Inc.</Text>
            <Box h={4}/>
        </VStack>
    )
}

export default Footer