// リセールのタブ。NSとほぼ同じ
import React from 'react';
import { Tab, TabList, Tabs, Box} from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';

export default function MarketTabs(props) {
    /* ログインしているかを簡易的にスイッチで切り替える 
    const [user, setUser] = React.useState(false)
    const handleChange = () => setUser(!user); 
    
    なぜか？ おすすめは登録者には出さない → 急上昇にリダイレクト*/

    return(
        <Box paddingBottom="30px">
            {/* <Switch size='md' onChange={handleChange} />ログイン */}
            <Tabs minW='76vw' maxW='100vw' defaultIndex={props.number} isManual align='left' colorScheme='orange' overflowX='auto' flexWrap='nowrap'>
                <TabList>
                    {/* {user ? <Tab as={ReachLink} to="/">おすすめ</Tab> : '' } */}
                    <Tab as={ReachLink} to="/m" whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>おすすめ</Tab>
                    <Tab as={ReachLink} to="/m/newest" whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>新着</Tab>
                    {/* <Tab as={ReachLink} to='/m/academia' whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>アカデミア</Tab> */}
                    {/* <Tab as={ReachLink} to='/m/business' whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>ビジネス</Tab> */}
                    {/* <Tab as={ReachLink} to='/m/technology' whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>テクノロジー</Tab> */}
                </TabList>
            </Tabs>
        </Box>
    )
}