import React from 'react';
import axios from 'axios';
import { Box, Flex, Heading, HStack, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import SettingsMenu from './settingsAtom/SettingsMenu';
import { ShowTime } from '../../components/atoms/ShowTime';

//出品管理ページ本体のコード
const History = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false });
    const [loading, setLoading] = React.useState(false);
    const [historys, setHistorys] = React.useState([]);

    React.useEffect(() => {
        setLoading(true);
        const load_history = async () => {
        await axios.get("/api/purchase_history/", {withCredentials: true})
        .then(res => {
            let history = [];
            for (let i=0;i<res.data.count;i++) {
                let data = res['data'][i]
                // console.log(data);
                history.push({
                    title: data.title,
                    price: data.price,
                    time: data.time,
                });
            }
            setHistorys(history);
        });
        setLoading(false);
    }
    load_history();
    }, []);
    
    return(
        <>
            <HeadBlock title="購入履歴"/>
            <HeaderPlane plasement="top"/>
            <Flex justify="center" height="flex" size="full" mx='20px'>
                { isMobile ? "" :
                    <Stack mr='100px'>
                        <Box h={16} />
                        <SettingsMenu mt={100} history={1}/>
                    </Stack>
                }
                <VStack mx={8} py={4} align='stretch' maxW={450} minW={{base: '', md: '450px'}}>
                    <Heading align='center' py={5}>購入履歴</Heading>
                    {historys.map((value, key) => {
                        return(
                            <Box key={key}>
                                <hr/>
                                <Stack pt={2}>
                                    <Text noOfLines={2} fontSize="sm" fontWeight="bold" textAlign={"left"}>
                                        {value.title}
                                    </Text>
                                    <HStack>
                                        <ShowTime date0={value.time} size={'sm'}/>
                                        <Text fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                            に{value.price}円で購入しました。
                                        </Text>
                                    </HStack>
                                </Stack>
                            </Box>
                        );
                    })}
                </VStack>
            </Flex>
        </>
    );
};

export default History;