import React from 'react';
import axios from 'axios'
import { Box, Button, Center, HStack, Heading, Link, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Spacer, Stack, Text, VStack } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

import Modal from 'react-modal';
import { ShowDate } from '../../../components/atoms/ShowDate';

Modal.setAppElement('#root')

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
};  

export default function ReleasedBox(props) {
    const { title, postDate, postid } =props;

    const [ isModal, setIsModal ] = React.useState(false);
    const navigate = useNavigate();

    const archive_post = async () => {
        const data = new FormData();
        data.append("post_id", postid);
        await axios.post("/api/archive-post/", data, { withCredentials: true })
        .then(res => {
            window.location.reload();
        });
    }

    return(
        <Box pb="20px">
            <Modal style={customStyles} isOpen={isModal} onRequestClose={() => setIsModal(false)}>
                <Center>
                    <VStack>
                        <Heading size="md">本当にこの投稿を非公開にしますか？</Heading>
                        <Text fontSize="sm">※ 非公開にした投稿は二度と公開状態に戻せません</Text>
                        <hr width="100%" />
                        <HStack spacing="16px">
                            <Link color='black' fontSize='sm' onClick={() => setIsModal(false)}>キャンセル</Link>
                            <Spacer />
                            <Link color='red.600' fontSize='sm' onClick={archive_post}>非公開にする</Link>
                        </HStack>
                    </VStack>
                </Center>
            </Modal>
            <HStack>
                <Box>
                <Stack>
                    <Text noOfLines={2} fontSize="sm" fontWeight="bold" textAlign={"left"}>
                        {title}
                    </Text>
                    <ShowDate time = {postDate} />
                </Stack>
                </Box>
                <Spacer />
                <Button minW='60px' maxW='60px' bg='white' color='black' borderColor='black' variant='outline' onClick={() => {navigate('/edit/' + postid + '/')}} _focus={{ boxShadow: "none"}}>
                    編集
                </Button>
                <Popover>
                    <PopoverTrigger>
                        <ChevronDownIcon color='gray' m={{ base: 1, md: 2 }} />
                    </PopoverTrigger>
                    <PopoverContent w='150px' _focus={{ boxShadow: "none"}}>
                        <PopoverArrow />
                        <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                        <PopoverBody>
                            <Link color='black' fontSize='sm' onClick={() => setIsModal(true)}>非公開化</Link>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </HStack>
        </Box>
    );
};