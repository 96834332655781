import { AspectRatio, Box, Center, GridItem, HStack, Icon, Image, Spacer, Spinner, Stack, Text } from '@chakra-ui/react';
import React, { memo, useEffect } from 'react';
import { FaRegHeart } from 'react-icons/fa';
import { Link as ReachLink } from 'react-router-dom';
import axios from 'axios'
import { ShowTime } from '../../components/atoms/ShowTime';

const UserArchives = memo(() => {
    let Posts = [];
    const [posts, setPosts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        const load_post = async () => {
            await axios.get('/api/list/archive-post/', { withCredentials: true })
                .then(res => {
                    if (res.request.status === 200) {
                        for (let i=0;i<res.data.count;i++) {
                            let data = res['data'][i]
                            Posts.push({
                                postId: data.id,
                                title: data.title,
                                date: data.create_time,
                                imageUrl: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + data.id + "." + (data.thumbnail_url ? data.thumbnail_url : "png"),
                                likescount: data.favorite,
                            });
                            setPosts(Posts);
                        }
                     }
                })
                .finally(() => setLoading(false));
            }
        load_post()
    }, [])
    
    if (loading) {
        return(
        <Center>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='pink.500'
                size='xl'
            />
        </Center>
        )
    } else {
        return (
            posts.map((post) => (
                <GridItem as={ReachLink} to={'/p/' + post.postId}>
                    <Box w={{base:'300px' , lg: '230px'}} h={{base:'260px' , lg: '200px'}} mx='1px' overflow='auto' _hover={{ cursor: "pointer", opacity: 0.8 }}>
                        {/* <Box textAlign="center"> */}
                            <AspectRatio ratio={16 / 9}>
                                <Image
                                    borderRadius={4}
                                    boxSize="100px"
                                    src={post.imageUrl}
                                    alt=""
                                />
                            </AspectRatio>
                        {/* </Box> */}
                        <Box>
                            <HStack>
                                <Stack height={12} lineHeight='normal'>
                                    <Box height={10}>
                                        <Text fontSize="sm" fontWeight="bold" textAlign={"left"} noOfLines={2}>
                                            {post.title}
                                        </Text>
                                    </Box>
                                    <HStack>
                                        <Icon as={FaRegHeart} color={'#CC5083'} w={4} h={4} ml={1} />
                                        <Text color={'#CC5083'}>{post.likescount}</Text>
                                        <Spacer />
                                        <ShowTime date0 = {post.date} />
                                    </HStack>
                                </Stack>
                            </HStack>
                        </Box>
                    </Box>
                </GridItem>
            ))
        );
    };
});

export default UserArchives