import React from 'react';
import { Flex, Heading, VStack, Text } from "@chakra-ui/react";
import { HeaderPlane } from '../../../components/organisms/layout/HeaderPlane';

const SendEmail = () => {
    return(
        <>
            <HeaderPlane />
            <Flex mt={10} justify="center" height="flex" size="full">
                <VStack spacing={6} py={4} px={10} align='stretch' w={450}>
                    <Heading as="h1" size="lg" textAlign="center">
                        メールを送信しました
                    </Heading>
                    <Text fontSize='md' color='gray.700' pt='10px' >
                        パスワードを再設定するためのリンクを、先ほど入力していただいたメールアドレスにお送りしました。ご確認ください。
                    </Text>
                </VStack>
            </Flex>
        </>
    )
};
export default SendEmail