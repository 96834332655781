import { Button, ButtonGroup, Spacer, HStack, Text, Icon } from '@chakra-ui/react';
import React, {useMemo} from 'react';
import axios from 'axios'
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';

import { FaCcAmex, FaCcVisa, FaCcMastercard, FaCcJcb } from 'react-icons/fa';


const useOptions = () => {
    const options = useMemo(
        () => ({
            style: {
                base: {
                  color: "#424770",
                  letterSpacing: "0.025em",
                  fontFamily: "Source Code Pro, monospace",
                  "::placeholder": {
                    color: "#aab7c4"
                  }
                },
                invalid: {
                  color: "#9e2146"
                }
            }
        }), []
    )

    return options
}

export default function CardForm(props) {
    // const {cardNum, deadline, name} =props;

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();


    const handleSubmit = async (event) => {
    event.preventDefault();

        const pay_data = new FormData();
        const result = await stripe.createToken(elements.getElement(CardNumberElement));
        if (result.error) {
            alert(result.error.message);
            return ;
        }
        pay_data.append("token", result.token.id);
        axios.post("/api/card_secret/", pay_data, { withCredentials: true })
        .then(res => {
        if (res.data.error) {
            alert(res.data.error);
            return ;
        }
        alert("クレジットカードの登録が完了しました。");
    });
}

    const delete_card = async () => {
        await axios.get('/api/delete_card/', { withCredentials: true })
        .then(res => {
            alert("クレジットカードが削除されました。");
            window.location.reload();
        });
    }

    return(
        <>
            <form onSubmit={handleSubmit} minW="1040px">
                <label>
                    カード番号
                    <CardNumberElement options={options} />
                    <HStack>
                        <Text fontSize="sm" color='blackAlpha.600'>対応カード: </Text>
                        <Icon as={FaCcVisa} color='blackAlpha.400' w={8} h={8} />
                        <Icon as={FaCcMastercard} color='blackAlpha.400' w={8} h={8} />
                        <Icon as={FaCcAmex} color='blackAlpha.400' w={8} h={8} />
                        <Icon as={FaCcJcb} color='blackAlpha.400' w={8} h={8} />
                    </HStack>
                </label>
                <br />
                <label>
                    有効期限（MM/YY）
                    <CardExpiryElement options={options} value="****" />
                </label>
                <label>
                    セキュリティコード（CVC）
                    <CardCvcElement options={options} />
                </label>
                <Spacer />
                <ButtonGroup display='flex' justifyContent='flex-end'>
                    <Button
                        type='button'
                        bg="#CCC"
                        size='sm'
                        color='white'
                        variant='solid'
                        _focus={{ boxShadow: "none"}}
                        onClick={delete_card}
                    >カード情報の削除</Button>
                    <Button
                        type='submit'
                        bg="#E9B5D2"
                        size='sm'
                        color='white'
                        variant='solid'
                        _focus={{ boxShadow: "none"}}
                    >カード情報の変更</Button>
                </ButtonGroup>
            </form>
        </>
    );
};