import { Grid, Box, Center, Spinner, useBreakpointValue, Flex } from '@chakra-ui/react';
import React from 'react';
import axios from 'axios'
import OriginalTabs from '../../components/atoms/Tabs';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import PostBox from '../../components/atoms/postbox/PostBox';
import { SideBar } from '../../components/atoms/sideBar/SideBar';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';
import { MobileSideBar } from '../../components/atoms/sideBar/MobileSideBar';

export default function Top() {
    let Posts = [];
    const [posts, setPosts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const isMobile = useBreakpointValue({ base: true, lg: false })

    React.useEffect(() => {
        setLoading(true);
        const load_post = async () => {
            await axios.get('/api/list/recommend/', { withCredentials: true })
                .then(res => {
                    if (res.request.status === 200) {
                        for (let i=0;i<res.data.count;i++) {
                            let data = res['data'][i]
                            Posts.push({
                                postId: data.id,
                                title: data.title,
                                date: data.create_time,
                                imageUrl: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + data.id + "." + (data.thumbnail_url ? data.thumbnail_url : "png"),
                                likescount: data.favorite,
                                price: data.price,
                                icon: (data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + data.uuid + data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                                userName: data.username,
                                remain: data.remain,
                            });
                            setPosts(Posts);
                        }
                     }
                })
                .finally(() => setLoading(false));
            }
        load_post()
    }, []);
    return(
        <>
            <HeadBlock />
            <HeaderPlane />
            <Flex>
                { isMobile ? "" : <SideBar mt={0} index="0" flex='24%' /> }
                <Box mx={0} flex='76%'>
                    { isMobile ? <MobileSideBar number={0} color='pink'/> : ""}
                    <OriginalTabs number={0} />
                    { loading ? (
                        <Center h="100vh" w='100%'>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='#CC5083'
                                size='xl'
                            />
                        </Center>
                    ) : (
                        <Box textAlign={'center'} mx='20px'>
                            <Grid justifyItems={'center'} alignItems={'center'} templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl:'repeat(4, 1fr)'}} gap={2} >             
                                <PostBox posts = {posts}/>
                            </Grid>
                        </Box>
                    )}
                </Box>
            </Flex>
        </>
    );
}