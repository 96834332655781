import { Box, Button, Center, Flex, Heading, Image, VStack, Text, Stack, Spacer } from '@chakra-ui/react';
import React from 'react';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import "react-quill/dist/quill.core.css";
import { useNavigate, useLocation } from "react-router-dom";
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';
import ImageImage from "../../images/type-movie.png";

export default function AfterPost (props) {
    // モーダルの開閉状態

    const location = useLocation();
    const navigate = useNavigate();

    const GoToDetail = () => {
        const postid = location.pathname.split("/")[3]
        navigate('/p/' + postid);
    }


    return (
        <>
            <HeadBlock/>
            <HeaderPlane/>
            <hr />
            {/* 中央のコンテンツ */}
            <Flex pt={5} justify="center" mx={{base: '20px', md: '100px'}}>
                {/* <AspectRatio w="640px" ratio={16 / 9}></AspectRatio> */}
            </Flex>

            <Center pt="30px" mx={{base: '0px', md: '100px'}}>
                <VStack maxW={{base: "95vw", md: '700px'}}>
                    {/* タイトル */}
                    <Stack>
                    {/* <VStack> */}
                        <Heading size="xl" maxW="640px" flexWrap='wrap'>執筆お疲れ様でした！</Heading>
                    {/* </VStack> */}
                    <Box h='20px'/>
                        <Text fontSize='xl'>
                            現在、投稿を宣伝するためのショート動画を自動生成中です。
                        </Text>
                        <Text fontSize='xl'>
                            生成が完了したら、メールとサービス内のお知らせにてご連絡します。
                        </Text>
                        <Text fontSize='xl'>
                            ダウンロードして、TwitterやTikTok、Instagram リール、YouTube ショートなどに投稿することで、より多くの人にコンテンツを読んでもらうことができます。
                        </Text>
                        <Text fontSize='xl'>
                        ぜひご活用ください。
                        </Text>
                    {/* </Stack> */}
                    <Box h='40px'/>
                    <Flex>
                        <Spacer/>
                    <Button
                        align='center'
                        width="300px"
                        bg={"#CC5083"}
                        color={'white'}
                        _hover={{ opacity: 0.8 }}
                        variant='solid'
                        _focus={{ boxShadow: "none"}}
                        onClick={GoToDetail}
                        >投稿したコンテンツへ
                    </Button>
                        <Spacer/>
                    </Flex>
                    <Box h='40px'/>
                    {/* <Stack> */}
                        <Heading size='lg'>
                            生成される動画のイメージ
                        </Heading>
                    <Box h='10px'/>
                    {/* <AspectRatio w="640px" ratio={16 / 9}> */}
                        <Image src={ImageImage} style={{ objectFit: 'cover', width: '100%' }} />
                    {/* </AspectRatio> */}
                    <Box h='5px'/>
                        <Text fontSize='lg' align='left'>
                            ※動画は3種類あり、ランダムでどれか1種類が生成されます。
                        </Text>
                    </Stack>
                </VStack> 
            </Center>
        </>
    )
}