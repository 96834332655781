// Nextswan marketのトップページ
import React from 'react';
import axios from 'axios';
import { Link as ReachLink } from "react-router-dom";
import { Grid, Box, Center, Spinner, useBreakpointValue, Flex, VStack, Text, Button } from '@chakra-ui/react';
import { MarketHeader } from './marketConponents/MarketHeader';
import MarketTabs from './marketConponents/MarketTabs';
import { SideBar } from '../../components/atoms/sideBar/SideBar';
import { MobileSideBar } from '../../components/atoms/sideBar/MobileSideBar';
import MarketPostBox from './marketConponents/MarketPostBox';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';

export default function MarketTop() {
    // ここからreturnまで現行Nextswanのパクリ
    let Posts = [];
    const [posts, setPosts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        const load_post = async () => {
            await axios.get('/api/list/market/recomend/', { withCredentials: true })
                .then(res => {
                    if (res.request.status === 200) {
                        // console.log(res.data);
                        for (let i=0;i<res.data.count;i++) {
                            let data = res['data'][i]
                            // console.log(data);
                            Posts.push({
                                postId: data.id,
                                title: data.title,
                                date: data.create_time,
                                imageUrl: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + data.post_id + "." + (data.thumbnail_url ? data.thumbnail_url : "png"),
                                likescount: data.favorite,
                                price: data.price,
                                icon: (data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + data.uuid + data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                                soldOut: data.soldOut,
                            });
                            setPosts(Posts);
                        }       
                    }
                })
                .finally(() => setLoading(false));
            }
        load_post()
    }, []);

    const isMobile = useBreakpointValue({ base: true, lg: false })
    const isWidthSize = useBreakpointValue({ base: true, md: false })
    const buttonSize = useBreakpointValue(['md', 'lg', 'lg']);
    const fontSize = useBreakpointValue(['lg', 'xl', 'xl']);

    return(
        <>
            <HeadBlock title="Nextswan リセール" description="Nextswan上で購入したコンテンツをリセールすることができる場所です。"/>
            <MarketHeader />
            <Flex>
                { isMobile ? "" : <SideBar mt={0} index="1" flex='24%' /> }
                <Box flex='76%'>
                    { isMobile ? <MobileSideBar number={1} color='orange'/> : ""}
                    <MarketTabs number={0}/>
                    { loading ? (
                        <Center h="100vh" w='100%' >
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='#E67D5A'
                                size='xl'
                            />
                        </Center>
                    ) : (
                        <>
                            <VStack>
                                { isWidthSize ? (
                                    <VStack>
                                        <Text fontSize={fontSize} fontWeight='bold' align='left'>
                                            Nextswan リセールは、
                                        </Text>
                                        <Text fontSize={fontSize} fontWeight='bold' align='left'>
                                            購入したコンテンツを
                                        </Text>
                                        <Text fontSize={fontSize} fontWeight='bold' align='left'>
                                            ユーザー同士で売買できる場所です。
                                        </Text>
                                    </VStack>
                                ) : (
                                    <VStack>
                                        <Text fontSize='3xl' fontWeight='bold'>
                                            Nextswan リセールは、購入したコンテンツを
                                        </Text>
                                        <Text fontSize='3xl' fontWeight='bold'>
                                            ユーザー同士で売買できる場所です。
                                        </Text>
                                    </VStack>
                                )}
                                <Box h={{base: '15px', md: '24px'}}/>
                                <Button
                                    as={ReachLink}
                                    to="/m/listing"
                                    bg={"#E67D5A"}
                                    color={'white'}
                                    _hover={{ opacity: 0.8 }}
                                    align="center"
                                    size={buttonSize}
                                    _focus={{ boxShadow: "none"}}
                                    >出品する
                                </Button>
                            </VStack>
                            <Box h={{base: '40px', md: '56px'}}/>
                            <Grid px={5} display='grid' justifyItems={'center'} alignItems={'center'} templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl:'repeat(4, 1fr)'}} gap={3} >             
                                <MarketPostBox posts={posts} color='#E67D5A' />
                            </Grid>
                        </>
                    )}
                </Box>
            </Flex>
        </>
    );
}