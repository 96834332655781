import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Button, VStack, Text } from "@chakra-ui/react";
import { HeaderPlane } from '../../../components/organisms/layout/HeaderPlane';

const PasswordComplete = () => {
    const navigate = useNavigate();
    const toLogin = async () => {
        navigate('/login');
    }
    return(
        <>
            <HeaderPlane />
            <Flex mt={10} justify="center" height="flex" size="full">
                <VStack spacing={6} py={4} px={10} align='stretch' w={450}>
                    <Heading as="h1" size="lg" textAlign="center">
                        パスワード再設定の完了
                    </Heading>
                    <Text fontSize='sm' color='gray.700' pt='10px' >
                        パスワードを再設定できました。再度ログインしてください。
                    </Text>
                    <Button
                        bg={"#CC5083"}
                        color={'white'}
                        _hover={{ opacity: 0.8 }}
                        variant='solid'
                        onClick={toLogin}
                        _focus={{ boxShadow: "none"}}
                    >ログインページへ</Button>
                </VStack>
            </Flex>
        </>
    )
};
export default PasswordComplete