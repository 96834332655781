//数字を3桁ごとにカンマで区切る。ShowPriceSのS＝小さな文字

import { Text } from '@chakra-ui/react';
import React from 'react';

export const ShowPriceS = (props) => {
    const {price} = props;
    const localeNum = price.toLocaleString();
    return(
        <Text p={1} color={'white'} >{localeNum}円</Text>
    )
}
export const ShowPriceBlack = (props) => {
    const {price} = props;
    const localeNum = price.toLocaleString();
    return(
        <Text size="sm" p={1} pt={2} >{localeNum}円</Text>
    )
}
export const ShowPriceBlack2 = (props) => {
    const {price} = props;
    const localeNum = price.toLocaleString();
    return(
        <Text size="sm" p={1}>{localeNum}円</Text>
    )
}
export const ShowPriceGray = (props) => {
    const {price} = props;
    const localeNum = price.toLocaleString();
    return(
        <Text fontSize="md" color="#797979">{localeNum}円</Text>
    )
}
export const ShowPriceOrange = (props) => {
    const {price} = props;
    const localeNum = price.toLocaleString();
    return(
        <Text fontSize="3xl" color="#E67D5A">{localeNum}</Text>
    )
}