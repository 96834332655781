import { Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';

export const ShowDate = (props) => {
    const time = props;
    const year = time.time.slice(0,4);
    const month0 = time.time.slice(5,7);
    const month = month0 * 1;
    const date0 = time.time.slice(8,10);
    const date =  date0 * 1;
    const hour0 = time.time.slice(11,13);
    const hour=  hour0 * 1;
    const minute = time.time.slice(14,16);
    return(
        <Text color='gray.600' fontSize='sm' align='left' >{year}年{month}月{date}日 {hour}:{minute}</Text>
    )
}


export const ShowDateSmall = (props) => {
    const fontSize = useBreakpointValue({ base: 'xs', lg: 'sm' });
    const time = props;
    const year = time.time.slice(0,4);
    const month0 = time.time.slice(5,7);
    const month = month0 * 1;
    const date0 = time.time.slice(8,10);
    const date =  date0 * 1;
    const hour0 = time.time.slice(11,13);
    const hour=  hour0 * 1;
    const minute = time.time.slice(14,16);
    return(
        <Text color='gray.600' fontSize={fontSize} align='left' minW='130px' >{year}年{month}月{date}日 {hour}:{minute}</Text>
    )
}

export const ShowDate2 = (props) => {
    const time = props;
    const year = time.time.slice(0,4);
    const month0 = time.time.slice(5,7);
    const month = month0 * 1;
    const date0 = time.time.slice(8,10);
    const date =  date0 * 1;
    const hour = time.time.slice(11,13);
    const minute = time.time.slice(14,16);
    const second = time.time.slice(17,19);
    return(
        <Text color='gray.600' fontSize='sm' >{year}年{month}月{date}日 {hour}:{minute}:{second}</Text>
    )
}
