import React from 'react';
import { TableLine } from '../../../components/atoms/table/TableLine';

export const ContentsTableLine = (props) => {
    const {contents} = props;
    return (
        contents.map((content) => (
            <TableLine left={content.title} center={content.detailA} center2={content.detailA2} right={content.detailB} />
        ))
    );
};