import React from 'react';
import { Box, Center, Checkbox, Flex, FormControl, FormLabel, Heading, Link, Spacer, Spinner, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import SettingsMenu from './settingsAtom/SettingsMenu';
import SettingsTabs from './settingsAtom/SettingsTabs';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const UserEdit = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false });
    const [loading, setLoading] = React.useState(false);
    const [allowEmail, setAllowEmail] = React.useState(true);
    const navigate = useNavigate()
    const [user_info, setUser_info] = React.useState({
        name: '',
        id: '',
        email: '',
        emailState: '',
    });

    React.useEffect(() => {
        setLoading(true);
        const load_userinfo = async () => {
            await axios.post('/api/mypage/', { withCredentials: true })
            .then(res => {
                // console.log(res.data);
                setUser_info({
                    name: res.data.username,
                    id: res.data.userid,
                    email: res.data.email,
                    emailState: res.data.mail,
                });
                setAllowEmail(res.data.mail);
            })
            .finally(() => setLoading(false));
        }
        load_userinfo();
    }, []);
    const passwordChange = async () => {
        if (window.confirm("パスワード再設定用のURLをメールで送信します。よろしいですか？")) {
            let data = new FormData();
            data.append("email", user_info.email);
            await axios.post('/api/password_reset/', data, { withCredentials: true })
            .then(res => {
                if (res.request.status === 200) {
                    navigate('/settings/password/send');
                };
            })
        }
    }
    const handleLogout = async () => {
        await axios.post('/api/logout/', {withCredentials: true});
        navigate('/');
    }
    const switchEmail = async () => {
        setAllowEmail(!allowEmail);
        console.log(allowEmail);
        await axios.get('/api/setting-email/');
    }

    // console.log(allowEmail)
    if (loading) {
        return(
        <Center h="100vh">
            <Spinner 
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='pink.500'
              size='xl'
            />
        </Center>
        )
    } else {
    return(
        <>
            <HeaderPlane />
            <Flex justify="center" height="flex" mx='100px'>
                { isMobile ? "" :
                <Stack mr='100px'>
                    <Box h={16} />
                    <SettingsMenu mt={100} news={0} contents={0} account={1} dashboard={0} />
                </Stack>
                }
                <VStack spacing={6} py={4} mx={8} align='stretch' w={450}>
                    <SettingsTabs number={0} />
                    {/* <UserInfo name={user_info.name} id={user_info.id} email={user_info.email} loading={loading} emailState={user_info.emailState} /> */}
                    <Heading as="h2" size="sm">ユーザーネーム</Heading>
                    <Text>{user_info.name}</Text>
                    <Heading as="h2" size="sm">ユーザーID</Heading>
                    <Text>{user_info.id}</Text>
                    <Heading as="h2" size="sm">メールアドレス</Heading>
                    <Text>{user_info.email}</Text>
                    <Heading as="h2" size="sm">メール通知</Heading>
                    <FormControl display='flex' alignItems='center'>
                        { allowEmail ? (
                            <Checkbox colorScheme='pink' _focus={{ boxShadow: "none"}} onChange={switchEmail} defaultChecked/>
                        ) : (
                            <Checkbox colorScheme='pink' _focus={{ boxShadow: "none"}} onChange={switchEmail} />
                        )}
                        <FormLabel htmlFor='email-alerts' ml={2} mb='0'>
                            Nextswanからの大切なお知らせを受け取る
                        </FormLabel>
                        <Spacer/>
                    </FormControl>
                    <Spacer />
                    <Link onClick={passwordChange} fontSize='sm' align="center">パスワード変更</Link>
                    <Link onClick={handleLogout} fontSize='sm' align="center">ログアウト</Link>
                </VStack>
            </Flex>
        </>
    )}
};
export default UserEdit