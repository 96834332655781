import React from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Button, FormControl, FormLabel, Input, Spacer, VStack, Text } from "@chakra-ui/react";
import { HeaderPlane } from '../../../components/organisms/layout/HeaderPlane';

const PasswordReset = () => {
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const [email, setEmail] = React.useState('');
    const emailchange = (e) => {
        setEmail(() => e.target.value)
    }

    const send_data = async () => {
        setLoading(true);
        const data = new FormData();
        data.append("email", email);
        await axios.post('/api/password_reset/', data, { withCredentials: true })
        .then(res => {
            if (res.request.status === 200) {
                navigate('/settings/password/send');
            };
        })
        // .catch(res => {
        //     if (res.request.status === 400) {
        //         alert('このパスワードは無効です。');
        //     }
        // })
        .finally(() => setLoading(false));
    }

    return(
        <>
            <HeaderPlane />
            <Flex mt={10} justify="center" height="flex" size="full">
                <VStack spacing={6} py={4} px={10} align='stretch' w={450}>
                    <Heading as="h1" size="lg" textAlign="center">
                        ログインできない場合
                    </Heading>
                    <Text fontSize='sm' color='gray.700' pt='10px' >
                        登録時に使用したメールアドレスを入力してください。パスワードを再設定するためのリンクを送信します。
                    </Text>
                    <FormControl isRequired>
                        <FormLabel htmlFor='email'>メールアドレス</FormLabel>
                        <Input id='email' type='email' onChange={emailchange} value={email} focusBorderColor='#CC5083'/>
                    </FormControl>
                    <Spacer />
                    { loading ? (
                        <Button
                            bg={"#CC5083"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            variant='solid'
                            onClick={send_data}
                            _focus={{ boxShadow: "none"}}
                            disabled={email === ""}
                            isLoading
                        >リンクを送信</Button>
                    ) : (
                        <Button
                            bg={"#CC5083"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            variant='solid'
                            onClick={send_data}
                            _focus={{ boxShadow: "none"}}
                            disabled={email === ""}
                        >リンクを送信</Button>
                    )}
                </VStack>
            </Flex>
        </>
    )
};
export default PasswordReset