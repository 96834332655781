import React, {useMemo} from 'react';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import axios from 'axios'
import './marketSettlement.css'
import { Button, Checkbox, HStack, VStack, Text, Icon } from '@chakra-ui/react';
import { FaCcAmex, FaCcVisa, FaCcMastercard, FaCcJcb } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const useOptions = () => {
    const options = useMemo(
        () => ({
            style: {
                base: {
                  color: "#424770",
                  letterSpacing: "0.025em",
                  fontFamily: "Source Code Pro, monospace",
                  "::placeholder": {
                    color: "#aab7c4"
                  }
                },
                invalid: {
                  color: "#9e2146"
                }
            }
        }), []
    )

    return options
}

const MarketCheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const options = useOptions();

    const [save, setSave] = React.useState(true)

    const [process, setProcess] = React.useState(false)
    
    const navigate = useNavigate()


    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcess(true);
        const pay_data = new FormData();
        // console.log(props.saved);
        if (!props.saved){
        const result = await stripe.createToken(elements.getElement(CardNumberElement));
        // console.log(result);
        if (result.error) {
            alert(result.error.message);
            setProcess(false);
            return ;
        }
        pay_data.append("token", result.token.id);
        }
        pay_data.append("market_id", props.market_id);
        pay_data.append("save", save);
        axios.post("/api/market/payment/", pay_data, { withCredentials: true })
        .then(res => {
        if (res.data.error) {
            alert("この記事は既に買われてしまったか、そのカードは使用できない可能性があります");
            window.location.href = '/m'
            return ;
        }
        // console.log(res.data);
        navigate("/p/"+res.data.post_id);
        });
    }

    return (
        <form onSubmit={handleSubmit} minW="1040px">
            {!props.saved ? (
            <>
            <label>
                カード番号
                <CardNumberElement options={options} />
                <HStack>
                    <Text fontSize="sm" color='blackAlpha.600'>対応カード: </Text>
                    <Icon as={FaCcVisa} color='blackAlpha.400' w={8} h={8} />
                    <Icon as={FaCcMastercard} color='blackAlpha.400' w={8} h={8} />
                    <Icon as={FaCcAmex} color='blackAlpha.400' w={8} h={8} />
                    <Icon as={FaCcJcb} color='blackAlpha.400' w={8} h={8} />
                </HStack>
            </label>
            <br />
            <label>
                有効期限（MM/YY）
                <CardExpiryElement options={options} value="****" />
            </label>
            <label>
                セキュリティコード（CVC）
                <CardCvcElement options={options} />
            </label>
            </>
            ) : ("")}
            <VStack spacing="20px">
                {!props.saved ? (
                    <Checkbox defaultChecked onChange={() => {setSave(!save)}}>カード情報を保存する</Checkbox>
                ) : (<p>保存された決済情報を使用します</p>)}
                { process ? (
                    <Button type="submit" width="300px" bg={"#E67D5A"} color={'white'} colorScheme='orange' variant='solid' ids disabled>
                        {props.price}円で購入する
                    </Button>
                ):(
                    <Button type="submit" width="300px" bg={"#E67D5A"} color={'white'} colorScheme='orange' variant='solid' ids>
                        {props.price}円で購入する
                    </Button>
                )}
            </VStack>
        </form>
    )
}

export default MarketCheckoutForm;