import { Text } from '@chakra-ui/react';
import React from 'react';

export const ShowTime = (props) => {
    const date0 = props;
    const { size='md' } = props;
    const date2 = Date.parse(date0.date0);
    const current = new Date();
    const date1 = current.getTime();
    const diff = Math.floor((date1 - date2) / (60 * 1000));
    if (diff > 525948.75245){
        const year = Math.floor(diff / 525948.75245)
        return(
            <Text color={'#606060'} fontSize={size}>{year}年前</Text>
        )
    }else if (diff > 43800){
        const month = Math.floor(diff / 43800)
        return(
            <Text color={'#606060'} fontSize={size}>{month}ヶ月前</Text>
        )
    }else if (diff > 10080){
        const week = Math.floor(diff / 10080)
        return(
            <Text color={'#606060'} fontSize={size}>{week}週間前</Text>
        )
    }else if (diff > 1440){
        const day = Math.floor(diff / 1440)
        return(
            <Text color={'#606060'} fontSize={size}>{day}日前</Text>
        )
    }else if (diff > 60){
        const hour = Math.floor(diff / 60)
        return(
            <Text color={'#606060'} fontSize={size}>{hour}時間前</Text>
        )
    }else{
        return(
            <Text color={'#606060'} fontSize={size}>{diff}分前</Text>
        )
    }
}

export const ShowTimeForPostBox = (props) => {
    const date0 = props;
    const { color = '#CC5083' } = props;
    const date2 = Date.parse(date0.date0);
    const current = new Date();
    const date1 = current.getTime();
    const diff = Math.floor((date1 - date2) / (60 * 1000));
    if (diff > 525948.75245){
        const year = Math.floor(diff / 525948.75245)
        return(
            <Text color={color} fontSize='sm'>{year}年前</Text>
        )
    }else if (diff > 43800){
        const month = Math.floor(diff / 43800)
        return(
            <Text color={color} fontSize='sm' >{month}ヶ月前</Text>
        )
    }else if (diff > 10080){
        const week = Math.floor(diff / 10080)
        return(
            <Text color={color} fontSize='sm' >{week}週間前</Text>
        )
    }else if (diff > 1440){
        const day = Math.floor(diff / 1440)
        return(
            <Text color={color} fontSize='sm' >{day}日前</Text>
        )
    }else if (diff > 60){
        const hour = Math.floor(diff / 60)
        return(
            <Text color={color} fontSize='sm' >{hour}時間前</Text>
        )
    }else{
        return(
            <Text color={color} fontSize='sm' >{diff}分前</Text>
        )
    }
}