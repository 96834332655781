import React from 'react';
import { Link as ReachLink } from 'react-router-dom';
import { Box, Heading, Link, ListItem, OrderedList, Text } from '@chakra-ui/react';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';

const Policy = () => {
    return (
        <>
            <HeadBlock title="プライバシーポリシー"></HeadBlock>
            <HeaderPlane/>
            <Box mx={{base:'20px', md: '100px', lg:'200px', xl: '300px'}} >
                <Heading size='lg' mt={8} pb={4} >
                    Nextswanプライバシーポリシー
                </Heading>
                <Text>Meltly株式会社（以下「当社」といいます。）は、当社の運営する「Nextswan」（以下「サービス」といいます。）をご利用いただくにあたり、個人情報保護の重要性を認識し、法令・ガイドラインを遵守して、個人情報を適正に取り扱うとともに、安全管理について適切な措置を講じ、これを維持、改善してまいります。</Text>
                <Heading size='md' mt={8} pb={4} >
                    利用する情報
                </Heading>
                <Text>当社が「利用目的に関する事項」に定める目的を達成するために取得することがある情報（以下「取得情報」といいます。）には、次のものが含まれます。</Text>
                <OrderedList>
                    <ListItem>氏名その他の特定の個人を識別することができる情報</ListItem>
                    <ListItem>特定の個人情報に結びついて使用されるご住所、メールアドレス、パスワード、ニックネーム等のサービスのアカウント情報</ListItem>
                    <ListItem>クレジットカード情報、銀行口座情報等の支払情報</ListItem>
                    <ListItem>お客様の本人確認に関する情報</ListItem>
                    <ListItem>お客様がサービスのアカウントを外部 SNS のアカウントと接続することを許可する場合に取得できる、お客様が接続を許可した外部 SNS の ID、プロフィール情報（ユーザー名、ニックネーム、プロフィール画像等を含みます）等の情報</ListItem>
                    <ListItem>お客様がサービスをご利用になる際に、自動的に収集および保存される、検索キーワード、閲覧したページ等当社サービスの使用状況の詳細、IP アドレス、端末のイベント情報（ブラウザの種類、ブラウザの言語、お客様によるリクエストの日時、参照URL等）、お客様のブラウザまたはお客様のサービスアカウントを特定できるCookie、ピクセルタグ等のサーバーログ内の特定の情報</ListItem>
                    <ListItem>端末固有の情報（ハードウェアモデル、オペレーティングシステムのバージョン、端末固有の ID、電話番号などのモバイルネットワーク情報等）</ListItem>
                    <ListItem>お客様がサービスに掲載・発信したデジタルコンテンツ、プロフィール、コメントその他の情報</ListItem>
                    <ListItem>お客様のサービスでの決済に関する情報</ListItem>
                    <ListItem>お客様からの問い合わせ時のお客様との通信の記録に関する情報</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    利用目的に関する事項
                </Heading>
                <Text>当社は、取得情報を取得・収集する際にお客様にお知らせした利用目的、利用規約に定める利用目的または以下の目的のために、取得情報を利用します。</Text>
                <OrderedList>
                    <ListItem>サービスを提供するため</ListItem>
                    <ListItem>お客様の指示に基づきサービス上に表示するため</ListItem>
                    <ListItem>サービスに関する各種事項の連絡や情報提供を行うため</ListItem>
                    <ListItem>お客様の許諾した外部 SNS との情報連携のため</ListItem>
                    <ListItem>サービスに関するメールを配信するため</ListItem>
                    <ListItem>サービスに関する請求、支払いとその確認等をするため</ListItem>
                    <ListItem>サービスに関する調査やイベントに関連する連絡のため</ListItem>
                    <ListItem>サービスの利用状況等を調査・分析するため</ListItem>
                    <ListItem>サービスの内容を改良・改善し、またはサービスの研究・開発のため</ListItem>
                    <ListItem>お客様に合わせてパーソナライズしたデジタルコンテンツおよびお客様に有益と考えられる情報等（お客様の嗜好と関連性がより高いと考えられるコンテンツや広告等の情報）を提供するため</ListItem>
                    <ListItem>取得した情報を統計的に処理するため</ListItem>
                    <ListItem>サービスのご案内やお知らせをお届けする等、マーケティングで利用するため</ListItem>
                    <ListItem>サービス運営上のトラブルの解決のため</ListItem>
                    <ListItem>サービスに関する不正利用防止や安全性の確保のため</ListItem>
                    <ListItem>サービスに関するお問い合わせに対応するため</ListItem>
                    <ListItem>上記に付帯・関連する目的のため</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    個人情報を提供いただくことの任意性について
                </Heading>
                <Text>個人情報の提供は義務的なものではなく、あくまでも任意のものです。ただし、提供いただけない場合、サービスの提供に支障が出ることがありますので、予めご了承ください。</Text>
                <Heading size='md' mt={8} pb={4} >
                    取得情報の第三者への提供
                </Heading>
                <Text>当社は、以下に定める場合、個人情報を第三者に提供することがあります。</Text>
                <OrderedList>
                    <ListItem>お客様の指示または同意に基づいて第三者に個人情報を提供する場合。</ListItem>
                    <ListItem>裁判所、監督官庁その他の公的機関から取得情報を提供するよう求められた場合等の個人情報保護法で第三者への個人情報の提供が認められている場合。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    取得情報の取扱いに関する第三者への委託
                </Heading>
                <Text>当社は、業務を円滑に進めお客様により良いサービスを提供するため、お客様の個人情報の取扱いを協力会社に委託する場合があります。ただし、委託する個人情報は、委託する業務を遂行するのに必要最小限の情報に限定するとともに、委託先との間で取扱いに関する契約の締結をはじめ、適切な監督を行います。</Text>
                <Heading size='md' mt={8} pb={4} >
                    「Cookie」等の利用
                </Heading>
                <Text>Cookie及びIPアドレス情報については、それら単独では特定の個人を識別することができないため、個人情報とは考えておりません。ただしこれらの情報と個人情報が一体となって使用される場合にはこれらの情報も個人情報とみなします。当サービスにおいては、たとえ特定の個人を識別することができなくとも、Cookie及びIPアドレス情報を利用する場合には、その目的と方法を開示してまいります。また、Cookie情報については、ブラウザの設定で拒否することが可能です。</Text>
                <Heading size='md' mt={8} pb={4} >
                    Google アナリティクスについて
                </Heading>
                <Text>当社は、サービスの利用状況等を調査・分析するため、サービス上にGoogle Inc.が提供するGoogleアナリティクスを利用しています。GoogleアナリティクスはCookieを使用して、サービスの利用状況等を把握しています。Google アナリティクスに関する説明については同社の
                    <Link as={ReachLink} to='https://policies.google.com/technologies/partner-sites?hl=ja'>「ユーザーがGoogle パートナーのサイトやアプリを使用する際のGoogleによるデータ使用」のページ</Link>
                    をご覧ください。ご自身のデータがGoogleアナリティクスで使用されることを望まない場合は、同社の提供する
                    <Link as={ReachLink} to='https://tools.google.com/dlpage/gaoptout?hl=ja'>Googleアナリティクスオプトアウトアドオン</Link>
                    をご利用ください。
                </Text>
                <Heading size='md' mt={8} pb={4} >
                    「ログ」および「機器情報」の使用等
                </Heading>
                <Text></Text>
                <OrderedList>
                    <ListItem>サーバーログ情報
                        <Text>お客様が当社のサービスをご利用になる際に、サーバーログ内の特定の情報が自動的に収集および保存されます。これには、検索キーワード、閲覧したページ等当社サービスの使用状況の詳細、IP アドレス、端末のイベント情報（ブラウザの種類、ブラウザの言語、お客様によるリクエストの日時、参照 URL など）、お客様のブラウザまたはお客様の当社サービスアカウントを特定できる Cookie、ピクセルタグなどの情報が含まれることがあります。</Text>
                    </ListItem>
                    <ListItem>端末情報
                        <Text>当社は、端末固有の情報（たとえば、ハードウェアモデル、オペレーティングシステムのバージョン、端末固有の ID、電話番号などのモバイルネットワーク情報）を収集することがあります。</Text>
                    </ListItem>
                    <ListItem>ローカルストレージ
                        <Text>当社は、HTML5 やアプリケーションデータのキャッシュのようなメカニズムを使用して、収集した情報（個人情報を含む）をお客様の端末にローカルに保存することがあります。</Text>
                    </ListItem>
                </OrderedList>
            </Box>
        </>
    )
}

export default Policy;