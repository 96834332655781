import React from 'react';
import { Tab, TabList, Tabs, Box} from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';

export default function SettingsTabs(props) {

    return(
        <Box paddingBottom="30px" overflow='auto' >
            {/* <Switch size='md' onChange={handleChange} />ログイン */}
            <Tabs isFitted defaultIndex={props.number} align='center' colorScheme='pink' overflowX='auto' flexWrap='nowrap'>
                <TabList>
                    {/* {user ? <Tab as={ReachLink} to="/">おすすめ</Tab> : '' } */}
                    <Tab as={ReachLink} to="/settings/account" whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>
                        アカウント
                    </Tab>
                    <Tab as={ReachLink} to="/settings/card" whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>
                        カード情報
                    </Tab>
                    <Tab as={ReachLink} to='/settings/transfer' whiteSpace='nowrap' _focus={{ boxShadow: "none"}}>
                        振込先
                    </Tab>
                </TabList>
            </Tabs>
        </Box>
    );
};