import React from 'react';
// import axios from 'axios'
import {Box, Flex, Stack, useBreakpointValue, VStack } from "@chakra-ui/react";
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import SettingsMenu from './settingsAtom/SettingsMenu';
import SettingsTabs from './settingsAtom/SettingsTabs';
import CardForm from './settingsAtom/CardForm';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// loadStripe
// Stripeのテスト用の公開可能キー
// const stripePromise = loadStripe("pk_test_51KjxcUFwZPjr5Y2qpaIzTHh1KmOrefvYgt3V9nNnIiFpYzgdxJMOnVwQOaaloMQrXOf5eWeMneYJxyh1xlfqdrUI009RnkA5jr");
const stripePromise = loadStripe("pk_live_51KjxcUFwZPjr5Y2ql0ByH9anQkshj4BsySMgXTZiRNT0OIjP2MNcKh6pse7YUOnh7M7AclU1t2Un1BWM2TxwcC0J00rXzEZuaN");
const CardSettings = () => {

    const isMobile = useBreakpointValue({ base: true, lg: false });

    return(
        <>
            <HeaderPlane />
            <Flex justify="center" height="flex" mx='100px'>
                { isMobile ? "" :
                <Stack mr='100px'>
                    <Box h={16} />
                    <SettingsMenu mt={100} news={0} contents={0} account={1} dashboard={0} />
                </Stack>
                }
                <VStack spacing={6} py={4} mx={8} align='stretch' w={450}>
                    <SettingsTabs number={1}/>
                    <Elements stripe={stripePromise} >
                        <CardForm />
                    </Elements>
                    
                </VStack>
            </Flex>
        </>
    );
};

export default CardSettings;