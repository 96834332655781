import React from 'react';
import axios from 'axios'
import { Box, Flex, Stack, useBreakpointValue, VStack } from "@chakra-ui/react";
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import SettingsMenu from './settingsAtom/SettingsMenu';
import PostStateTabs from './settingsAtom/PostStateTabs';


const ContentsSettings = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false });
    const [draft_post, setDraft_post] = React.useState([]);
    const [public_post, setPublic_post] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        const load_draft = async () => {
            const temp_draft = []
            await axios.get('/api/list/draft-post/', { withCredentials: true })
            .then(res => {
                if (res.request.status === 200) {
                    for (let i=0;i<res.data.count;i++) {
                        let data = res['data'][i]
                        temp_draft.push({
                            postId: data.id,
                            title: data.title,
                            postDate: data.edit_time,
                        });
                    setDraft_post(temp_draft);
                    // console.log(draft_post);
                    }
                    // console.log(res.data);
                }
            });
        }
        const load_public = async () => {
            const temp_public = []
            await axios.get('/api/list/public-post/?user_id=' + localStorage.getItem("myuserid"), { withCredentials: true })
            .then(res => {
                if (res.request.status === 200) {
                    for (let i=0;i<res.data.count;i++) {
                        let data = res['data'][i]
                        temp_public.push({
                            postId: data.id,
                            title: data.title,
                            postDate: data.create_time,
                        });
                    setPublic_post(temp_public);
                    // console.log(temp_public);
                    }
                    // console.log(res.data);
                }
            })
            .finally(() => setLoading(false));
        }
        load_draft();
        load_public();
    }, [])

    return(
        <>
            <HeaderPlane plasement="top"/>
            <Flex justify="center" height="flex" size="full" mx='20px'>
                { isMobile ? "" :
                <Stack mr='100px'>
                    <Box h={16} />
                    <SettingsMenu mt={100} news={0} contents={1} account={0} dashboard={0} />
                </Stack>
                }
                <VStack mx={8} py={4} align='stretch' maxW={450}>
                    <PostStateTabs public_posts={public_post} draft_posts={draft_post} number={0} loading={loading}/>
                </VStack>
            </Flex>
        </>
    );
};

export default ContentsSettings;