import { AspectRatio, Box, Flex, GridItem, Image, Spacer, Text } from "@chakra-ui/react";
import React, { memo } from "react";
// import { FaRegHeart } from 'react-icons/fa';
import { Link as ReachLink } from 'react-router-dom';
import { ShowPriceS } from "../../../components/atoms/ShowPriceS";
import { ShowTimeForPostBox } from "../../../components/atoms/ShowTime";
import soldOutImage from "../../../images/soldout.png";

const MarketPostBox = memo((props) => {
    
    const { posts, color='#E67A5D' } = props;
    // console.log(posts)
    return (
        posts.map((post, key) => (
            <GridItem as={ReachLink} to={'/m/' + post.postId} key={key}>
                <Box w={{base: '335px', md: '300px', lg: '230px', xl: '250px'}} h={{base: '250px', md: '234px', lg: '182px', xl:'197px'}} overflowY='auto' p={0} _hover={{ cursor: "pointer", opacity: 0.8 }}>
                    <Box textAlign="center" position={"relative"}>
                        { !post.soldOut ?
                            <AspectRatio ratio={16 / 9}>
                                <Image
                                    borderRadius={4}
                                    src={post.imageUrl}
                                    alt=""
                                />
                            </AspectRatio>
                        :
                            <Box textAlign="center" /*position={"relative"}*/>
                                <AspectRatio ratio={16 / 9}>
                                    <Image
                                        borderRadius={4}
                                        src={post.imageUrl}
                                        filter='grayscale(100%)'
                                    />
                                </AspectRatio>
                                <Image
                                    src={soldOutImage}
                                    position={'absolute'}
                                    bottom={4}
                                />
                            </Box>
                        }
                        <Box bg={color} align="center" borderRadius={4} size='sm' boxShadow='dark-lg' position={"absolute"} bottom={3} right={3} >
                            { post.price===0 ? (
                                <Text p={1} color={'white'}>無料</Text>
                            ) : (
                                <ShowPriceS price={post.price}/>
                            )}
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Flex height={10}>
                                <Text fontSize="sm" fontWeight="bold" textAlign={"left"} mr={0.5} w={{base: '275px', md: '235px', lg: '165px', xl:'185px'}} noOfLines={2}>
                                    {post.title}
                                </Text>
                                <Spacer/>
                                <ShowTimeForPostBox date0 = {post.date} color={color}/>
                        </Flex>
                    </Box>
                </Box>
            </GridItem>
        ))
    );
});

export default MarketPostBox;