import { AspectRatio, Box, Center, GridItem, HStack, Icon, Image, Spacer, Spinner, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { memo, useEffect } from 'react';
import { FaRegHeart } from 'react-icons/fa';
import { ShowPriceS } from "../../components/atoms/ShowPriceS";
import { Link as ReachLink, useLocation } from 'react-router-dom';
import axios from 'axios'
import { ShowTime } from "../../components/atoms/ShowTime";

const UserHomePopular = memo(() => {
    let Posts = [];
    const [posts, setPosts] = React.useState([]);
    const location = useLocation();
    const isMobile = useBreakpointValue({ base: true, lg: false });
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        const load_post = async () => {
            const userid = location.pathname.split("/")[1];
            await axios.get('/api/list/popular/?user_id='+userid, { withCredentials: true })
                .then(res => {
                    if (res.request.status === 200) {
                        for (let i=0;i<res.data.count;i++) {
                            let data = res['data'][i]
                            Posts.push({
                                postId: data.id,
                                title: data.title,
                                date: data.create_time,
                                imageUrl: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + data.id + "." + (data.thumbnail_url ? data.thumbnail_url : "png"),
                                price: data.price,
                                likescount: data.favorite,
                            });
                            setPosts(Posts);
                        }       
                        }
                })
                .finally(() => setLoading(false));
            }
        load_post()
    }, [])

    if (loading) {
        return(
        <Center>
            <Spinner 
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='pink.500'
                size='xl'
            />
        </Center>
        )
    }else if (isMobile){
        return (
            posts.map((post, key) => (
                <HStack as={ReachLink} to={'/p/' + post.postId} key={key} pb={3}>
                    <Box position={"relative"} w={{base: '35vw', md: '30vw'}} mr={2} >
                        <AspectRatio ratio={16 / 9}>
                            <Image
                                borderRadius={2}
                                src={post.imageUrl}
                                alt=""
                            />
                        </AspectRatio>
                        <Box bg={"#CC5083"} borderRadius={2} size='xs' boxShadow='dark-lg' position={"absolute"} bottom={2} right={2} >
                                { post.price===0 ? (<Text p={1} color={'white'}>無料</Text>) : (<ShowPriceS price={post.price}/>) }
                        </Box>
                    </Box>
                    <Box w={{base: '40vw', md:'35vw'}}>
                        <Stack lineHeight='normal'>
                            <Box>
                                <Text fontSize="sm" fontWeight="bold" textAlign={"left"} noOfLines={2}>
                                    {post.title}
                                </Text>
                            </Box>
                            <HStack>
                                <Icon as={FaRegHeart} color={'#CC5083'} w={4} h={4} ml={1} />
                                <Text color={'#CC5083'} pr={1} >{post.likescount}</Text>
                                <ShowTime date0 = {post.date} />
                            </HStack>
                        </Stack>
                    </Box>
                </HStack>
            ))
        )
    }else{
        return(
            posts.map((post, key) => (
                <GridItem as={ReachLink} to={'/p/' + post.postId} key={key}>
                    <Box w={{base:'300px' , lg: '230px'}} h={{base:'309px' , lg: '236px'}} mx='1px' overflow='auto' _hover={{ cursor: "pointer", opacity: 0.8 }}>
                        <Box textAlign="center" position={"relative"}>
                            <AspectRatio ratio={16 / 9}>
                                <Image
                                    borderRadius={4}
                                    boxSize="100px"
                                    src={post.imageUrl}
                                    alt=""
                                />
                            </AspectRatio>
                            <Box bg={"#CC5083"} align="center" borderRadius={4} size='sm' boxShadow='dark-lg' position={"absolute"} bottom={3} right={3} >
                                    { post.price===0 ? (<Text p={1} color={'white'}>無料</Text>) : (<ShowPriceS price={post.price}/>) }
                            </Box>
                        </Box>
                        <Box>
                            <Stack height={12} lineHeight='normal'>
                                <Box height={10}>
                                    <Text fontSize="sm" fontWeight="bold" textAlign={"left"} mt={1} mr={0.5} w="95%" noOfLines={2}>
                                        {post.title}
                                    </Text>
                                </Box>
                                <HStack>
                                    <Icon as={FaRegHeart} color={'#CC5083'} w={4} h={4} ml={1} />
                                    <Text color={'#CC5083'}>{post.likescount}</Text>
                                    <Spacer />
                                    <ShowTime pr={4} date0 = {post.date} />
                                </HStack>
                            </Stack>
                        </Box>
                    </Box>
                </GridItem>
            ))
        )
    }
});

export default UserHomePopular;