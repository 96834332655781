//数字を3桁ごとにカンマで区切る。ShowPriceLのL＝大きな文字

import { Text } from '@chakra-ui/react';
import React from 'react';

export const ShowPriceL = (props) => {
    const {price} = props;
    const localeNum = price.toLocaleString();
    return(
        <Text align="right" fontSize="3xl" my={3} mr={6} >{localeNum}円</Text>
    )
}