import React, { memo, useEffect } from 'react';
import { Link as ReachLink } from 'react-router-dom';
import {AspectRatio, Box, Center, Flex, Grid, GridItem, Heading, HStack, Image, Spinner, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import axios from 'axios';
import { MarketHeader } from '../marketConponents/MarketHeader';
import MarketSettingsMenu from './MarketSettingsMenu';
import { HeadBlock } from '../../../components/organisms/layout/HeadBlock';
import soldOutImage from "../../../images/soldout.png";

const BookmarkBox = memo(() => {
    let Posts = [];
    const [loading, setLoading] = React.useState(false);
    const isMobile = useBreakpointValue({ base: true, lg: false });

    const [posts, setPosts] = React.useState({
        post_id: '',
        thumbnail_url: '',
        title: '',
        icon: '',
        userid: '',
        username: '',
        favorite: '',
        time: '',
        price: '',
        market_price: '',
        is_login: false,
        is_me: true,
        soldOut: false,
    });

    useEffect(() => {
        setLoading(true);
        const load_post = async () => {
            await axios.get('/api/list/market/favorite/', { withCredentials: true })
            .then(res => {
                if (res.request.status === 200) {
                    // console.log(res.data);
                    for (let i=0;i<res.data.count;i++) {
                        let data = res['data'][i]
                        // console.log(data);
                        Posts.push({
                            post_id: data.id,
                            title: data.title,
                            imageUrl: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + data.post_id + "." + (data.thumbnail_url ? data.thumbnail_url : "png"),
                            price: data.price,
                            icon: (data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + data.uuid + data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                            userName: data.username,
                            soldOut: data.sold,
                        });
                        setPosts(Posts);
                    };
                };
            })
            .finally(() => setLoading(false));
        }
        load_post()
    }, [])

    // console.log(posts)

    if (loading) {
        return(
        <Center>
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='#E67D5A'
                size='xl'
            />
        </Center>
        )
    } else if (isMobile) {
        return (
            Object.keys(posts).map((value) => {
                return(
                <HStack as={ReachLink} to={'/m/' + posts[value].post_id}  pb={3}>
                    <Box position={"relative"} w={{base: '40vw', md:'100px'}} mr={2} >
                        { !posts[value].soldOut ?
                            <AspectRatio ratio={16 / 9}>
                                <Image
                                    borderRadius={2}
                                    src={posts[value].imageUrl}
                                    alt=""
                                />
                            </AspectRatio>
                        :
                            <>
                                <AspectRatio ratio={16 / 9} >
                                    <Image
                                        borderRadius={2}
                                        src={posts[value].imageUrl}
                                        style={{ objectFit: 'cover', width: '100%' }}
                                        filter='grayscale(100%)'
                                    />
                                </AspectRatio>
                                <Image
                                    src={soldOutImage}
                                    position={'absolute'}
                                    bottom={2}
                                />
                            </>
                        }
                    </Box>
                    <Box w={{base: '46vw', md:'176px'}}>
                        <Stack lineHeight='normal'>
                            <Box>
                                <Text fontSize="md" fontWeight="bold" textAlign={"left"} noOfLines={2}>
                                    {posts[value].title}
                                </Text>
                            </Box>
                            <Text fontSize="sm" textAlign={"left"} noOfLines={2}>
                                {posts[value].userName}
                            </Text>
                            <HStack>
                                <Text color={'#E67D5A'}>¥{posts[value].price}</Text>
                            </HStack>
                        </Stack>
                    </Box>
                </HStack>
                )
            })
        )
    } else {
        return (
            Object.keys(posts).map((value) => {
                return(
                <GridItem as={ReachLink} to={'/m/' + posts[value].post_id}>
                    <Box position={"relative"} w={{base:'300px' , lg: '230px'}} h={{base:'320px' , lg: '250px'}} mx='1px' overflow='auto' _hover={{ cursor: "pointer", opacity: 0.8 }}>
                        { !posts[value].soldOut ?
                            <AspectRatio ratio={16 / 9}>
                                <Image
                                    borderRadius={4}
                                    boxSize="100px"
                                    src={posts[value].imageUrl}
                                    alt=""
                                />
                            </AspectRatio>
                        :
                            <>
                                <AspectRatio ratio={16 / 9} >
                                    <Image
                                        borderRadius={2}
                                        src={posts[value].imageUrl}
                                        style={{ objectFit: 'cover', width: '100%' }}
                                        filter='grayscale(100%)'
                                    />
                                </AspectRatio>
                                <Image
                                    src={soldOutImage}
                                    position={'absolute'}
                                    top={3}
                                />
                            </>
                        }
                        <Box>
                            <Stack lineHeight='normal'>
                                <Box>
                                    <Text fontSize="md" fontWeight="bold" textAlign={"left"} noOfLines={2}>
                                        {posts[value].title}
                                    </Text>
                                </Box>
                                <Text fontSize="sm" textAlign={"left"} noOfLines={2}>
                                    {posts[value].userName}
                                </Text>
                                <HStack>
                                    <Text color={'#E67D5A'}>¥{posts[value].price}</Text>
                                </HStack>
                            </Stack>
                        </Box>
                    </Box>
                </GridItem>
                )
            })
        );
    };
});

const Bookmark = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false });
    return(
        <>
            <HeadBlock title="ブックマーク"/>
            <MarketHeader />
            <Flex justify="center" height="flex" mx='100px'>
                { isMobile ? "" :
                <Stack mr='100px' >
                    <Box h={16} />
                    <MarketSettingsMenu mt={100} bookmark={1} />
                </Stack>
                }
                <VStack mx={8} py={4} w={450}>
                    <Heading as="h3" size="md" pt={{base: 4, lg: 7}} pb={{base: 3, lg: 6}}>
                        ブックマーク登録した出品
                    </Heading>
                    <Grid
                        justifyItems={{base: 'left', lg: 'center'}}
                        alignItems={'center'}
                        overflowX='auto'
                        flexWrap='nowrap'
                        templateColumns={{base: 'repeat(0, 1fr)', lg: 'repeat(2, 1fr)'}}
                        gap={3}
                    >
                        <BookmarkBox/>
                    </Grid>
                </VStack>
            </Flex>
        </>
    );
};
export default Bookmark;