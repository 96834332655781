import './highlight';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider} from 'react-helmet-async';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Footer from './components/organisms/layout/Footer';

const theme = extendTheme({
  fonts: {
    heading: `"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Meiryo", "MS Pgothic", knowledge-reg, helvetica,arial, sans-serif;`,
    body: `"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Meiryo", "MS Pgothic", knowledge-reg, helvetica,arial, sans-serif;`
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div position="relative">
      <ChakraProvider theme={theme}>
        <HelmetProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
            <Footer />
        </HelmetProvider>
      </ChakraProvider>
    
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
