// スマホ用のサイドバー。ほぼタブ
import { Box, css, HStack, Icon, Image, Text } from '@chakra-ui/react';
import React from 'react'
// import { SideBarData } from './SideBarData'
import { FaRegLightbulb } from "react-icons/fa";
import Symbol from "../../../images/nextswanSymbol.png";
import MarketSymbol from "../../../images/nextswanMarketSymbol.png";
import { Link as ReachLink } from 'react-router-dom';

export function MobileSideBar(props) {
    return(
        <Box pb={1} w='100vw'>
            <HStack
                overflowX='auto'
                flexWrap='nowrap'
                css={css({
                    scrollbarWidth: 'none',
                    '::-webkit-scrollbar': {display: 'none'},
                    '-webkit-overflow-scrolling': 'touch',
                    boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                    border: '0 none',
                    })}
                h='40px'
            >
                <HStack as={ReachLink} to="/" _focus={{ boxShadow: "none"}} w='100px' mx={2} pr={3} >
                    <Image src={Symbol} w='20px' display='grid' placeItems='center'/>
                    <Text fontSize='sm' mx={1} fontWeight='bold' color="gray.500" >Nextswan</Text>
                </HStack>
                <HStack as={ReachLink} to="/m" _focus={{ boxShadow: "none"}} w='160px' mr={5} pr={10}>
                    <Image src={MarketSymbol} w='20px' display='grid' placeItems='center'/>
                    <Text fontSize='sm' mx={1} whiteSpace='nowrap' fontWeight='bold' color="gray.500" >Nextswan リセール</Text>
                </HStack>
                <HStack as={ReachLink} to="/about" _focus={{ boxShadow: "none"}}>
                    <Icon as={FaRegLightbulb} h='20px' display='grid' placeItems='center'/>
                    <Text fontSize='sm' mx={1} whiteSpace='nowrap' fontWeight='bold' color="gray.500" >Nextswanとは？</Text>
                </HStack>
            </HStack>
        </Box>
    )
}