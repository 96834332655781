import React from 'react';
import { Box, Button, HStack, Spacer, Stack, Text, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, Link, Center, VStack, Heading } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { ShowDate } from '../../../components/atoms/ShowDate';
import axios from "axios"

Modal.setAppElement('#root');

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
};



export default function DraftBox(props) {
    const { title, postDate, postid } = props;
    const [ isModal, setIsModal ] = React.useState(false)
    const navigate = useNavigate();

    const draft_delete = async () => {
        const data = new FormData();
        data.append("post_id", postid);
        await axios.post('/api/draft_delete/', data, { withCredentials: true })
        .then(res => {
            window.location.reload();
        });
    }

    return(
        <Box pb="20px">
            <Modal style={customStyles} isOpen={isModal} onRequestClose={() => setIsModal(false)}>
                <Center>
                    <VStack>
                        <Heading size="md">本当にこの下書きを削除しますか？</Heading>
                        <Text fontSize="sm">※ 削除した下書きは二度と復元できません</Text>
                        <hr width="100%" />
                        <HStack spacing="16px">
                            <Link color='black' fontSize='sm' onClick={() => setIsModal(false)}>キャンセル</Link>
                            <Spacer />
                            <Link color='red.600' fontSize='sm' onClick={draft_delete}>削除する</Link>
                        </HStack>
                    </VStack>
                </Center>
            </Modal>
            <HStack>
                <Stack maxW='311px'>
                    <Text noOfLines={2} fontSize="sm" fontWeight="bold" textAlign={"left"}>{title}</Text>
                    <ShowDate time = {postDate} />
                </Stack>
                <Spacer />
                <Button minW='60px' maxW='60px' bg='white' color='black' borderColor='black' variant='outline' onClick={() => navigate('/edit/' + postid + '/')} _focus={{ boxShadow: "none"}}>
                    編集
                </Button>
                <Popover>
                    <PopoverTrigger>
                        <ChevronDownIcon color='gray' m={{ base: 1, md: 2 }} />
                    </PopoverTrigger>
                    <PopoverContent w='150px' _focus={{ boxShadow: "none"}}>
                        <PopoverArrow />
                        <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                        <PopoverBody>
                            <Link color='black' fontSize='sm' onClick={() => setIsModal(true)}>削除</Link>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </HStack>
        </Box>
    );
};