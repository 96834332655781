import React from 'react';
import { Box, Center, Flex, Spinner, Stack, useBreakpointValue, VStack } from "@chakra-ui/react";
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import SettingsMenu from './settingsAtom/SettingsMenu';
import SettingsTabs from './settingsAtom/SettingsTabs';
import TransferForm from './settingsAtom/TransferForm';
import axios from 'axios';

const TransferSettings = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false });
    const [loading, setLoading] = React.useState(false);

    const [account, setAccount] = React.useState({
        lastname: "",
        firstname: "",
        lastname_kana: "",
        firstname_kana: "",
        postalcode: "",
        address: "",
        bankcode: "",
        branchcode: "",
        account_number: "",
        account_kind: "",
        account_holder: "",
    });

    React.useEffect(() => {
        setLoading(true);
        const load_account = async () => {
            axios.get("/api/bank_account", { withCredentials: true })
            .then(res => {
                setAccount({
                    lastname: res.data.lastname,
                    firstname: res.data.firstname,
                    lastname_kana: res.data.lastname_kana,
                    firstname_kana: res.data.firstname_kana,
                    postalcode: res.data.postalcode,
                    address: res.data.address,
                    bankcode: res.data.bankcode,
                    branchcode: res.data.branchcode,
                    account_number: res.data.account_number,
                    account_kind: res.data.account_kind,
                    account_holder: res.data.account_holder,
                });
            })
            .finally(() => setLoading(false));
        }

        load_account();
    }, []);

    if (isMobile) {
        return(
            <>
                <HeaderPlane plasement="top"/>
                <Flex justify="center" height="flex" size="full" mx='20px'>
                    <VStack spacing={6} py={4} mx={8} align='stretch' w={450}>
                        <SettingsTabs number={2} />
                        {loading ? (
                            <Center h="100vh">
                                <Spinner 
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='pink.500'
                                    size='xl'
                                />
                            </Center>
                        ) : (
                            <TransferForm
                                lastNameC={account.lastname}
                                lastNameH={account.lastname_kana}
                                firstNameC={account.firstname}
                                firstNameH={account.firstname_kana}
                                postNum={account.postalcode}
                                address={account.address}
                                bankCode={account.bankcode}
                                branchNum={account.branchcode}
                                accountNum={account.account_number}
                                accountType={account.account_kind}
                                accountHolder={account.account_holder}
                            />
                        )}
                    </VStack>
                </Flex>
            </>)
        }
    return(
        <>
            <HeaderPlane />
            <Flex justify="center" height="flex" mx='100px'>
                <Stack mr='100px'>
                    <Box h={16} />
                    <SettingsMenu mt={100} news={0} contents={0} account={1} dashboard={0}/>
                </Stack>
                <VStack spacing={6} py={4} mx={8} align='stretch' w={450}>
                    <SettingsTabs number={2} />
                    {loading ? (
                        <Center h="100vh">
                            <Spinner 
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='pink.500'
                                size='xl'
                            />
                        </Center>
                    ) : (
                        <TransferForm
                            lastNameC={account.lastname}
                            lastNameH={account.lastname_kana}
                            firstNameC={account.firstname}
                            firstNameH={account.firstname_kana}
                            postNum={account.postalcode}
                            address={account.address}
                            bankCode={account.bankcode}
                            branchNum={account.branchcode}
                            accountNum={account.account_number}
                            accountType={account.account_kind}
                            accountHolder={account.account_holder}
                        />
                    )}
                </VStack>
            </Flex>
        </>
    );
};

export default TransferSettings;