import { Box, Flex, Grid, GridItem, Heading, Image, Stack, Text, useBreakpointValue, VStack} from '@chakra-ui/react';
import React from 'react';
import { MobileSideBar } from '../../components/atoms/sideBar/MobileSideBar';
import { SideBar } from '../../components/atoms/sideBar/SideBar';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';

import Rule1 from '../../images/rule1.png';
import Rule2 from '../../images/rule2.png';
import Step1 from '../../images/nextswan_step_1.jpg';
import Step2 from '../../images/nextswan_step_7.jpg';
import Step3 from '../../images/nextswan_step_3.jpg';
import Step4 from '../../images/nextswan_step_10.jpg';
import Step5 from '../../images/nextswan_step_5.jpg';
import Step6 from '../../images/nextswan_step_6.jpg';
import { Feature } from './Feature';

const AboutPoem = (props) => {
    const { titleSize='xl', titleAlign='left', titleW='auto', fontSize='lg', compact=false } = props;
    const isMobile = useBreakpointValue({ base: true, lg: false });
    return(
        <div>
            <VStack mt={10} py={5}>
                <Heading as="h2" size={titleSize} w={titleW} textAlign={titleAlign} style={{fontFamily: 'serif'}}>
                    ユーザーが最も安心して購入できる
                </Heading>
                <Heading as="h2" size={titleSize} w={titleW} textAlign={titleAlign} style={{fontFamily: 'serif'}}>
                    リセーラブルコンテンツ投稿プラットフォーム
                </Heading>
            </VStack>
            {/* <Stack>
                { isMobile || compact ? "" : <br/> }
                { compact ? <Box h={3} /> : <br/> }
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>インターネットが普及して30年。</Text>
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>情報が溢れかえり、どれが質の高い情報か質の低い情報か一目で分かりません。</Text>
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>価値あるコンテンツが埋もれてしまい、見合う対価を得られていないこともしばしばです。</Text>
                <br />
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>Nextswanは「良いコンテンツを正しく評価する」場所となります。</Text>
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>そのためにはコンテンツを生み出すクリエイターとそれを評価するユーザーがいなければ成り立ちません。</Text>
                <br />
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>だから、Nextswanはクリエイターとユーザーの双方に利益を還元します。</Text>
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>ユーザーに高く評価されるコンテンツを生み出したクリエイター。</Text>
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>価値あるコンテンツを早く見つけて評価したユーザー。</Text>
                <br />
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>サービスを支える全ての人に利益が還元され、良いコンテンツが流通し続ける世界をNextswanは実現します。</Text>
            </Stack> */}
        </div>
    )
};

const AboutSignup = (props) => {
    const { titleSize='xl', titleAlign='left', titleW='auto', fontSize='lg', compact=false } = props;
    const isMobile = useBreakpointValue({ base: true, lg: false });
    return(
        <div>
            <VStack mt={10} py={5}>
                <Heading as="h2" size={titleSize} w={titleW} textAlign={titleAlign} style={{fontFamily: 'serif'}}>
                    ユーザーが最も安心して購入できる
                </Heading>
                <Heading as="h2" size={titleSize} w={titleW} textAlign={titleAlign} style={{fontFamily: 'serif'}}>
                    リセーラブルコンテンツ投稿プラットフォーム
                </Heading>
            </VStack>
            <Stack>
                { isMobile || compact ? "" : <br/> }
                { compact ? <Box h={3} /> : <br/> }
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>インターネットが普及して30年。</Text>
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>情報が溢れかえり、どれが質の高い情報か質の低い情報か一目で分かりません。</Text>
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>価値あるコンテンツが埋もれてしまい、見合う対価を得られていないこともしばしばです。</Text>
                <br />
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>Nextswanは「良いコンテンツを正しく評価する」場所となります。</Text>
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>そのためにはコンテンツを生み出すクリエイターとそれを評価するユーザーがいなければ成り立ちません。</Text>
                <br />
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>だから、Nextswanはクリエイターとユーザーの双方に利益を還元します。</Text>
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>ユーザーに高く評価されるコンテンツを生み出したクリエイター。</Text>
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>価値あるコンテンツを早く見つけて評価したユーザー。</Text>
                <br />
                <Text fontSize={fontSize} textAlign="left" style={{fontFamily: 'serif'}}>サービスを支える全ての人に利益が還元され、良いコンテンツが流通し続ける世界をNextswanは実現します。</Text>
            </Stack>
        </div>
    )
};

const AboutPoint = (props) => {
    const { color='pink.300' } = props;
    return(
        <Box mx={{base: '30px', md: '100px'}} pt='30px'>
            <Heading as="mark" bgColor={color} size="lg" py={1}>
                ポイント
            </Heading>
            <Grid justifyItems={'center'} alignItems={'center'} templateColumns={{base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)'}} gap='auto'>
                <GridItem height='460px'>
                    <VStack padding={{base: 0, md: "10px"}} textAlign="center">
                        <Image src={Rule1} width={{base: '320px', md: "400px"}} height="300px"/>
                        <Feature
                            width={{base: '320px', md: "400px"}}
                            number="01"
                            title="良いコンテンツは価格が上がる"
                            line1="いいねや購入、シェアなど読み手の評価に応じて、コンテンツの価格がリアルタイムに変わります。"
                        />
                    </VStack>
                </GridItem>
                <GridItem height='460px'>
                    <VStack padding={{base: 0, md: "10px"}} textAlign="center">
                        <Box position={"relative"}>
                            <Image src={Rule2} width={{base: '320px', md: "450px"}} height="300px"/>
                        </Box>
                        <Feature
                            width={{base: '320px', md: "400px"}}
                            number="02"
                            title="購入したコンテンツはあとで売れる"
                            line1="クリエイターがリセールを許可したコンテンツは、購入したあとに「"
                            line2="Nextswan リセール"
                            line3="」で出品し、他のユーザーに販売できます。その際、販売額の55%がクリエイターにロイヤリティーとして支払われます。"
                        />
                    </VStack>
                </GridItem>
            </Grid>
        </Box>
    )
}

const AboutStep = (props) => {
    const { color='pink.300' } = props;
    return(
        <Box mx={{base: '30px', md: '100px'}} pt='30px'>
            <Heading as="mark" bgColor={color} size="lg" py={1}>
                ステップ
            </Heading>
            <Heading as="h3" size="md" pt='30px' >
                for Creator(クリエイター向け)
            </Heading>
            <br />
            <Grid justifyItems={'center'} alignItems={'center'} templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)'}} gap={8}>
                <GridItem height='330px' width='250px'>
                    <VStack padding={{base: 0, md: "10px"}} textAlign="center">
                        <Image src={Step1} width="200px" height="150px"/>
                        <Feature
                            number="1."
                            title="執筆"
                            line1="文章コンテンツを執筆しましょう。あなたが書きたいこと、伝えたいことを自由に表現して、届けることができます。"
                            />
                    </VStack>
                </GridItem>
                <GridItem height='330px' width='250px'>
                    <VStack padding={{base: 0, md: "10px"}} textAlign="center">
                        <Image src={Step2} width="200px" height="150px"/>
                        <Feature
                            number="2."
                            title="詳細設定"
                            line1="執筆が完了したら、最大販売部数、スタート価格、リセールの許可、リセールの解禁日を設定しましょう。"
                            />
                    </VStack>
                </GridItem>
                <GridItem height='330px' width='250px'>
                    <VStack padding={{base: 0, md: "10px"}} textAlign="center">
                        <Image src={Step3} width="200px" height="150px"/>
                        <Feature
                            number="3."
                            title="公開"
                            line1="コンテンツが公開されると、読み手の評価に応じて自動で価格が変わります。"
                            />
                    </VStack>
                </GridItem>
            </Grid>
            <br />
            <Heading as="h3" size="md" pt='30px'>
                for User(ユーザー向け)
            </Heading>
            <br />
            <Grid justifyItems={'center'} alignItems={'center'} templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)'}} gap='auto'>
                <GridItem height='330px' width='250px'>
                    <VStack padding={{base: 0, md: "10px"}} textAlign="center">
                        <Image src={Step4} width="200px" height="150px"/>
                        <Feature
                            number="1."
                            title="購入"
                            line1="コンテンツを購入しましょう。まだ評価が少ないコンテンツは安く、すでに評価の高いコンテンツは安心して購入できます。"
                        />
                    </VStack>
                </GridItem>
                <GridItem height='330px' width='250px'>
                    <VStack padding={{base: 0, md: "10px"}} textAlign="center">
                        <Image src={Step5} width="200px" height="150px"/>
                        <Feature
                            number="2."
                            title="出品"
                            line1="リセールが許可されているコンテンツは、読んだあとに"
                            line2="Nextswan リセール"
                            line3="で出品できます。購入時より高い金額で取引できるかもしれません。"
                        />
                    </VStack>
                </GridItem>
                <GridItem height='330px' width='250px'>
                    <VStack padding={{base: 0, md: "10px"}} textAlign="center">
                        <Image src={Step6} width="200px" height="150px"/>
                        <Feature
                            number="3."
                            title="売買"
                            line2="Nextswan リセール"
                            line3="にて、他のユーザーが出品しているコンテンツを購入できます。購入したコンテンツは、クリエイターがリセールを許可している限り、再び"
                            line4="Nextswan リセール"
                            line5="に出品できます。"
                        />
                    </VStack>
                </GridItem>
            </Grid>
        </Box>
    )
}

const About = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false });
    const titleSize = useBreakpointValue({ base: 'lg', lg: 'xl' });
    return(
        <>
            <HeadBlock title="Nextswanとは？" />
            <HeaderPlane />
            <Flex>
                { isMobile ? "" : <SideBar mt={0} index="2" flex='24%' /> }
                <Box mx={0} flex='76%'>
                    { isMobile ? <MobileSideBar number={2} color='pink'/> : ""}
                    <Box>
                        <VStack mx={{base: '30px', md: '30px', lg: '100px'}}>
                            <AboutPoem titleSize={titleSize}/>
                        </VStack>
                        <br />
                        <br />
                        <AboutPoint/>
                        <AboutStep/>
                    </Box>
                </Box>
            </Flex>
        </>
    );
}

export { About, AboutPoem, AboutSignup, AboutPoint, AboutStep };