import React from 'react';
import { Button, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { FaCaretRight } from 'react-icons/fa';
import { Link as ReachLink } from 'react-router-dom';



export default function SettingsMenu(props) {
    const { news=0, purchased=0, history=0, contents=0, account=0, dashboard=0 } = props;

    return(
            <VStack alignItems='center' >
                <Text align='center' fontSize='xl' fontWeight='semibold' mb={4}>設定</Text>
                <HStack>
                    {news ? (<Icon as={FaCaretRight} />) : (<></>) }
                    <Link as={ReachLink} to="/news">
                        <Button w="200px" bgColor='white' variant='ghost' _focus={{ boxShadow: "none"}}>
                            お知らせ
                        </Button>
                    </Link>
                </HStack>
                <HStack>
                    {purchased ? (<Icon as={FaCaretRight} />) : (<></>) }
                    <Link as={ReachLink} to="/p/purchased">
                        <Button w="200px" bgColor='white' variant='ghost' _focus={{ boxShadow: "none"}}>
                            購入したコンテンツ
                        </Button>
                    </Link>
                </HStack>
                <HStack>
                    {history ? (<Icon as={FaCaretRight} />) : (<></>) }
                    <Link as={ReachLink} to="/settings/history">
                        <Button w="200px" bgColor='white' variant='ghost' _focus={{ boxShadow: "none"}}>
                            購入履歴
                        </Button>
                    </Link>
                </HStack>
                <HStack>
                    {contents ? (<Icon as={FaCaretRight} />) : (<></>) }
                    <Link as={ReachLink} to="/settings/contents">
                        <Button w="200px" bgColor='white' variant='ghost' _focus={{ boxShadow: "none"}}>
                            コンテンツの管理・編集
                        </Button>
                    </Link>
                </HStack>
                <HStack>
                    {account ? (<Icon as={FaCaretRight} />) : (<></>) }
                    <Link as={ReachLink} to="/settings/account">
                        <Button w="200px" bgColor='white' variant='ghost' _focus={{ boxShadow: "none"}}>
                            アカウント設定
                        </Button>
                    </Link>
                </HStack>
                <HStack>
                    {dashboard ? (<Icon as={FaCaretRight} />) : (<></>) }
                    <Link as={ReachLink} to="/settings/dashboard">
                        <Button w="200px" bgColor='white' variant='ghost' _focus={{ boxShadow: "none"}}>
                            売上管理
                        </Button>
                    </Link>
                </HStack>
            </VStack>
    );
};