import React from 'react';
import { Link as ReachLink } from 'react-router-dom';
import { Box, Heading, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';

const Commerce = () => {
    return (
        <>
            <HeadBlock title="特商法表記" />
            <HeaderPlane/>
            <Box mx={{base:'20px', md: '100px', lg:'200px', xl: '300px'}} >
                <Heading size='xl' mt={8} pb={4} >
                    特定商取引法の表示について
                </Heading>
                <Heading size='sm' mt={8} pb={4} >
                    事業者名
                </Heading>
                <Text>
                    Meltly株式会社
                </Text>
                <Heading size='sm' mt={8} pb={4} >
                    住所
                </Heading>
                <Text>
                    東京都品川区豊町4-19-21
                </Text>
                <Heading size='sm' mt={8} pb={4} >
                    連絡先
                </Heading>
                <Text>
                    support[at]meltly.co.jp　([at]を@に変えてから送信してください)
                </Text>
                <Text>
                    080-2910-8364
                </Text>
                <Text>
                    (なお、電話でのお問い合わせは受け付けておりません。メールにてお問い合わせください。)
                </Text>
                <Heading size='sm' mt={8} pb={4} >
                    販売価格と手数料
                </Heading>
                <Text>
                    デジタルコンテンツの価格は、Nextswanの独自の仕組みで決定された、各コンテンツの決済ページに表示している金額です。また、販売者に対して以下の通りの手数料が課されます。
                </Text>
                <UnorderedList pt={2} pb={2}>
                    <ListItem>
                        決済手数料: 有料コンテンツの販売対価の3.6%
                    </ListItem>
                    <ListItem>
                        プラットフォーム利用料: 6.4%
                    </ListItem>
                </UnorderedList>
                <Text>
                    サービス中で販売されるコンテンツの販売価格と手数料の詳細は、<Link as={ReachLink} to='/term' color='teal.400' _focus={{ boxShadow: "none"}}>利用規約</Link>や<Link target="_blank" rel="noreferrer" href='https://nextswan.notion.site/nextswan/Nextswan-ad74aee98ea04489b99bd72ce7e631fa' color='teal.400' _focus={{ boxShadow: "none"}}>ヘルプセンター</Link>をご参照ください。
                </Text>
                <Heading size='sm' mt={8} pb={4} >
                    サービスの対価以外でお客様に発生する金銭
                </Heading>
                <Text>
                    ご利用の際に必要となる通信料はお客様のご負担となります。
                </Text>
                <Heading size='sm' mt={8} pb={4} >
                    支払い時期・支払い方法
                </Heading>
                <Text>
                    お支払い方法はサイト内に表示する方法となります。ご利用いただけるクレジットカードは、クレジットカード登録画面において指定するクレジットカードとなります。
                </Text>
                <Text>
                    クレジットカードをご利用の場合、ご利用料金は、契約締結時にお客様がご利用のクレジットカード会社宛に請求いたします。なお、お客様のお支払いの時期は、お客様とクレジットカード会社との会員規約に基づきます。
                </Text>
                <Heading size='sm' mt={8} pb={4} >
                    提供時期
                </Heading>
                <Text>
                    サイト内に表示する方法によるお支払の手続きが完了し次第、すぐにご利用いただけます。
                </Text>
                <Heading size='sm' mt={8} pb={4} >
                    返品・キャンセルについて
                </Heading>
                <Text>
                    デジタルコンテンツの性質上、ご購入者様の都合による返品・返金・キャンセルは一切できません。商品違いであっても返金は致し兼ねますので、予めご了承ください。
                </Text>
                <Heading size='sm' mt={8} pb={4} >
                    コンテンツの閲覧保証ブラウザ
                </Heading>
                <Text>
                    Chrome/Safari/FireFox/Edge/iOS Safari/Android Chromeの各最新版
                </Text>
                <Heading size='sm' mt={8} pb={4} >
                    個人情報の取扱いについて
                </Heading>
                <Text>
                    <Link as={ReachLink} to='/policy' color='teal.400' _focus={{ boxShadow: "none"}}>プライバシーポリシー</Link>をご参照ください。
                </Text>
                <Heading size='sm' mt={8} pb={4} >
                    その他
                </Heading>
                <Text>
                    コンテンツの販売者が販売事業者に該当する場合、販売者の特定商取引法に基づく表記はコンテンツの販売ページに表示されます。
                </Text>
            </Box>
        </>
    )
}

export default Commerce;