import React from 'react';
import { Box, Heading, Link, ListItem, OrderedList, Text } from '@chakra-ui/react';
import { Link as ReachLink } from 'react-router-dom';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';

const Terms = () => {
    return (
        <>
            <HeadBlock title="利用規約" />
            <HeaderPlane/>
            <Box mx={{base:'20px', md: '100px', lg:'200px', xl: '300px'}} >
                <Heading align='center' size='xl' mt={8} pb={4} >
                    Nextswan利用規約
                </Heading>
                <Heading size='md' align='center' mt={8} pb={4} >
                    第1章　通則
                </Heading>
                <Heading size='md' mt={8} pb={4} >
                    第1条（目的）
                </Heading>
                <OrderedList>
                    <ListItem>この利用規約（以下「本規約」といいます。）は、Meltly株式会社（以下「当社」といいます。）が運営する「Nextswan」（以下「本サービス」といいます。）を利用するユーザー（以下「ユーザー」といいます。）に適用されます。ユーザーは、本規約及び<Link as={ReachLink} to='/policy' color='teal.400' >プライバシーポリシー</Link>に同意の上、本サービスを利用します。</ListItem>
                    <ListItem>本規約は、本サービスの利用条件を定めています。本サービスにユーザー登録したユーザーは全て本規約に従い、年齢や利用環境等の条件に応じて、本規約の定める条件に従って本サービスを利用します。</ListItem> 
                    <ListItem>ユーザーが本規約及びプライバシーポリシーに同意することにより、当社との間に本契約（第2条で定義します。）が成立します。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第2条（定義）
                </Heading>
                <OrderedList>
                    <Text>本規約において使用する以下の用語は、以下の各号に定める意味を有します。</Text>
                    <Text>(1)「本契約」：本規約を契約条件として当社及びユーザーとの間で締結される、本サービスの利用契約を指します。</Text>
                    <Text>(2)「ユーザー」：本サービスのユーザー登録をしている全ての方を指します。</Text>
                    <Text>(3)「ユーザー情報」：本サービスに登録したユーザーのID及びパスワードを指します。</Text>
                    <Text>(4)「通信機器」： スマートフォン、タブレット端末及びコンピューター機器を指します。</Text>
                    <Text>(5)「クリエイター」：自ら制作したデジタルコンテンツを本サービス上で公開するユーザーを指します。</Text>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第3条（ユーザー登録）
                </Heading>
                <OrderedList>
                    <ListItem>本サービスのユーザーになろうとする方は、本規約及びプライバシーポリシーの内容に同意の上、当社が定める手続きによりユーザー登録を行います。</ListItem>
                    <ListItem>ユーザーは、前項に基づき登録した情報に変更が発生した場合、直ちに、登録情報の変更手続を行う義務を負います。</ListItem>
                    <ListItem>当社は、当社の裁量により、ユーザー登録を拒否する場合があります。</ListItem>
                    <ListItem>ユーザーは、本サービス上のアカウントを第三者に対して利用、貸与、譲渡、売買又は質入等をすることはできません。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第4条（本サービスの内容）
                </Heading>
                    <Text>本サービスは、ユーザーに対してデジタルコンテンツを公開し、これを利用する権利を購入し、または当該権利を再販するためのプラットフォームを提供するサービスです。</Text>
                <Heading size='md' mt={8} pb={4} >
                    第5条（利用料金）
                </Heading>
                <OrderedList>
                    <ListItem>本サービスの利用料金は無料です。</ListItem>
                    <ListItem>ユーザーが本サービスを通じてデジタルコンテンツを販売する場合で、当該デジタルコンテンツが本サービスを通じて実際に売買された時、当該ユーザーは、当社に対し、販売手数料及び決済手数料として、以下各号に相当する額を、当社が当該ユーザーのために代理受領した当該デジタルコンテンツの代金から控除される方法で、当社に支払うものとします。</ListItem>
                    <Text>(1)当該デジタルコンテンツのクリエイターがデジタルコンテンツを販売するとき</Text>
                    <Text>　販売手数料：販売価格の6.4%</Text>
                    <Text>　決済手数料：販売価格の3.6%</Text>
                    <Text>(2)当該デジタルコンテンツのクリエイター以外がデジタルコンテンツを転売するとき</Text>
                    <Text>　販売手数料：販売価格の6.4%</Text>
                    <Text>　決済手数料：販売価格の3.6%</Text>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第6条（ユーザー情報及び通信機器に関する管理）
                </Heading>
                <OrderedList>
                    <ListItem>ユーザーは、本サービスの提供を受けるために必要な機器、通信手段及び交通手段等の環境を全て自らの費用と責任で備えます。また、本サービスの利用にあたり必要となる通信費用は、全てユーザーの負担とします。</ListItem>
                    <ListItem>ユーザーは、ユーザー情報及び通信機器の管理責任を負います。ユーザー情報及び通信機器の管理不十分、使用上の過誤、第三者の使用等による損害の責任はユーザーが負い、当社は当社に故意又は過失のない限り一切の責任を負いません。</ListItem>
                    <ListItem>ユーザーは、ユーザー情報又は通信機器を第三者に使用されるおそれのある場合は、直ちに当社にその旨を連絡するとともに、当社の指示がある場合はこれに従います。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第7条（本サービスの提供条件）
                </Heading>
                    <Text>当社は、メンテナンス等のために、ユーザーに通知することなく、本サービスを停止又は変更することがあります。</Text>
                <Heading size='md' align='center' mt={8} pb={4} >
                    第2章　取引契約
                </Heading>
                <Heading size='md' mt={8} pb={4} >
                    第8条（取引契約の定義）
                </Heading>
                <OrderedList>
                    <ListItem>クリエイターは、クリエイターが本サービスを通じて公開したデジタルコンテンツについて、次条に定める利用権を他のユーザーに許諾する旨の契約を、本サービスを通じて締結することができます。</ListItem>
                    <ListItem>前項の契約に基づき、クリエイターから特定のデジタルコンテンツについて、次条に定める利用権の許諾を受けたユーザーは、本サービスを通じて、他のユーザーとの間で、当該権利を譲渡する契約を締結することができます。</ListItem>
                    <ListItem>前項の規定は、他のユーザーから特定のデジタルコンテンツについて、次条に定める利用権の譲渡を受けたユーザーが、更に他のユーザーにこれを譲渡する場合に準用します。</ListItem>
                    <ListItem>前3項の契約を総称して「取引契約」といいます。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第9条（取引契約の目的となる利用権）
                </Heading>
                <OrderedList>
                    <ListItem>前条に定める取引契約の目的となる利用権は、権利者が、本サービス上で、個人の非営利の使用のために、当該デジタルコンテンツを回数の制限なく表示し閲覧する非独占的な利用権とします。</ListItem>
                    <ListItem>クリエイターは、前条に定める取引契約の目的となるデジタルコンテンツについて、その内容を改変することができるものとし、取引契約の当事者となるユーザーはこれについてあらかじめ同意するものとします。</ListItem>
                    <ListItem>当社及びクリエイターは、その裁量において、取引契約の目的となったデジタルコンテンツの配信を停止することができるものとし、取引契約の当事者となるユーザーはこれについてあらかじめ同意するものとします。</ListItem>
                    <ListItem>前条に定める取引契約の目的となる利用権の個数についてはクリエイターがあらかじめ設定するものとし、公開後はその個数を変更することはできません。</ListItem>
                    
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第10条（取引契約の目的となるデジタルコンテンツ）
                </Heading>
                <OrderedList>
                    <ListItem>本サービスを通じてクリエイターが公開し、ユーザーがその利用権を取引契約の目的とすることができるデジタルコンテンツは、当社が指定するデータ形式またはファイル形式によるものに限られます。</ListItem>
                    <ListItem>以下各号のいずれかに該当するデジタルコンテンツは、その利用権を取引契約の目的とすることはできません。</ListItem>
                    <Text>(1)盗作、剽窃など、他者の著作権等を侵害しているもの。</Text>
                    <Text>(2)上記のほか、他者の財産権、商標権等の知的財産権、肖像権、名誉・プライバシー等を侵害するもの。</Text>
                    <Text>(3)詐欺やそのおそれがあるもの。</Text>
                    <Text>(4)過度に性的であると判断されうる音声、画像、動画。</Text>
                    <Text>(5)わいせつ的、暴力的な表現行為、その他過度の不快感を及ぼすおそれのあるもの。</Text>
                    <Text>(6)差別につながる民族・宗教・人種・性別・年齢等に関するもの。</Text>
                    <Text>(7)自殺、集団自殺、自傷、違法薬物使用、脱法薬物使用等を勧誘・誘発・助長するおそれのあるもの。</Text>
                    <Text>(8)マルチ商法等、当社がユーザーに不利益をもたらすと判断する情報商材。または、その宣伝に直接若しくは間接的に利用するもの。</Text>
                    <Text>(9)株式の銘柄推奨、その他金融商品取引法に抵触するもの。</Text>
                    <Text>(10)「必ずもうかる」等、ユーザーに著しい誤解を招く表現を用いたもの。</Text>
                    <Text>(11)コンピューターウィルスその他有害なコンピューター・プログラムを含むもの。</Text>
                    <Text>(12)オンラインゲーム等のアカウント、キャラクター、アイテム、通貨及び仮想通貨などを譲渡しようとするもの。</Text>
                    <Text>(13)不当景品類及び不当表示防止法、医薬品、医療機器等の品質、有効性及び安全性の確保等に関する法律、並びに医療法その他の広告に関する法令に違反するもの、またはそのおそれのあるもの。</Text>
                    <Text>(14)特定の個人、特定のグループまたは組織になりすますもの。</Text>
                    <Text>(15)未成年者を犯罪行為またはそのおそれのある行為に勧誘するもの。</Text>
                    <Text>(16)法令に違反するもの。</Text>
                    <Text>(17)公序良俗に反するもの。</Text>
                    <Text>(18)前各号の内容が掲載されているサイトへのリンクがあるもの。</Text>
                    <Text>(19)その他、前各号に準ずる不適切なもの。</Text>
                    <ListItem>当社は、本サービス内でのインデキシングの最適化を目的として、クリエイターがアップロードしたデジタルコンテンツの一部（原則としてタイトルおよびトップページ）を、本サービスが設定する表示アルゴリズムに基づいて変更、切除その他の改変をすることがあります。なお、表示アルゴリズムは、当社の判断に基づき変更することがあります。</ListItem>
                    <ListItem>当社は、ユーザーが本サービスを利用する際の利便性を高めるために、オートリンク機能、校正機能などを当社の裁量で適宜提供するものとし、これらの機能がデジタルコンテンツに適用されることについてクリエイターはあらかじめ同意するものとします。</ListItem>
                    <ListItem>当社は、クリエイターがアップロードしたデジタルコンテンツが18歳以上向けの表現を含むと判断したときは、18歳以上向けのデジタルコンテンツである旨の表示をし、18歳未満のユーザーの閲覧を制限することができます。当社は、かかる表示及び制限によりユーザーに⽣じた⼀切の損害について、損害賠償責任その他の責任を負わないものとします。</ListItem>
                    <ListItem>当社は、ユーザーに安全に利用いただくために、本利用規約の遵守状況を確認する目的で、必要かつ法令に反しない限度において、クリエイターがアップロードしたデジタルコンテンツの内容（パスワード等の識別符号によりアクセスが制限されているリンク先の外部サイトや添付ファイルを含みます。）を確認することができるものとし、クリエイターはこれを異議なく承諾するものとします。ただし、当社はそのような確認を行う義務を負うものではありません。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第11条（未成年者）
                </Heading>
                <OrderedList>
                    <ListItem>18歳未満のユーザーは、同意権者の同意がなければ取引契約の主体となることはできません。</ListItem>
                    <ListItem>18歳未満のユーザーまたは18歳未満である可能性があると当社が判断したユーザーが取引契約の主体となろうとする場合、当社は、当該ユーザーまたはその同意権者に対し、確認の連絡その他当社所定の確認手続をとる場合があります。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第12条（販売価格）
                </Heading>
                <OrderedList>
                    <ListItem>取引契約における販売価格は、当社が本サービスに適用するアルゴリズムによって決定されます。また、その価格は当該アルゴリズムに従い常に変動します。</ListItem>
                    <ListItem>ユーザーは、取引契約にかかる価格が前項のとおり当社によって決定されることについて、あらかじめ同意するものとします。</ListItem>
                    <ListItem>当社は、当該アルゴリズムに関してその内容を開示する義務を負いません。</ListItem>
                    <ListItem>当社は、当該アルゴリズムの内容を当社の判断によって予告なく変更することができ、かつ、その事実をユーザーに通知する義務を負いません。</ListItem>
                    <ListItem>当該取引契約の目的となっているデジタルコンテンツのクリエイター以外のユーザー間で取引契約が行われるとき、当該クリエイターは、当該取引契約の売主であるユーザーから、当該取引契約における販売価格の55%に相当する額（以下、「リセール・ロイヤルティ」といいます。）の支払を、当社が売主であるユーザーのために代理受領した当該デジタルコンテンツの代金から清算を受ける方法で受けることができます。</ListItem>
                    <ListItem>クリエイターが本サービスを退会した場合、その時点をもって、前項のリセール・ロイヤルティの支払を受ける権利を将来的に喪失します。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第13条（代理受領）
                </Heading>
                <OrderedList>
                    <ListItem>ユーザーは、当社に対し、取引契約に際してユーザー間で支払われる対価（リセール・ロイヤルティを含みます。）を自身に代わって受領するための代理権限を付与するものとします。</ListItem>
                    <ListItem>当社は、前項に基づき受領した対価から、当該ユーザーが当社に対して支払うべき販売手数料その他当該ユーザーが当社に対して負担する債務の一切及び当該ユーザーが当該取引契約の目的となっているデジタルコンテンツのクリエイターに対して支払うべきリセール・ロイヤルティ額を控除します（以下、控除後の額を「支払金額」といいます。）。</ListItem>
                    <ListItem>ユーザーは、当月20日までに当社所定の申請手続を行うことにより、前月末日時点での支払金額（以下、「受取可能金額」といいます。）の全額を、当月末に受け取ることができます。ただし、その額が1,000円未満である場合を除きます。</ListItem>
                    <ListItem>前項の申請は、受取可能金額の一部についてのみ行うことはできません。</ListItem>
                    <ListItem>受取可能金額の受け取り期限は受取可能金額が確定した時点から180日以内とします。受取可能金額が確定した時点から180日を経過したにもかかわらず、クリエイターが当社所定の申請手続を行わず、かつ、受取可能金額が1,000円以上である場合、当社はユーザーが登録した銀行口座に、受取可能金額全額を振込送金します。なお、前段に定める場合で、受取可能金額が1,000円未満である場合その他当社が適当と判断した場合は、当社の裁量により、当社は、ユーザーが登録したメールアドレスに、受取可能金額相当額のAmazonギフト券を送付することで、当該ユーザーに対して負う受取可能金額の返還債務を免れることができます。</ListItem>
                    <ListItem>退会その他の理由によりユーザーが本サービスの利用者としての地位を失った場合には、ユーザーは、その時点において未払いの支払金額を受け取る権利を喪失するものとします。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第14条（プロモーション）
                </Heading>
                <OrderedList>
                    <ListItem>当社はクリエイターのデジタルコンテンツのより一層の普及のために、当社の裁量によりデジタルコンテンツの販売価格を引き下げてプロモーションをすることがあります。その場合、当社は売主であるユーザーに対し、本来の販売価格と値下げ後の販売価格との差額を補償し支払います。なお、この場合における利用料金は、本来の販売価格を基礎に算出されます。</ListItem>
                    <ListItem>当社は、クリエイターが本サービスに公開したデジタルコンテンツをより多くの方々に楽しんでいただくために、他メディア及びSNSへの転載などの手段を通じて紹介させていただくことがあります。なお、デジタルコンテンツについては、コンテンツの中の無料公開部分のみキャプチャー画像をご紹介させていただきます。</ListItem>
                    <ListItem>当社は、デジタルコンテンツ、ユーザー情報、行動履歴等を統計的処理や機械学習などの手法で解析し、リコメンド等のプロモーションに利用することがあります。</ListItem>
                </OrderedList>
                <Heading size='md' align='center' mt={8} pb={4} >
                    第3章　一般条項
                </Heading>
                <Heading size='md' mt={8} pb={4} >
                    第15条（知的財産権等）
                </Heading>
                <OrderedList>
                    <ListItem>ユーザーが本サービス上において投稿等を行った場合、本サービスを提供する目的達成に必要な限度で、著作物性の有無を問わず、掲載内容の一部又は全部に関し、発生しうる全ての著作権（著作権法第27条及び第28条に定める権利を含みます。）について、目的を問わず、無償かつ無制限に利用できる権利を当社に対して許諾することについて同意します。</ListItem>
                    <ListItem>ユーザーは、方法又は形態の如何を問わず、本サービスにおいて提供される全ての情報及びコンテンツ（以下総称して「当社コンテンツ」といいます。）を著作権法に定める、私的使用の範囲を超えて複製、転載、公衆送信、改変その他の利用をすることはできません。</ListItem>
                    <ListItem>当社コンテンツに関する著作権、特許権、実用新案権、商標権、意匠権その他一切の知的財産権及びこれらの権利の登録を受ける権利（以下総称して「知的財産権」といいます。）は、当社又は当社がライセンスを受けているライセンサーに帰属し、ユーザーには帰属しません。また、ユーザーは、知的財産権の存否にかかわらず、当社コンテンツについて、複製、配布、転載、転送、公衆送信、改変、翻案その他の二次利用等を行ってはなりません。</ListItem>
                    <ListItem>ユーザーが本条の規定に違反して問題が発生した場合、ユーザーは、自己の費用と責任において当該問題を解決するとともに、当社に何らの不利益、負担又は損害を与えないよう適切な措置を講じなければなりません。</ListItem>
                    <ListItem>ユーザーは、著作物となりうる掲載内容の一部について、当社並びに当社より正当に権利を取得した第三者及び当該第三者から権利を承継した者に対し、著作者人格権（公表権、氏名表示権及び同一性保持権を含みます。）を行使しません。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第16条（禁止事項）
                </Heading>
                <OrderedList>
                    <ListItem>当社は、ユーザーによる本サービスの利用に際して、以下の各号に定める行為を禁止します。</ListItem>
                    <Text>(1)本規約に違反する行為</Text>
                    <Text>(2)当社、当社がライセンスを受けているライセンサーその他第三者の知的財産権、特許権、実用新案権、意匠権、商標権、著作権、肖像権等の財産的又は人格的な権利を侵害する行為又はこれらを侵害する恐れのある行為</Text>
                    <Text>(3)当社又は第三者に不利益若しくは損害を与える行為又はその恐れのある行為</Text>
                    <Text>(4)不当に他人の名誉や権利、信用を傷つける行為又はその恐れのある行為</Text>
                    <Text>(5)法令又は条例等に違反する行為</Text>
                    <Text>(6)公序良俗に反する行為若しくはその恐れのある行為又は公序良俗に反する恐れのある情報を他のユーザー又は第三者に提供する行為</Text>
                    <Text>(7)犯罪行為、犯罪行為に結びつく行為若しくはこれを助長する行為又はその恐れのある行為</Text>
                    <Text>(8)事実に反する情報又は事実に反する恐れのある情報を提供する行為</Text>
                    <Text>(9)当社のシステムへの不正アクセス、それに伴うプログラムコードの改ざん、位置情報の改ざん、故意に虚偽、通信機器の仕様その他アプリケーションを利用してのチート行為、コンピューターウィルスの頒布その他本サービスの正常な運営を妨げる行為又はその恐れのある行為</Text>
                    <Text>(10)マクロ及び操作を自動化する機能やツール等を使用する行為</Text>
                    <Text>(11)本サービスの信用を損なう行為又はその恐れのある行為</Text>
                    <Text>(12)青少年の心身及びその健全な育成に悪影響を及ぼす恐れのある行為</Text>
                    <Text>(13)他のユーザーのアカウントの使用その他の方法により、第三者になりすまして本サービスを利用する行為</Text>
                    <Text>(14)詐欺、預貯金口座及び携帯電話の違法な売買等の犯罪に結びつく又は結びつく恐れのある行為</Text>
                    <Text>(15)犯罪収益に関する行為、テロ資金供与に関する行為又はその疑いがある行為</Text>
                    <Text>(16)その他当社が不適当と判断する行為</Text>
                    <ListItem>当社は、ユーザーの行為が、第１項各号のいずれかに該当すると判断した場合、事前に通知することなく、以下の各号のいずれか又は全ての措置を講じることができます。</ListItem>
                    <Text>(1)本サービスの利用制限</Text>
                    <Text>(2)公開したデジタルコンテンツの削除</Text>
                    <Text>(3)公開したデジタルコンテンツの本サービス内検索結果からの除外</Text>
                    <Text>(4)本契約の解除による退会処分</Text>
                    <Text>(5)その他当社が必要と合理的に判断する行為</Text>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第17条（解除）
                </Heading>
                <OrderedList>
                    <ListItem>当社は、ユーザーが以下の各号のいずれかに該当した場合、何らの通知等を要することなく、本契約を解除し、退会させることができます。</ListItem>
                    <Text>(1)登録情報に虚偽の情報が含まれている場合</Text>
                    <Text>(2)過去に当社から退会処分を受けていた場合</Text>
                    <Text>(3)ユーザーの相続人等からユーザーが死亡した旨の連絡があった場合又は当社がユーザーの死亡の事実を確認できた場合</Text>
                    <Text>(4)未成年が法定代理人の同意なく、本サービスを利用した場合</Text>
                    <Text>(5)成年被後見人、被保佐人又は被補助人が、成年後見人、保佐人又は補助人等の同意なく、本サービスを利用した場合</Text>
                    <Text>(6)当社からの要請に対し誠実に対応しない場合</Text>
                    <Text>(7)その他当社が不適当と判断した場合</Text>
                    <ListItem>前項各号に掲げる場合のほか、当社は、ユーザーに対して30日前までに事前に通知することにより、本契約を解除し、退会させることができます。また、ユーザーが退会を希望する場合、当社が定める退会手続により、当月末日をもって本契約を解除し、退会することができます。</ListItem>
                    <ListItem>第1項及び第2項の措置により退会したユーザーは、退会時に期限の利益を喪失し、直ちに、当社に対し負担する全ての債務を履行します。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第18条（非保証・免責）
                </Heading>
                <OrderedList>
                    <ListItem>本サービスの内容について、その完全性、正確性及び有効性等について、当社は一切の保証をしません。また、当社は、本サービスに中断、中止その他の障害が生じないことを保証しません。</ListItem>
                    <ListItem>ユーザーが本サービスを利用するにあたり、本サービスから本サービスに関わる第三者が運営する他のサービス（以下「外部サービス」といいます。）に遷移する場合があります。その場合、ユーザーは、自らの責任と負担で外部サービスの利用規約等に同意の上、本サービス及び外部サービスを利用します。なお、外部サービスの内容について、その完全性、正確性及び有効性等について、当社は一切の保証をしません。</ListItem>
                    <ListItem>ユーザーが登録情報の変更を行わなかったことにより損害を被った場合でも、当社は一切の責任を負いません。</ListItem>
                    <ListItem>ユーザーは、法令の範囲内で本サービスをご利用ください。本サービスの利用に関連してユーザーが日本又は外国の法令に触れた場合でも、当社は一切の責任を負いません。</ListItem>
                    <ListItem>予期しない不正アクセス等の行為によってユーザー情報を盗取された場合でも、それによって生じるユーザーの損害等に対して、当社は一切の責任を負いません。</ListItem>
                    <ListItem>当社は、天災、地変、火災、ストライキ、通商停止、戦争、内乱、感染症の流行その他の不可抗力により本契約の全部又は一部に不履行が発生した場合、一切の責任を負いません。</ListItem>
                    <ListItem>本サービスの利用に関し、ユーザーが他のユーザーとの間でトラブル（本サービス内外を問いません。）になった場合でも、当社は一切の責任を負わず、ユーザー間のトラブルは、当該ユーザーが自らの費用と負担において解決します。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第19条（損害賠償責任）
                </Heading>
                <OrderedList>
                    <ListItem>ユーザーは、本規約の違反又は本サービスの利用に関連して当社に損害を与えた場合、当社に発生した損害（逸失利益及び弁護士費用を含みます。）を賠償します。</ListItem>
                    <ListItem>次項を除く本規約の他の定めにかかわらず、当社は、当社の帰責事由によりユーザーに損害を与えた場合、次の各号に定める範囲でのみその損害を賠償する責任を負います。</ListItem>
                    <Text>(1)当社の故意又は重過失による場合：当該損害の全額</Text>
                    <Text>(2)当社の軽過失による場合：現実かつ直接に発生した通常の損害（特別損害、逸失利益、間接損害及び弁護士費用を除く。）の範囲内とし、かつ1万円を上限とする</Text>
                    <ListItem>前項にかかわらず、ユーザーが法人である場合又は個人が事業として若しくは事業のために本サービスを利用する場合には、当社に故意又は重過失のない限り、本サービスに関連して当該ユーザーが被った損害につき当社は一切の責任を負いません。なお、当社が損害を賠償する場合は、損害発生日から直近1年間の販売手数料の累積総額を上限とします。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第20条（本サービスの廃止）
                </Heading>
                <OrderedList>
                    <ListItem>当社は、当社が本サービスの提供を廃止すべきと合理的に判断した場合、本サービスの提供を廃止できます。</ListItem>
                    <ListItem>前項の場合、当社に故意又は重過失がある場合を除き、当社は一切の責任を負いません。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第21条（秘密保持）
                </Heading>
                <OrderedList>
                    <ListItem>ユーザー及び当社は、本サービスの提供に関して知り得た相手方の秘密情報（本サービスに関するノウハウ、当社のシステムに関する情報、技術上又は営業上の一切の秘密情報を含みます。）を、厳重かつ適正に管理するものとし、相手方の事前の書面による同意なく第三者（当社の関連会社及び委託先を含みます。）に開示、提供及び漏洩しないものとします。</ListItem>
                    <ListItem>次の各号の情報は、秘密情報に該当しないものとします。</ListItem>
                    <Text>(1)開示を受けた時、既に所有していた情報</Text>
                    <Text>(2)開示を受けた時、既に公知であった情報又はその後自己の責に帰さない事由により公知となった情報</Text>
                    <Text>(3)開示を受けた後に、第三者から合法的に取得した情報</Text>
                    <Text>(4)開示された秘密情報によらず独自に開発し又は創作した情報</Text>
                    <Text>(5)法令の定め又は裁判所の命令に基づき開示を要請された情報</Text>
                    <ListItem>ユーザー及び当社は、相手方の指示があった場合又は本契約が終了した場合は、相手方の指示に従い速やかに秘密情報を、原状に回復した上で返却又は廃棄し、以後使用しないものとします。</ListItem>
                    <ListItem>当社は、ユーザーの同意を得て当社の関連会社又は委託先にユーザーの秘密情報を開示した場合、当該関連会社及び委託先の当該秘密情報の取扱いについて一切の責任を負いません。</ListItem>
                    <ListItem>当社は、本サービスを提供する目的のために、ユーザーの秘密情報を利用することができます。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第22条（反社会的勢力の排除）
                </Heading>
                <OrderedList>
                    <ListItem>ユーザー及び当社は、現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下総称して「暴力団員等」といいます。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを保証します。</ListItem>
                    <Text>(1)暴力団員等が経営を支配していると認められる関係を有すること</Text>
                    <Text>(2)暴力団員等が経営に実質的に関与していると認められる関係を有すること</Text>
                    <Text>(3)自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等、不当に暴力団員等を利用していると認められる関係を有すること</Text>
                    <Text>(4)暴力団員等に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有すること</Text>
                    <Text>(5)役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること</Text>
                    <ListItem>ユーザー及び当社は、自ら又は第三者を利用して次の各号のいずれにも該当する行為を行わないことを確約します。</ListItem>
                    <Text>(1)暴力的な要求行為</Text>
                    <Text>(2)法的な責任を超えた不当な要求行為</Text>
                    <Text>(3)取引に関して、脅迫的な言動をし、又は暴力を用いる行為</Text>
                    <Text>(4)風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為</Text>
                    <Text>(5)その他前各号に準ずる行為</Text>
                    <ListItem>ユーザー及び当社は、相手方が、暴力団員等若しくは第1項各号のいずれかに該当し、若しくは前項各号のいずれかに該当する行為をし、又は第1項の規定にもとづく表明・保証に関して虚偽の申告をしたことが判明した場合には、自己の責に帰すべき事由の有無を問わず、相手方に対して何らの催告をすることなく本契約を解除することができます。</ListItem>
                    <ListItem>ユーザー及び当社は、前項により本契約を解除した場合、相手方に損害が生じたとしてもこれを一切賠償する責任はないことを確認し、これを了承します。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第23条（お問い合わせ対応）
                </Heading>
                <OrderedList>
                    <ListItem>当社は、本サービスに関するユーザーからのお問い合わせに対して回答するよう努めますが、法令又は本規約上、当社に義務又は責任が発生する場合を除き、回答の義務を負いません。</ListItem>
                    <ListItem>当社は、ユーザーからのお問い合わせに回答するか否かの基準を開示する義務を負いません。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第24条（地位の譲渡等）
                </Heading>
                <OrderedList>
                    <ListItem>ユーザー及び当社は、相手方の書面による事前の承諾なく、本契約上の地位又は本規約に基づく権利若しくは義務の全部又は一部につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。但し、株式譲渡若しくは事業譲渡又は合併、会社分割その他の組織再編についてはこの限りではありません。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第25条（個人情報の取り扱い）
                </Heading>
                <OrderedList>
                    <ListItem>本サービスにおける個人情報の取り扱いに関しては、当社が定める「プライバシーポリシー」に基づき取り扱います。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第26条（分離可能性）
                </Heading>
                <OrderedList>
                    <ListItem>本規約の規定の一部が法令に基づいて無効と判断されても、本規約の他の規定は有効とします。</ListItem>
                    <ListItem>本規約の規定の一部があるユーザーとの関係で無効又は取消となった場合でも、本規約は他のユーザーとの関係では有効とします。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第27条（違反行為への対処方法）
                </Heading>
                <OrderedList>
                    <ListItem>ユーザーは、本規約に違反する行為を発見した場合は、当社にご連絡ください。</ListItem>
                    <ListItem>ユーザーは、本規約に違反する行為への当社の対処について、異議を申し立てることはできません。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第28条（本契約の有効期間）
                </Heading>
                <OrderedList>
                    <ListItem>本契約の有効期間は、本契約成立時からユーザーが退会するまでの間とします。なお、第10条第5項、第12条第3項、第4項及び第6項、第13条第5項及び第6項、第14条、第15条、第17条第3項、第18条から第20条、第22条第3項及び第4項、第24条、第26条、本条、第30条及び第31条の規定は、本契約の終了後も有効に存続するものとします。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第29条（本規約の変更）
                </Heading>
                <OrderedList>
                    <ListItem>当社は、以下の各号のいずれかに該当する場合は、民法第548条の4の規定に基づき本規約を随時変更できます。本規約が変更された後の本契約は、変更後の本規約が適用されます。</ListItem>
                    <Text>(1)本規約の変更が、ユーザーの一般の利益に適合するとき</Text>
                    <Text>(2)本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性及びその内容その他の変更に係る事情に照らして合理的なものであるとき</Text>
                    <ListItem>当社は、本規約の変更を行う場合は、変更後の本規約の効力発生時期を定め、効力発生時期の2週間前までに、変更後の本規約の内容及び効力発生時期をユーザーに通知、本サービス上への表示その他当社所定の方法によりユーザーに周知します。</ListItem>
                    <ListItem>前二項の規定にかかわらず、前項の本規約の変更の周知後にユーザーが本サービスを利用した場合又は当社所定の期間内にユーザーが解約の手続をとらなかった場合、当該ユーザーは本規約の変更に同意したものとします。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第30条（準拠法）
                </Heading>
                <OrderedList>
                    <ListItem>本規約に関する準拠法は、全て日本国の法令が適用されます。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第31条（合意管轄）
                </Heading>
                <OrderedList>
                    <ListItem>ユーザーと当社との間における一切の訴訟は、岡山地方裁判所を第一審の専属的合意管轄裁判所とします。</ListItem>
                </OrderedList>
                <Heading size='md' mt={8} pb={4} >
                    第32条（その他）
                </Heading>
                <OrderedList>
                    <ListItem>ユーザーは、本規約に定めのない事項について、当社が細目等を別途定めた場合、これに従います。この場合、当該細目等は、本規約と一体をなします。</ListItem>
                    <ListItem>細目等は、当社所定の箇所に掲載した時点より効力を生じます。</ListItem>
                    <ListItem>細目等と本規約の内容に矛盾抵触がある場合、本規約が優先します。</ListItem>
                </OrderedList>
                <Text size='md' mt={8} pb={4}>
                    2022年7月19日：制定・施行
                </Text>
            </Box>
        </>
    )
}

export default Terms;