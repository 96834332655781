import { Box, Heading, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import React from 'react';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';

const Company = () => {
    return (
        <>
            <HeadBlock title="会社概要"></HeadBlock>
            <HeaderPlane/>
            <Box mx={{base:'20px', md:'100px'}} >
                <Heading mt={8} pb={8} >運営会社</Heading>
                <TableContainer>
                    <Table variant='simple' align='center'>
                        <Tbody>
                            <Tr>
                                <Td>会社名</Td>
                                <Td>Meltly株式会社</Td>
                            </Tr>
                            <Tr>
                                <Td>設立</Td>
                                <Td>2022年2月1日</Td>
                            </Tr>
                            <Tr>
                                <Td>法人番号</Td>
                                <Td>8010701042191</Td>
                            </Tr>
                            <Tr>
                                <Td>所在地</Td>
                                <Td>〒142-0042 東京都品川区豊町4-19-21</Td>
                            </Tr>
                            <Tr>
                                <Td>代表者</Td>
                                <Td>髙橋侑志</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default Company;