import React from 'react';
import { Th, Thead, Tr } from '@chakra-ui/react';

export const TableHead = (props) => {
    const {left, center, center2, right} = props;
    return (
        <Thead>
            <Tr>
                <Th>{left}</Th>
                <Th>{center}</Th>
                <Th>{center2}</Th>
                <Th>{right}</Th>
            </Tr>
        </Thead>
    );
};