import { AspectRatio, Box, Flex, GridItem, HStack, Icon, Image, Spacer, Stack, Text } from "@chakra-ui/react";
import React, { memo } from "react";
import { FaRegHeart } from 'react-icons/fa';
import { ShowPriceS } from "../ShowPriceS";
import { Link as ReachLink } from 'react-router-dom';
import { ShowTimeForPostBox } from "../ShowTime";

const PostBox = memo((props) => {
    
    const { posts, color='#CC5083' } = props;
    return (
        posts.map((post, key) => (
            <GridItem as={ReachLink} to={'/p/' + post.postId} key={key}>
                <Box w={{base: '345px', md: '300px', lg: '230px', xl: '250px'}} h={{base: '330px', md: '296px', lg: '256px', xl:'276px'}} overflow='auto' p={0} cursor="pointer" _hover={{ opacity: 0.8 }}>
                    <Box textAlign="center" position={"relative"}>
                        <AspectRatio ratio={16 / 9}>
                            <Image
                                borderRadius={4}
                                src={post.imageUrl}
                                alt=""
                            />
                        </AspectRatio>
                        <Box bg={color} align="center" borderRadius={4} size='sm' boxShadow='dark-lg' position={"absolute"} bottom={3} right={3} >
                                { post.price===0 ? (<Text p={1} color={'white'}>無料</Text>) : (<ShowPriceS price={post.price}/>) }
                        </Box>
                        {/* なんかの場合わけ */}
                        { post.remain!==0 && post.remain!==null ?
                            <Flex>
                                <Box bgColor='#faf8ee' color='#CC5083'  w='auto' p='2px' borderRadius={4} position={"absolute"} bottom={1} left={1}>
                                    <Text fontSize='xs' mx={1} >残り{post.remain}部</Text>
                                </Box>
                                <Spacer/>
                            </Flex>
                        : "" }
                        { post.remain===0 ?
                            <Flex>
                                <Box bgColor='#faf8ee' color='#CC5083'  w='auto' p='2px' borderRadius={4} position={"absolute"} bottom={1} left={1}>
                                    <Text fontSize='xs' mx={1} >売り切れ</Text>
                                </Box>
                                <Spacer/>
                            </Flex>
                        : "" }
                    </Box>
                    <Box>
                        <HStack>
                            <Image src={post.icon} alt="" borderRadius="50%" width={{ base: 6, md: 8 }} height={{ base: 6, md: 8 }} style={{ objectFit: 'cover'}} ml={1} />
                            <Stack height={12} lineHeight='normal'>
                                <Box height={10}>
                                    <Text fontSize="sm" fontWeight="bold" textAlign={"left"} mt={1} mr={0.5} w={{base: '290px', md: '250px', lg: '180px', xl:'200px'}} noOfLines={2}>
                                        {post.title}
                                    </Text>
                                </Box>
                                <Text fontSize="xs" fontWeight="gray" textAlign={"left"} mt={-4}
                                >{post.userName}
                                </Text>
                                <HStack>
                                    <Icon as={FaRegHeart} color={color} w={4} h={4} />
                                    <Text color={color} fontSize='sm' >{post.likescount}</Text>
                                    <Box w='30px' />
                                    <ShowTimeForPostBox date0 = {post.date} color={'#797979'} />
                                </HStack>
                            </Stack>
                        </HStack>
                    </Box>
                </Box>
            </GridItem>
        ))
    );
});

export default PostBox;