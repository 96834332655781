import { AspectRatio, Box, Button, Center, Flex, Heading, HStack, Image, Icon, VStack, Text, Avatar, Image as SwanImage, Spacer, Stack, Link, Spinner, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import axios from 'axios';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import "react-quill/dist/quill.core.css";
import { Link as ReachLink, useNavigate, useLocation } from "react-router-dom";
import { FaRegHeart } from 'react-icons/fa';
import { ShowDateSmall } from '../../components/atoms/ShowDate';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';
import Logo1 from "../../images/nextswanLogo.png";
import Prism from "prismjs";

export default function Preview (props) {
    // モーダルの開閉状態
    const [postid, setPostid] = React.useState('');
    const buttonSize = useBreakpointValue(['sm', 'sm', 'md']);
    const [loading, setLoading] = React.useState(true);
    const [opening, setOpening] = React.useState(false);
    const [post, setPost] = React.useState({
        uuid: '',
        userid: '',
        username: '',
        user_intro: '',
        title: '',
        price: '',
        thumbnail_url: '',
        create_time: '',
        edit_time: '',
        like: '',
        icon: '',
        body: '',
        cut_body: '',
        spy: '',
    });
    const [post_info, setPost_info] = React.useState({
        price: '',
        favorite: '',
        move: [],
        remain: '',
    });

    const location = useLocation();
    const navigate = useNavigate()

    const load_post_info = () => {
        let post_id = location.pathname.split("/")[2]
        const post_info_data = new FormData();
        // console.log(postid);
        post_info_data.append("post_id", post_id);
        axios.post("/api/post_info/", post_info_data, {withCredentials: true})
        .then(res => {
            // console.log(res.data);
            setPost_info({
                price: res.data.price,
                favorite: res.data.favorite,
                move: res.data.move,
                remain: res.data.remain,
        });
        });
    }

    React.useEffect(() => {
        setLoading(true);
        let post_id = location.pathname.split("/")[2]
        setPostid(post_id);
        const load_post = async () => {
            const post_data = new FormData();
            post_data.append("post_id", post_id);
            await axios.post("/api/preview/", post_data, { withCredentials: true })
            .then(res => {
            if (res.request.status === 200) {
                // console.log(res.data);
                setPost({
                    title: res.data.title,
                    is_purchase: res.data.is_purchase,
                    is_login: res.data.is_login,
                    uuid: res.data.uuid,
                    userid: res.data.userid,
                    username: res.data.username,
                    userIntro: res.data.user_intro,
                    thumbnail: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + post_id + "." + (res.data.thumbnail_url ? res.data.thumbnail_url : "png"),
                    date: res.data.create_time,
                    edit_time: res.data.edit_time,
                    icon: (res.data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + res.data.uuid + res.data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                    body: res.data.body,
                    cut_body: res.data.cut_body,
                    spy: res.data.spy,
                });
            }
            })
            .finally(() => setLoading(false));
        }
        load_post();
    }, []);

    React.useEffect(() => {
        Prism.highlightAll();
    }, [post]);

    const open = () => {
        setOpening(!opening);
    }

    //リセール開始判定用の時間たち
    const today = new Date();
    const year = today.getFullYear();
    const month0 = today.getMonth();
    const month = ('0' + month0).slice(-2);
    const day0 = today.getDate();
    const day = ('0' + day0).slice(-2);
    const startResale = new Date(post.start);
    startResale.setHours(0);
    const todayDate = new Date (year, month, day);
    const dayGaps = ((startResale-todayDate)/1000/60/60/24);

    if (loading) {
        return(
            <>
                <HeaderPlane />
                <Center h="100vh">
                    <Spinner 
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='pink.500'
                    size='xl'
                    />
                </Center>
            </>
        )
    }else{
        return (
            <>
                <HeadBlock title={post.title} />
                <HStack>
                    <Flex
                        as="nav"
                        align="center"
                        justify="space-between"
                        w="100%"
                        padding={{base: 1, md: 2}}
                        bg="white"
                        color="black"
                    >
                    <Flex align="center" ml={-8} >
                        <Link as={ReachLink} to="/" _focus={{ boxShadow: "none"}}>
                            <SwanImage src={Logo1} alt="" width={{ base: 209, md: 313 }} height={{ base: '40px', md: '60px' }}/>
                        </Link>
                    </Flex>
                    <Stack
                        direction={{ base: "column", md: "row" }}
                        width={{ base: "auto", md: "auto" }}
                        alignItems="center"
                        flexGrow={1}
                        mt={{ base: 4, md: 0 }}
                    ></Stack>
                    </Flex>
                </HStack>
                <hr />
                <Center bgColor='pink.100' mb={1} ><Text fontSize='sm' py='1px' >この画面はプレビューです。</Text></Center>
                {/* 中央のコンテンツ */}
                <Flex pt={5} justify="center" mx={{base: '20px', md: '100px'}}>        
                    <AspectRatio w="640px" ratio={16 / 9}>
                        <Image src={post.thumbnail} style={{ objectFit: 'cover', width: '100%' }} />
                    </AspectRatio>
                </Flex>
                <Center pt="30px" mx={{base: '0px', md: '100px'}}>
                    <Stack maxW={{base: "95vw", md: '700px'}}>
                        {/* タイトル */}
                        <Heading size="xl" maxW="640px" flexWrap='wrap' >{ post.title }</Heading>
                        {/* いいね・投稿時間 */}
                        <HStack left={0} >
                            <Icon as={FaRegHeart} />
                            <Text fontSize='sm'>777</Text>
                            { post.date ? <ShowDateSmall time = {post.edit_time} pl={3}/> : ('') }
                            <Spacer/>
                            {/* 残り部数 */}
                            {post.limit === null ? "" :
                                <Flex minW='145px' >
                                    <Spacer/>
                                    <Text fontSize='sm'>
                                        残り
                                    </Text>
                                    <Text fontWeight='bold' fontSize='sm' color='#CC5083'>
                                        7
                                    </Text>
                                    <Text fontSize='sm'>
                                        /77部
                                    </Text>
                                </Flex>
                            }
                        </HStack>
                        
                        <Flex align="center">
                            {/* ユーザー名 */}
                            <Flex>
                                <Avatar size="sm" src={ post.icon } mt={2} />
                                <Link as={ReachLink} to={'/' + post.userid} _focus={{ boxShadow: "none"}}>
                                    <Text size="lg" mx={3} pt={3} maxW='540px' noOfLines={2} >{ post.username }</Text>
                                </Link>
                            </Flex>
                            <Spacer />
                            {/* 価格表示欄 */}
                            <Button
                                pointerEvents='none'
                                cursor='unset'
                                bg={"#E9B5D2"}
                                color={'white'}
                                align="center"
                                size='sm'
                                _focus={{ boxShadow: "none"}}
                            >購入済み
                            </Button>
                        </Flex>
                    {/* 購入していない時の表示 */}
                        <Box pt="20px" mx={{base: '0px', md: '100px'}}>
                            { opening ? 
                                <div class="ql-snow"><div className="ql-editor" dangerouslySetInnerHTML={{__html: post.body}}/></div>
                            :
                                <div class="ql-snow"><div className="ql-editor" dangerouslySetInnerHTML={{__html: post.cut_body}}/></div>
                            }
                            <Box h={10} />
                            <hr style={{ border: 'none', borderTop: '2px dashed' }} />
                            <VStack pt="20px">
                                {/* 購入ボタン */}
                                { opening ?
                                    <Button
                                        width="300px"
                                        bg={"#CC5083"}
                                        color={'white'}
                                        _hover={{ opacity: 0.8 }}
                                        variant='solid'
                                        _focus={{ boxShadow: "none"}}
                                        onClick={open}
                                    >無料部分のみ表示する
                                    </Button>
                                :
                                    <VStack>
                                        <HStack>
                                            <Text fontSize="sm" color="gray.600">有料化後も、ここまでが無料で表示されます。</Text>
                                        </HStack>
                                        <Button
                                            width="300px"
                                            bg={"#CC5083"}
                                            color={'white'}
                                            _hover={{ opacity: 0.8 }}
                                            variant='solid'
                                            _focus={{ boxShadow: "none"}}
                                            onClick={open}
                                        >全文を表示する
                                        </Button>
                                    </VStack>
                                }
                            </VStack>  
                        </Box>
                        <Box w='100vw' />
                    </Stack> 
                </Center>
            </>
        )
    }
}