import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const lineChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  title: {
    text: "コンテンツの価格推移(円)",
    align: "left"
  },
  tooltip: {
    enabled: false,
    theme: "light",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  xaxis: {
    type: "datetime",
    categories: [],
    labels: {
      style: {
        colors: "#FFFFFF",
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#606060",
        fontSize: "12px",
      },
    },
    // title: {
    //   text: "価格(円)"
    // }
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: true,
      opacityFrom: 0.7,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#CC5083", "#000"],
  },
  colors: ["#CC5083", "#000"],
  responsive: [{
    breakpoint: 768,
    options: { width: '200px' },
  }]
};

export const LineChart = (props) => {
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData([{name: "コンテンツの価格", data: props.move}]);
    setChartOptions(lineChartOptions);
  }, [])

    return (
      <ReactApexChart
        options={chartOptions}
        series={chartData}
        type="area"
        width='540px'
        height='400px'
      />
    );
};

export const LineChartMobile = (props) => {
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData([{name: "コンテンツの価格", data: props.move}]);
    setChartOptions(lineChartOptions);
  }, [])

    return (
      <ReactApexChart
        options={chartOptions}
        series={chartData}
        type="area"
        width='324px'
        height='240px'
      />
    );
};