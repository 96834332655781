//売上管理ページの部品。売上を表示する

import React from 'react';
import axios from 'axios'

import { 
    Box, 
    Heading, 
    Spacer,
    Text, 
    Table, 
    Tbody, 
    VStack, 
    TableContainer,
    Button,
    Center} from "@chakra-ui/react";
import { TableHead } from '../../components/atoms/table/TableHead';
import { ContentsTableLine } from './settingsAtom/ContentsTableLine';
import { ShowPriceL } from '../../components/atoms/ShowPriceL';

const DashboardCore = () => {
    const [sales, setASales] = React.useState({
        total_sales: 0,
        not_transfer: 0,
        invalid: 0,
    });
    let posts = [];
    let monthly = [];
    let markets = [];
    const [contents, setContents] = React.useState([]);
    const [month, setMonth] = React.useState([]);
    const [market, setMarket] = React.useState([]);

    React.useEffect(() => {
        const load_sales = async () => {
        await axios.post('/api/sales/', { withCredentials: true })
        .then(res => {
            console.log(res.data);
            setASales({
                total_sales: res.data.total_sales,
                not_transfer: res.data.not_transfered,
                invalid: res.data.invalid,
            });
        });
        }
        load_sales();

        const load_contents = async () => {
            await axios.post('/api/contents_sales/', { withCredentials: true })
            .then(res => {
                // console.log(res.data);
                for (let i=0;i<res.data.count;i++) {
                    let data = res['data'][i]
                    // console.log(data);
                    if (typeof data === "undefined") {
                        continue;
                    }
                    posts.push({
                        title: data.post_name,
                        detailA: data.sales_count,
                        detailA2: data.sales,
                        detailB: data.royalty,
                    });
                }
                setContents(posts);
            });
        }
        load_contents();

        const load_month = async () => {
            await axios.post('/api/monthly_sales/', { withCredentials: true })
            .then(res => {
                // console.log(res.data);
                for (let i=0;i<res.data.count;i++) {
                    let data = res['data'][i];
                    // console.log(typeof data);
                    // if (typeof data === "undefined") {
                    //     alert(i + "がない");
                    //     continue;
                    // }
                    // console.log(data);
                    monthly.push({
                        title: data.month,
                        detailA: data.sales,
                        detailB: data.status,
                    });
                    // console.log(monthly);
                }
                setMonth(monthly);
            });
        }
        load_month();

        const load_market = async () => {
            await axios.post('/api/market_sales/', { withCredentials: true })
            .then(res => {
                // console.log(res.data);
                for (let i=0;i<res.data.count;i++) {
                    let data = res['data'][i]
                    // console.log(data);
                    markets.push({
                        title: data.month,
                        detailB: data.sales,
                        detailA: data.count,
                    });
                    // console.log(monthly);
                    setMarket(markets);
                }
            });
        }
        load_market();
    }, []);

    const send_apply = async () => {
        if (window.confirm("振込申請を行いますか？")) {
        await axios.post("/api/apply/", {withCredentials: true})
        .then(res => {
            if (res.data.error) {
                alert(res.data.error);
            }else{
                window.location.reload();
            }
        });
    }
    }

    return(
        <VStack spacing={6} mx={8} mt={4} align='stretch' w={{base:'335px' , md:'450px'}}>
            <Spacer />
            <>
                <Heading as="h1" size="lg" textAlign="center">
                    振り込み可能な売上
                </Heading>
                <Box bg="#E9B5D2" color='white' w='100%' borderRadius={4} H='flex' p={2}>
                    <ShowPriceL price={sales.not_transfer} />
                </Box>
                <Text align="left" size="sm" >
                    ※前月末までの未振込の売上が1,000円以上の場合に振込申請が可能です。
                </Text>
                <Text align="left" size="sm" >
                    ※毎月1日から25日まで振込申請が可能です。
                </Text>
                <Text align="left" size="sm" >
                    ※振込1回ごとに、振込申請があった金額から振込手数料(145円〜)を除いた金額をお支払いします。
                </Text>
                {sales.not_transfer > 999 ? (<>
                <Center pt={4} >
                <Button
                    size='lg'
                    align='center'
                    bg='#CC5083'
                    color='white'
                    // onClick={() => setIsModal(true)}
                    _hover={{ opacity: 0.5 }}
                    _focus={{ boxShadow: "none"}}
                    onClick={send_apply}
                >振込申請
                </Button>
                </Center></> ): ("")}
            </><Spacer /><>
                <Heading as="h1" size="lg" textAlign="center" >
                    受け取り期限を過ぎた売上
                </Heading>
                <Box bg="#CCC" color='white' w='100%' borderRadius={4} H='flex' p={2}>
                    <ShowPriceL price={sales.invalid} />
                </Box>
            </><Spacer /><>
                <Heading as="h1" size="lg" textAlign="center" >
                    総売上
                </Heading>
                <Box bg="#CCC" color='white' w='100%' borderRadius={4} H='flex' p={2}>
                    <ShowPriceL price={sales.total_sales} />
                </Box>
            </><Spacer /><>
                <Heading as="h1" size="md" textAlign="center">
                    コンテンツ別の売上
                </Heading>
                <TableContainer>
                    <Table variant='simple' overflowX='auto' flexWrap='nowrap'>
                        {/* 新たに「ロイヤリティ」の列を設けている */}
                        <TableHead left='コンテンツ' center='購入者数' center2='売上(円)' right='ロイヤリティ(円)' />
                        <Tbody overflowX='auto' >
                            <ContentsTableLine contents={contents} />
                        </Tbody>
                    </Table>
                </TableContainer>
            </><Spacer /><>
                {/* marketでの売上(!=ロイヤリティ)をこの表で表示 */}
                <Heading as="h1" size="sm" textAlign="center" >
                    Nextswan リセールでのリセール売上
                </Heading>
                <TableContainer>
                    <Table variant='simple' overflowX='auto' flexWrap='nowrap'>
                        <TableHead left='期間' center='取引回数' right='売上(円)' />
                            <Tbody overflowX='auto' >
                                <ContentsTableLine contents={market} />
                            </Tbody>
                    </Table>
                </TableContainer>
                <Spacer />
            </><Spacer /><>
                <Heading as="h1" size="md" textAlign="center" >
                    月別の売上
                </Heading>
                <TableContainer>
                    <Table variant='simple' overflowX='auto' flexWrap='nowrap'>
                        <TableHead left='期間' center='総売上(円)' right='状況' />
                        <Tbody overflowX='auto' >
                            <ContentsTableLine contents={month} />
                        </Tbody>
                    </Table>
                </TableContainer>
                <Spacer />
            </>
        </VStack>
    )
}

export default DashboardCore;