import React , {useEffect }from 'react';
import axios from 'axios'

const Logout = () => {
    useEffect(() => {
        const logout = async () => {
            await axios.post('/api/logout/', {withCredentials: true})
            }
        logout()
    }, [])
    return(
        <div>Logout</div>
    );
}

export default Logout