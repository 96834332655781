import React from 'react';
import axios from 'axios';
import { Box, Button, Center, Flex, Heading, HStack, Image, Link, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Spacer, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { MarketHeader } from '../marketConponents/MarketHeader';
import MarketSettingsMenu from './MarketSettingsMenu';
import Modal from 'react-modal';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate, Link as ReachLink } from "react-router-dom";
import { HeadBlock } from '../../../components/organisms/layout/HeadBlock';

Modal.setAppElement('#root');
const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

//「出品中」の中のボックス
const ListingBox = (props) => {
    const { title, icon, userName, postid, listingNow, price } = props;
    
    const [ isModal, setIsModal ] = React.useState(false);
    const navigate = useNavigate();
    
    const archive_post = async () => {
        const data = new FormData();
        data.append("market_id", postid);
        // console.log(postid);
        await axios.post("/api/delete_market/", data, { withCredentials: true })
        .then(res => {
            window.location.reload();
        });
    }
    
    return(
        <Box pb="20px">
            <Modal style={customStyles} isOpen={isModal} onRequestClose={() => setIsModal(false)}>
                <Center>
                    <VStack>
                        <Heading size="md" pb={2} >本当にこの出品を取り下げますか？</Heading>
                        <hr width="100%" />
                        <HStack spacing="16px" pt={2} >
                            <Link color='black' fontSize='sm' onClick={() => setIsModal(false)}>キャンセル</Link>
                            <Spacer />
                            <Button 
                                bg='#E67D5A'
                                color='white'
                                fontSize='sm'
                                onClick={archive_post}
                                _hover={{ opacity: 0.5 }}
                                _focus={{ boxShadow: "none"}}
                            >出品取り下げ
                            </Button>
                        </HStack>
                    </VStack>
                </Center>
            </Modal>
            <HStack>
                <Box as={ReachLink} to={"/m/" + postid}>
                    <Stack>
                        <Text noOfLines={2} fontSize="sm" fontWeight="bold" textAlign={"left"}>{title}</Text>
                        <HStack>
                            <Image src={icon} alt="" borderRadius="50%" width={6} height={6} style={{ objectFit: 'cover'}} ml={1} />
                            <Text fontSize="sm" fontWeight="gray" textAlign={"left"} mt={-4} maxW='200px' noOfLines={2} >
                                {userName}
                            </Text>
                            <Spacer/>
                            <HStack>
                                <Text fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                    {price}
                                </Text>
                                <Text fontSize="sm" fontWeight="gray" textAlign={"left"}>
                                    円
                                </Text>
                            </HStack>
                        </HStack>
                    </Stack>
                </Box>
                <Spacer as={ReachLink} to={"/m/" + postid} />
                { listingNow ? (
                    <>
                    <Button minW='60px' maxW='60px' bg='white' color='black' borderColor='black' variant='outline' onClick={() => {navigate('/m/' + postid + '/edit')}} _focus={{ boxShadow: "none"}}>
                        編集
                    </Button>
                    <Popover>
                        <PopoverTrigger>
                            <ChevronDownIcon color='gray' m={{ base: 1, md: 2 }} />
                        </PopoverTrigger>
                        <PopoverContent w='150px' _focus={{ boxShadow: "none"}}>
                            <PopoverArrow />
                            <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                            <PopoverBody>
                                <Link color='black' fontSize='sm' onClick={() => setIsModal(true)}>出品取り下げ</Link>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    </>
                ) : "" }
            </HStack>
        </Box>
    );
};

//出品管理ページの出品中/取引済みを切り替えるタブ
const SettingsListingTabs = (props) => {
    return(
        <Box paddingBottom="30px" overflow='auto' >
            {/* <Switch size='md' onChange={handleChange} />ログイン */}
            <Tabs isFitted defaultIndex={props.number} isManual align='center' w={{base:'335px' , md:'450px'}} colorScheme='orange' overflowX='auto' flexWrap='nowrap'>
                <TabList>
                    {/* {user ? <Tab as={ReachLink} to="/">おすすめ</Tab> : '' } */}
                    <Tab whiteSpace='nowrap' number={0} _focus={{ boxShadow: "none"}}>出品中</Tab>
                    <Tab whiteSpace='nowrap' number={1} _focus={{ boxShadow: "none"}}>取引済</Tab>
                </TabList>
                { props.loading ? (
                    <Center h="100vh">
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='#E67D5A'
                            size='xl'
                        />
                    </Center>
                ) : (
                <TabPanels>
                    <TabPanel>
                        {props.listing_posts.map((listing_post) => (
                            <ListingBox as={ReachLink} to={"/m/" + listing_post.postId} title={listing_post.title} icon={listing_post.icon} userName={listing_post.userName} postid={listing_post.postId} listingNow={true} price={listing_post.price} />
                        ))}
                    </TabPanel>
                    <TabPanel>
                        {props.sold_posts.map((sold_post) => (
                            <Box as={ReachLink} to={"/m/" + sold_post.postId}>
                                <ListingBox title={sold_post.title} icon={sold_post.icon} userName={sold_post.userName} postid={sold_post.postId} listingNow={false} price={sold_post.price} />
                            </Box>
                        ))}
                    </TabPanel>
                </TabPanels>
                )}
            </Tabs>
        </Box>
    );
};

//出品管理ページ本体のコード
const SettingsListing = () => {
    const isMobile = useBreakpointValue({ base: true, lg: false });
    const [sold_post, setSold_post] = React.useState([]);
    const [listing_post, setListing_post] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        const load_sold = async () => {
            const temp_sold = []
            await axios.get('/api/list/market/sold/', { withCredentials: true })
            .then(res => {
                if (res.request.status === 200) {
                    for (let i=0;i<res.data.count;i++) {
                        let data = res['data'][i]
                        temp_sold.push({
                            postId: data.market_id,
                            title: data.title,
                            postDate: data.edit_time,
                            price: data.price,
                            icon: (data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + data.uuid + data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                            userName: data.username,
                        });
                    }
                }
                setSold_post(temp_sold);
            });
        }
        const load_listing = async () => {
            const temp_listing = []
            await axios.get('/api/list/market/listing/', { withCredentials: true })
            .then(res => {
                // console.log(res.data);
                if (res.request.status === 200) {
                    for (let i=0;i<res.data.count;i++) {
                        let data = res['data'][i]
                        temp_listing.push({
                            postId: data.market_id,
                            title: data.title,
                            postDate: data.create_time,
                            price: data.price,
                            icon: (data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + data.uuid + data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                            userName: data.username,
                        });
                    }
                }
                setListing_post(temp_listing);
            })
            .finally(() => setLoading(false));
        }
        load_sold();
        load_listing();
    }, [])

    return(
        <>
            <HeadBlock title="出品管理"/>
            <MarketHeader plasement="top"/>
            <Flex justify="center" height="flex" size="full" mx='20px'>
                { isMobile ? "" :
                <Stack mr='100px'>
                    <Box h={16} />
                    <MarketSettingsMenu mt={100} news={false} listing={true} history={false} />
                </Stack>
                }
                <VStack mx={8} py={4} align='stretch' maxW={450}>
                    <SettingsListingTabs listing_posts={listing_post} sold_posts={sold_post} number={0} loading={loading}/>
                </VStack>
            </Flex>
        </>
    );
};

export default SettingsListing;