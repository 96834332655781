// この画面の公開ボタンを押すと、出品を許可するかなどの設定ができるモーダルが出る
import React, { useState, useEffect, useRef } from "react";
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai-sublime.css';
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import Modal from 'react-modal';
import { Link as ReachLink, useNavigate, useLocation } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from 'date-fns/locale/ja';
import "react-datepicker/dist/react-datepicker.css"
import _ from 'lodash'

import { AspectRatio, Box, Flex, HStack, Image as SwanImage, Stack, Spacer, Button, Link, useBreakpointValue, Text, Input, Center, VStack, Icon, Heading, FormControl, Select, Switch, forwardRef, Spinner } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import Logo1 from "../../images/nextswanLogo.png";
import { FaImage, FaCalendarAlt } from 'react-icons/fa';
import { ShowDate2 } from "../../components/atoms/ShowDate";

hljs.configure({
  languages: ['javascript', 'ruby', 'python', 'rust', 'html', 'xml', 'java', 'go', 'css', 'dart', 'r', 'sql', 'vim', 'typescript', 'php', 'c', 'cpp'],
})

// 公開モーダル
Modal.setAppElement('#root')

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : '-30%',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    maxWidth              : '600px',
    overflow              : 'scroll', 
  }
};

// Quillのオ-バーライド
let Image =  ReactQuill.Quill.import('formats/image');
class OverImage extends Image {
	 static create(value) {
		let node = super.create(value);
		if (typeof value === 'string') {
			let image = this.sanitize(value);
      const image_data = new FormData();
      image_data.append("image", image);
      axios.post('/api/image_s3/', image_data, { withCredentials: true} )
      .then(res => {
        // console.log(res.data);
        if (res.request.status === 200) {
          node.setAttribute('src', res.data.s3_url);
        } else {
          // console.log("画像の挿入に失敗しました。");
        }
      }).catch(err => {
        alert("画像が挿入できませんでした。画像は4MB未満にしてください。")
        node.remove()
      });
		}
		return node;
	}
}

ReactQuill.Quill.register(OverImage, true);

const PostEdit = () => {
  const [value, setValue] = useState('');
  const buttonSize = useBreakpointValue(['sm', 'sm', 'md']);
  const previewButtonSize = useBreakpointValue(['xs', 'xs', 'sm']);
  const [ isModal, setIsModal ] = React.useState(false);
  const [thumnail, setThumnail ] = useState('');
  const [title, setTitle] = useState('');
  const [init_quill, setInit_quill] = useState('');
  const [postid, setPostid] = useState('');
  const [edit_time, setEdit_time] = useState('');
  const [isRelease, setIsRelease] = useState(false);
  const [limit, setLimit] = useState('50');
  const [price, setPrice] = useState('330');
  //モーダル内で出品を許可しているかどうか(許可してたらtrue)
  const [allowMarket, setAllowMarket] = useState(false);
  // const [choosePrice, setChoosePrice] = useState(false);

  // カレンダー用
  const today0 = new Date();
  const today = new Date();
  today.setDate(today.getDate()+5)
  const [date, setDate] = useState(today);
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const quil = useRef(null);

  var insertImage = () => {};


  const insertDeli = setInterval(() => {
    // console.log("insertDeli");
    if (document.getElementsByClassName('ql-insertImage')[0] !== undefined) {
      clearInterval(insertDeli);
      // console.log("イベント登録");
      insertImage = () => {
        // console.log("区切り画像挿入");
        // console.log(document.activeElement);
        if (quil.current.getEditor().getSelection() != null) {
          const cursorPosition = quil.current.getEditor().getSelection().index;
          quil.current.getEditor().insertEmbed(cursorPosition, 'image', 'https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/system/deli.png');
          quil.current.getEditor().setSelection(cursorPosition + 1);
        }
      }
      document.getElementsByClassName('ql-insertImage')[0].onclick = insertImage;
  }
  }, 1000);

  useEffect(() => {
    setLoading(true);
    const get_post = async () => {
      let post_id = location.pathname.split("/")[2]
      setPostid(post_id);
      const id_data= new FormData();
      id_data.append("post_id", post_id);
      await axios.post("/api/get-post/", id_data, { withCredentials: true })
      .then(res => {
      // console.log(res.data);
      if (res.request.status === 200) {
        setTitle(res.data.title === "名称未設定" ? "" :res.data.title);
        setValue(res.data.body);
        setInit_quill(res.data.body);
        setInit_quill(res.data.body);
        setThumnail(res.data.thumbnail_url);
        setEdit_time(res.data.edit_time);
        setIsRelease(res.data.is_release);
        setAllowMarket(res.data.is_market);
        if (res.data.is_release) {
          setLimit(res.data.limit);
          setPrice(res.data.price);
        }
        if (res.data.start) setDate(new Date(res.data.start.split("-")[0], res.data.start.split("-")[1] -1, res.data.start.split("-")[2]));
      }
      })
      setLoading(false);
    }
    get_post();
    const interval = setInterval(() => {document.getElementById("send_data_button").click();}, 30000);
    return () => {
      clearInterval(interval);
    }
  }, []);

  const titleChange = (e) => setTitle(e.target.value);
  // const disable = document.getElementById('quantity');
  const change_limit = (e) => {
    setLimit(e.target.value);
  }

  const change_price = (e) => {
    setPrice(e.target.value);
  }
  // const change_market = (e) => {
  //   disable.disabled=true;
  //   setAllowMarket(e.target.value);
  //   console.log(e.target.value);
  // }

  const handleFileChange =  async (e) => {
    if (e.target.files.length !== 0) {
        const thumnail_data = new FormData();
        thumnail_data.append("post_id", postid);
        thumnail_data.append("thumnail", e.target.files[0]);
        await axios.post('/api/thumnail_s3/', thumnail_data, { withCredentials: true })
        .then(res => {
          if (res.request.status === 200) {
            setThumnail(res.data.s3_url);
          }
        });
    }
  }
  const delete_thumnail = (e) => {
    setThumnail('');
    document.getElementById('thumnail_image').value = '';
  }

  // 下書き保存
  const send_data = async () => {
    if (title === "" ) setTitle("タイトル未設定");
    const data = new FormData();
    data.append("post_id", postid);
    data.append("title", title);
    data.append("body", value);
    data.append("thumbnail_url", thumnail);
    await axios.post('/api/edit-post/',data, {withCredentials: true})
    .then(res => {
      if (res.request.status === 200) {
        setEdit_time(res.data.edit_time);
        }
    }).catch(err => {
      alert("一時保存に失敗しました。")
    });
  }

  // 下書き保存してページを離れる
  const draft_save = async () => {
    if (title === "" ) setTitle("タイトル未設定");
    const data = new FormData();
    data.append("post_id", postid);
    data.append("title", title);
    data.append("body", value);
    data.append("thumbnail_url", thumnail);
    await axios.post('/api/edit-post/',data, {withCredentials: true})
    .then(res => {
      if (res.request.status === 200) {
        setEdit_time(res.data.edit_time);
        navigate("/settings/contents")
        }
    }).catch(err => {
      alert("一時保存に失敗しました。")
    });
  }

  const post_public = async () => {
    await send_data();
    const data = new FormData();
    data.append("post_id", postid);
    data.append("market", allowMarket);
    const yyyy = date.getFullYear();
    const mm = date.getMonth()+1;
    const dd = date.getDate();
    const startDate = yyyy + '/' + mm + '/' +dd
    if (!isRelease) {
      data.append("limit", limit);
      data.append("init_price", price);
      // alert(limit);
    }
    if (allowMarket) {
      data.append("start", startDate);
    }
    await axios.post("/api/public-post/", data, {withCredentials: true})
    .then(res => {
      // ここで初回か2回目か区別
      // console.log(res.data);
      if (isRelease) {
        navigate("/p/" + postid);
      }else{
        // navigate("/p/share/" + postid);
        navigate("/p/" + postid);
      }
    });
  }

  const Size = Quill.import("formats/size");
  Size.whitelist = ["large", "medium", "small"];
  Quill.register(Size, true);

  var toolbarOptions = [
    [{ 'header': [2, 3, 4, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'color': [] }, { 'background': [] }],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    [{ 'align': [] }],
    ['link', 'image', 'code', 'code-block'],
    ['clean'],
    ['insertImage']
  ];

  const modules = {
    syntax: true,
    // toolbar: toolbarOptions,
    toolbar: {
      // container: "#toolbar",
      container: toolbarOptions,
    },
    clipboard: {
      matchVisual: false
    },
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'align',
    'link', 'image', 'color', 'background',
    'code', 'code-block',
    'clean',
    'insertImage'
  ]

  const getInputTextForDate = (event) => {
    setDate(event);
  }
  registerLocale('ja', ja);

  //カレンダー開閉用のボタン
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button onClick={onClick} ref={ref} bg='white' colorScheme='gray' variant='outline' _focus={{ boxShadow: "none"}} _hover={{ opacity: 0.8 }} size='sm'>
      <Icon as={FaCalendarAlt} mr={2} />
      {value}
    </Button>
  ));

  const isMobile = useBreakpointValue({ base: true, md: false });
  
  if (loading) {
    return(
        <>
          <HStack>
            <Flex
                as="nav"
                align="center"
                justify="space-between"
                w="100%"
                padding={{base: 1, md: 2}}
                bg="white"
                color="black"
            >
              <Flex align="center" ml={-8} >
                  <Link as={ReachLink} to="/" _focus={{ boxShadow: "none"}}>
                    <SwanImage src={Logo1} alt="" width={{ base: 209, md: 313 }} height={{ base: '40px', md: '60px' }}/>
                  </Link>
              </Flex>
              <Stack
                  direction={{ base: "column", md: "row" }}
                  width={{ base: "auto", md: "auto" }}
                  alignItems="center"
                  flexGrow={1}
                  mt={{ base: 4, md: 0 }}
              ></Stack>
              <HStack >
                  <Button
                    // id="send_data_button"
                    bg={"white"}
                    color={'black'}
                    _hover={{ opacity: 0.8 }}
                    variant='outline'
                    align="center"
                    size={buttonSize}
                    onClick={draft_save}
                    _focus={{ boxShadow: "none"}}
                  >一時保存</Button>
                  <Spacer />
                  <Button
                    bg={"#CC5083"}
                    color={'white'}
                    _hover={{ opacity: 0.8 }}
                    align="center"
                    size={buttonSize}
                    onClick={() => setIsModal(true)}
                    _focus={{ boxShadow: "none"}}
                  >公開設定</Button>
              </HStack>
            </Flex>
          </HStack>
          <Center h="100vh">
              <Spinner 
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='pink.500'
              size='xl'
              />
          </Center>
        </>
    )
  }else{
    return (
      <>
      {/* 公開ボタン押下時に表示されるモーダル。*/}
      <Box w={{base: '300px', md: '600px'}} >
        <Modal style={customStyles} isOpen={isModal} onRequestClose={() => setIsModal(false)}>
          <Center>
              <Stack>
                <Heading fontSize={{base: '2xl', md: '3xl'}} align='center' mt={2}>詳細設定</Heading>
                <Box h={5} />
                {/* 部数設定 */}
                { isRelease ? (
                  <>
                    <Flex>
                      <Flex flex={{base: '50%', md: '80%'}}>
                        <Text fontSize={{base: 'xl', md: '2xl'}} color='gray.500'>部数</Text>
                        <Text fontSize={{base: 'lg', md: 'xl'}} mt={{base: '2px', md: '4px'}} color='gray.500'>(有料発行分)</Text>
                      </Flex>
                      { isMobile ? <Spacer/> : ""}
                      {/* 部数を選択 */}
                      <FormControl flex='20%' size='xs' >
                        <Select isDisabled id='quantity' focusBorderColor='gray.800' color='gray.800' cuesor='none' >
                        {limit === 15 ? (<option value="15">15</option>): <></>}
                        {limit === 30 ? (<option value="30">30</option>): <></>}
                        {limit === 50 ? (<option value="50">50</option>): <></>}
                        {limit === 77 ? (<option value="77">77</option>): <></>}
                        {limit === 100 ? (<option value="100">100</option>): <></>}
                        {limit === 300 ? (<option value="300">300</option>): <></>}
                        {limit === 500 ? (<option value="500">500</option>): <></>}
                        {limit === 777 ? (<option value="777">777</option>): <></>}
                        {limit === 1000 ? (<option value="1000">1000</option>): <></>}
                        {limit == null ? (<option value="0">無制限</option>): <></>}
                        {/* {limit === 5000 ? (<option value="3000">5000</option>): <></>} */}
                        {/* {limit === 7777 ? (<option value="7777">7777</option>): <></>} */}
                        {/* {limit === 10000 ? (<option value="10000">10000</option>): <></>} */}
                        </Select>
                      </FormControl>
                    </Flex>
                    <Text fontSize={{base: 'xs', md: 'sm'}} color='gray.500'>※あとから変更できません。</Text>
                  </>
                ) : (
                  <>
                    <Flex>
                      <Flex flex={{base: '50%', md: '80%'}}>
                        <Text fontSize={{base: 'xl', md: '2xl'}}>部数</Text>
                        <Text fontSize={{base: 'lg', md: 'xl'}} mt={{base: '2px', md: '4px'}} >(有料発行分)</Text>
                      </Flex>
                      { isMobile ? <Spacer/> : ""}
                      {/* 部数を選択 */}
                      <FormControl flex='20%' size='xs' >
                        <Select id='quantity' defaultValue='50' focusBorderColor='pink.500' name="limit" onChange={change_limit}>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="50" selected>50</option>
                          <option value="77">77</option>
                          <option value="100">100</option>
                          <option value="300">300</option>
                          <option value="500">500</option>
                          <option value="777">777</option>
                          <option value="1000">1000</option>
                          <option value="0">無制限</option>
                          {/* <option value="5000">5000</option> */}
                          {/* <option value="7777">7777</option> */}
                          {/* <option value="10000">10000</option> */}
                        </Select>
                      </FormControl>
                    </Flex>
                    <Text fontSize={{base: 'xs', md: 'sm'}}>※あとから変更できません。</Text>
                  </>
                )}
                <br/>
                {/* 初期価格選択 */}
                { !isRelease ? (
                  <>
                    <Flex>
                      <Flex flex={{base: '50%', md: '80%'}}>
                        <Text fontSize={{base: 'xl', md: '2xl'}}>スタート価格</Text>
                      </Flex>
                      { isMobile ? <Spacer/> : ""}
                      {/* 部数を選択 */}
                      <FormControl flex='20%' size='xs' >
                        <Select id='quantity' defaultValue='330' focusBorderColor='pink.500' name="limit" onChange={change_price}>
                          <option value="110">110円</option>
                          <option value="330">330円</option>
                          <option value="980">980円</option>
                          <option value="1980">1,980円</option>
                          <option value="3980">3,980円</option>
                          <option value="9800">9,800円</option>
                        </Select>
                      </FormControl>
                    </Flex>
                    {/* <Flex>
                      <Text fontSize={{base: 'xs', md: 'sm'}} flex='40%'>※公開された直後のスタート価格です。以降は読み手の評価に応じて価格が変わります。</Text>
                      <Spacer/>
                    </Flex> */}
                    <Text fontSize={{base: 'xs', md: 'sm'}}>※あとから変更できません。</Text>
                  </>
                ) : (
                  <>
                    <Flex>
                      <Flex flex={{base: '50%', md: '80%'}}>
                        <Text fontSize={{base: 'xl', md: '2xl'}} color='gray.500'>スタート価格</Text>
                      </Flex>
                      { isMobile ? <Spacer/> : ""}
                      {/* 部数を選択 */}
                      <FormControl flex='20%' size='xs' >
                        <Select isDisabled id='quantity' focusBorderColor='gray.800' color='gray.800' cuesor='none' >
                          {price === 110 ? (<option value="110">110円</option>): <></>}
                          {price === 330 ? (<option value="330">330円</option>): <></>}
                          {price === 980 ? (<option value="980">980円</option>): <></>}
                          {price === 1980 ? (<option value="1980">1,980円</option>): <></>}
                          {price === 3980 ? (<option value="3980">3,980円</option>): <></>}
                          {price === 9800 ? (<option value="9800">9,800円</option>): <></>}
                        </Select>
                      </FormControl>
                    </Flex>
                    {/* <Flex>
                      <Text fontSize={{base: 'xs', md: 'sm'}} color='gray.500' flex='40%'>※公開された直後のスタート価格です。以降は読み手の評価に応じて価格が変わります。</Text>
                      <Spacer/>
                    </Flex> */}
                    <Text fontSize={{base: 'xs', md: 'sm'}} color='gray.500'>※あとから変更できません。</Text>
                  </>
                )}
                {/* 出品許可 */}
                <br/>
                <Flex>
                  <Text fontSize={{base: 'xl', md: '2xl'}} flex={{base: '50%', md: '75%'}}>リセールを許可する</Text>
                  <Spacer/>
                  <FormControl flex='5%'>
                    {allowMarket ? (
                      <Switch size='md' mt={2} colorScheme='pink' _focus={{ boxShadow: "none"}} focusBorderColor='pink.500' onChange={() => setAllowMarket(!allowMarket)} isChecked />
                    ) : (
                      <Switch size='md' mt={2} colorScheme='pink' _focus={{ boxShadow: "none"}} focusBorderColor='pink.500' onChange={() => setAllowMarket(!allowMarket)} />
                    )}
                  </FormControl>
                </Flex>
                <Flex>
                  <Text fontSize={{base: 'xs', md: 'sm'}} flex='40%'>※リセールを許可すると、購入したユーザーがNextswan リセール上で、他のユーザーに販売することができます。その際、ロイヤリティとして著者(あなた)に売上の55%が支払われます。</Text>
                  <Spacer/>
                </Flex>
                <Text fontSize={{base: 'xs', md: 'sm'}}>※あとから変更できます。</Text>
                {/* 出品開始日 */}
                <br/>
                { allowMarket ? (
                  <>
                    <Flex>
                      <Text fontSize={{base: 'xl', md: '2xl'}} flex={{base: '50%', md: '75%'}} >リセールの解禁日</Text>
                      { isMobile ? "" : <Spacer/>}
                      {/* カレンダー */}
                      <Flex flex='25%'>
                        <DatePicker
                          dateFormat="yyyy/MM/dd"
                          selected={date}
                          onChange={getInputTextForDate}
                          minDate={today0}
                          locale='ja'
                          fixedHeight
                          customInput={<ExampleCustomInput />}
                        />
                      </Flex>
                    </Flex>
                    <Flex>
                      <Text fontSize={{base: 'xs', md: 'sm'}} flex='40%'>※購入したユーザーがNextswan リセール上で、他のユーザーにこのコンテンツを販売することができるようになる日です。</Text>
                      <Spacer/>
                    </Flex>
                    <Text fontSize={{base: 'xs', md: 'sm'}}>※あとから変更できます。</Text>
                  </>
                ) : (
                  <>
                    <Flex>
                      <Text fontSize={{base: 'xl', md: '2xl'}} flex={{base: '50%', md: '75%'}} color='gray.500'>リセールの解禁日</Text>
                      <Spacer/>
                      {/* カレンダー */}
                      <Flex flex='8%'>
                        <Button isDisabled bg='white' colorScheme='gray' color='gray.500' variant='outline' _focus={{ boxShadow: "none"}} size='sm'>
                          <Icon as={FaCalendarAlt} mr={2} color='gray.500'/>
                        </Button>
                      </Flex>
                    </Flex>
                    <Flex>
                      <Text fontSize={{base: 'xs', md: 'sm'}} flex='40%' color='gray.500'>※購入したユーザーがNextswan リセール上で、他のユーザーにこのコンテンツを販売することができるようになる日です。</Text>
                      <Spacer/>
                    </Flex>
                    <Text fontSize={{base: 'xs', md: 'sm'}} color='gray.500'>※あとから変更できます。</Text>
                  </>
                )}
                {/* 但書 */}
                {/* <br/> */}
                {/* <br/> */}
                {/* <Center>
                  <Box
                    px={2}
                    py={1}
                    _hover='none'
                    cursor='unset'
                    bg={"#DDFFDD"}
                    color={'black'}
                    size='sm'
                    borderRadius={10}
                    _focus={{ boxShadow: "none"}}
                  >
                    <HStack>
                      <Icon as={FaExclamationCircle} w={6} h={6} mr={1} color='teal.400' />
                      <Stack spacing='-2px' >
                        <Text fontSize={{base: 'sm', md: 'md'}}>5いいねされるまで、投稿は無料で公開されます。</Text>
                        <Text fontSize={{base: 'sm', md: 'md'}}>それ以降は、読み手の評価に応じて価格が変わります。</Text>
                      </Stack>
                    </HStack>
                  </Box>
                </Center> */}
                <br/>
                <hr width="100%" />
                <Box h={1} />
                <HStack spacing="16px" bgColor='white' pos='sticky' pt={4} pb={4} bottom={-5} >
                  <Spacer/>
                  <Link color='black' fontSize={{base: 'xs', md: 'sm'}} onClick={() => setIsModal(false)}>
                    キャンセル
                  </Link>
                  <Box w={2}/>
                  <Button 
                    bg='#CC5083'
                    color='white'
                    fontSize='sm'
                    onClick={post_public}
                    _hover={{ opacity: 0.5 }}
                    _focus={{ boxShadow: "none"}}
                  >公開</Button>
                </HStack>
              </Stack>
          </Center>
        </Modal>
      </Box>
      {/* モーダル終わり */}
      {/* 本体のコード */}
      {/* ヘッダー */}
      <Box>
        <HStack>
          <Flex
            as="nav"
            align="center"
            justify="space-between"
            w="100%"
            padding={{base: 1, md: 2}}
            bg="white"
            color="black"
          >
            <Flex align="center" ml={-8} >
              <Link as={ReachLink} to="/" _focus={{ boxShadow: "none"}}>
                <SwanImage src={Logo1} alt="" width={{ base: 209, md: 313 }} height={{ base: '40px', md: '60px' }}/>
              </Link>
            </Flex>
            <Stack
              direction={{ base: "column", md: "row" }}
              width={{ base: "auto", md: "auto" }}
              alignItems="center"
              flexGrow={1}
              mt={{ base: 4, md: 0 }}
            ></Stack>
            <HStack >
              <Button
                // id="send_data_button"
                bg={"white"}
                color={'black'}
                _hover={{ opacity: 0.8 }}
                variant='outline'
                align="center"
                size={buttonSize}
                onClick={draft_save}
                _focus={{ boxShadow: "none"}}
              >一時保存</Button>
              <Spacer />
              <Button
                bg={"#CC5083"}
                color={'white'}
                _hover={{ opacity: 0.8 }}
                align="center"
                size={buttonSize}
                onClick={() => setIsModal(true)}
                _focus={{ boxShadow: "none"}}
              >公開設定</Button>
            </HStack>
          </Flex>
        </HStack>
      </Box>
      {/* 保存時間を表示 */}
      { isMobile ? (
          <VStack spacing={0} bgColor='pink.100' borderBottomColor='black' mb={1} >
            <Text fontSize='xs' pt='1px' >エディタの[¥]を押すと、挿入された画像より上が無料で公開</Text>
            <Text fontSize='xs' pt='1px' >されます。未設定の場合は冒頭約20%が無料となります。</Text>
          </VStack>
        ) : (
          <Center bgColor='pink.100' mb={1} >
            <Text fontSize='sm' py='1px' >エディタの[¥]をクリックすると、挿入された画像より上の部分が無料で公開されます。</Text>
            <Text fontSize='sm' py='1px' >未設定の場合は冒頭約20%が無料となります。</Text>
          </Center>
        )
      }
      <HStack justify='right' mr='15px'>
        <Text color='gray.600' fontSize='sm'>最終<Link id="send_data_button" onClick={send_data}>更新</Link>: </Text>
        <ShowDate2 time = {edit_time} />
      </HStack>
      <Flex justify='right' mt={2} mr='15px'>
        <Button as={Link}
          bg={"white"}
          color={'black'}
          _hover={{ opacity: 0.8 }}
          variant='outline'
          align="center"
          size={previewButtonSize}
          target="_blank"
          rel="noreferrer"
          href={"/edit/" + postid + "/preview"}
          onClick={send_data}
          _focus={{ boxShadow: "none"}}
        >
          プレビュー
        </Button>
      </Flex>
      {/* サムネとタイトル */}
      <Box mx={{base: '20px', md: '100px'}} >
        <Box pt="10px" pb="10px">
          <Flex justify="center">
            <VStack spacing={4}>
              <label>
                {thumnail?
                <AspectRatio ratio={16 / 9} minW={{base: "320px", md: "480px", lg: "692px"}} minH={{base: "180px", md: "270px", lg: "387px"}} cursor='pointer'>
                  <SwanImage
                    src={"https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + postid + "." + thumnail}
                    style={{ objectFit: 'cover', width: '100%' }}
                    borderRadius={5}
                  />
                </AspectRatio>
                : ''} 
                <HStack cursor='pointer' pt={4} >
                  <Spacer/>
                  <Icon as={FaImage} color='gray.700' w={6} h={6} />
                  <Text fontSize='md'>サムネイルを挿入</Text>
                  <Spacer/>
                </HStack>
                {/* サーバの問題で今はpngのみ、そのうち他の拡張子も対応させたい */ }
                <Input type='file' accept='image/png,image/jpeg,img/jpg' style={{ display: 'none' }} onChange={handleFileChange} id="thumnail_image" />
              </label>
              {thumnail ?
              <HStack onClick={delete_thumnail} cursor='pointer'>
                <DeleteIcon w={6} h={6} color="#CC5083" />
                <Text fontSize='md'>サムネイルを削除</Text>
              </HStack>
              : ''}
              {/* サムネの初期化 */}
            </VStack>
          </Flex>
          <Spacer h={4} />
          <VStack pt="10px" pb="10px">
            {/* タイトル */}
            <Input focusBorderColor='white' minW={{base: "320px", md: "480px", lg: "692px"}} minH={{base: "40px", md: "45px", lg: "50px"}} defaultValue={title} placeholder=' タイトル' onChange={titleChange} />
          </VStack>
          <Spacer h={4} />
        </Box>
        <VStack className="text-editor">
          {/* <CustomToolbar /> */}
          <ReactQuill
            ref={quil}
            theme="snow"
            defaultValue={value}
            onChange={(c) => {setTimeout(() => {setValue(c)}, 2000)}}
            modules={modules}
            formats={formats}
            key={init_quill}
          />
        </VStack>
      </Box>
      </>
    );
  }
}

export default PostEdit;