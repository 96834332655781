import { Button, ButtonGroup, Center, FormControl, FormLabel, HStack, Input, Radio, RadioGroup, Spinner } from '@chakra-ui/react';
import React from 'react';
import axios from "axios"

export default function TransferForm(props) {
    const { lastNameC, lastNameH, firstNameC, firstNameH, postNum, address, bankCode, branchNum, accountNum, accountType, accountHolder } = props;
    const [lastname, setLastname] = React.useState("");
    const [firstname, setFirstname] = React.useState("");
    const [lastname_kana, setLastname_kana] = React.useState("");
    const [firstname_kana, setFirstname_kana] = React.useState("");
    const [postalcode, setPostalcode] = React.useState("");
    const [addresscode, setAddresscode] = React.useState("");
    const [bankcode, setBankcode] = React.useState("");
    const [branchcode, setBranchcode] = React.useState("");
    const [account_number, setAccount_number] = React.useState("");
    const [account_kind, setAccount_kind] = React.useState("");
    const [account_holder, setAccount_holder] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLastname(lastNameC);
        setFirstname(firstNameC);
        setLastname_kana(lastNameH);
        setFirstname_kana(firstNameH);
        setPostalcode(postNum);
        setAddresscode(address);
        setBankcode(bankCode);
        setBranchcode(branchNum);
        setAccount_number(accountNum);
        setAccount_kind(accountType);
        setAccount_holder(accountHolder);
    }, [props])

    const change_lastname = (e) => setLastname(e.target.value);
    const change_firstname = (e) => setFirstname(e.target.value);
    const change_lastname_kana = (e) => setLastname_kana(e.target.value);
    const change_firstname_kana = (e) => setFirstname_kana(e.target.value);
    const change_postalcode = (e) => setPostalcode(e.target.value);
    const change_addresscode = (e) => setAddresscode(e.target.value);
    const change_bankcode = (e) => setBankcode(e.target.value);
    const change_branchcode = (e) => setBranchcode(e.target.value);
    const change_account_number = (e) => setAccount_number(e.target.value);
    const change_account_kind = (e) => {
        setAccount_kind(e.target.value);
        // console.log("OnClick");
    }
    const change_account_holder = (e) => setAccount_holder(e.target.value);

    const send_data = async () => {
        setLoading(true);
        const data = new FormData();
        data.append("lastname", lastname);
        data.append("firstname", firstname);
        data.append("lastname_kana", lastname_kana);
        data.append("firstname_kana", firstname_kana);
        data.append("postalcode", postalcode);
        data.append("address", addresscode);
        data.append("bankcode", bankcode);
        data.append("branchcode", branchcode);
        data.append("account_number", account_number);
        data.append("account_kind", account_kind);
        data.append("account_holder", account_holder);
        
        await axios.post("/api/bank_account/", data, { withCredentials: true })
        .then(res => {
            // console.log(res.data);
        })
        .finally(() => setLoading(false));
    };

    // const delete_data = () => {
    //     setLastname('');
    //     setFirstname('');
    //     setLastname_kana('');
    //     setFirstname_kana('');
    //     setPostalcode('');
    //     setAddresscode('');
    //     setBankcode('');
    //     setBranchcode('');
    //     setAccount_number('');
    //     setAccount_kind('');
    //     setAccount_holder('');
    // };

    if (loading) {
        return(
        <Center h="100vh">
            <Spinner 
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='pink.500'
              size='xl'
            />
        </Center>
        )
    } else {
        return(
            <>
                <HStack>
                    <FormControl>
                        <FormLabel htmlFor='姓'>姓(全角)</FormLabel>
                        <Input id='姓' type='text' defaultValue={lastname} onChange={change_lastname}/>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor='名'>名(全角)</FormLabel>
                        <Input id='名' type='text' defaultValue={firstname} onChange={change_firstname}/>
                    </FormControl>
                </HStack>
                <HStack>
                    <FormControl>
                        <FormLabel htmlFor='せい'>せい(全角)</FormLabel>
                        <Input id='せい' type='text' defaultValue={lastname_kana} onChange={change_lastname_kana}/>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor='めい'>めい(全角)</FormLabel>
                        <Input id='めい' type='text' defaultValue={firstname_kana} onChange={change_firstname_kana}/>
                    </FormControl>
                </HStack>
                <FormControl>
                    <FormLabel htmlFor='郵便番号'>郵便番号(ハイフンなし半角数字)</FormLabel>
                    <Input id='郵便番号' type='number' defaultValue={postNum} onChange={change_postalcode}/>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor='住所'>住所</FormLabel>
                    <Input id='住所' type='text' defaultValue={address} onChange={change_addresscode}/>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor='金融機関コード'>金融機関コード(半角数字4桁)</FormLabel>
                    <Input id='金融機関コード' type='number' defaultValue={bankCode} onChange={change_bankcode}/>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor='支店番号'>支店番号(半角数字)</FormLabel>
                    <Input id='支店番号' type='number' defaultValue={branchNum} onChange={change_branchcode}/>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor='口座番号'>口座番号(半角数字7桁)</FormLabel>
                    <Input id='口座番号' type='number' defaultValue={accountNum} onChange={change_account_number}/>
                </FormControl>
                <FormControl as='fieldset'>
                    <FormLabel as='legend'>口座種別</FormLabel>
                    <RadioGroup onChange={setAccount_kind} value={account_kind}>
                        <HStack spacing='24px'>
                        <Radio value='1' colorScheme='pink' _focus={{ boxShadow: "none"}}>普通預金</Radio>
                        <Radio value='2' colorScheme='pink' _focus={{ boxShadow: "none"}}>当座預金</Radio>
                        <Radio value='3' colorScheme='pink' _focus={{ boxShadow: "none"}}>貯蓄預金</Radio>
                        </HStack>
                    </RadioGroup>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor='口座名義カナ'>口座名義(全角カタカナ)</FormLabel>
                    <Input id='口座名義カナ' type='text' defaultValue={account_holder} onChange={change_account_holder}/>
                </FormControl>
                <ButtonGroup display='flex' justifyContent='flex-end'>
                    {/* <Button
                        bg="#CCC"
                        size='sm'
                        color='white'
                        variant='solid'
                        _focus={{ boxShadow: "none"}}
                        // onClick={delete_data}
                    >振込先の削除</Button> */}
                    <Button
                        bg="#E9B5D2"
                        size='sm'
                        color='white'
                        variant='solid'
                        _focus={{ boxShadow: "none"}}
                        onClick={send_data}
                    >振込先の変更</Button>
                </ButtonGroup>
            </>
        );
    };
};