import React, { useState } from 'react';

import axios from 'axios';
import { Button, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputRightElement, Link, VStack, Text, Spacer } from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';


// const endpoint = "https://nextswan.com";
export default function LogIn() {
    const [loading, setLoading] = React.useState(false);
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const navigate = useNavigate();

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [next, setNext] = React.useState('');


    React.useEffect(() => {
        const url = new URL(window.location.href);
        const params = url.searchParams;
        setNext(params.get('next'));
        // console.log(params.get('next'));
    }, []);

    const emailchange = (e) => {
        setEmail(() => e.target.value)
    }

    const passwordchange = (e) => {
        setPassword(() => e.target.value)
    }

    const enterClick = (e) => {
        if (e.key === 'Enter') {
            send_data()
        }
    }

    const to_signup = () => {
        if (next) navigate('/signup?next=' + next);
        else navigate('/signup');
    }
    const forget = () => {
        navigate('/settings/password/reset');
    }

    const send_data = async () => {
        setLoading(true);
        const data = new FormData();
        data.append("email", email);
        data.append("password", password);

        await axios.post('/api/login/', data, { withCredentials: true })
        .then(res => {
            if (res.request.status === 200) {
                // console.log(res.data);
                // mypageにuserid問い合わせ
                axios.get('/api/mypage/', {  withCredentials: true})
                .then(res => {
                    // console.log(res.data);
                    if (res.request.status === 200) {
                        localStorage.setItem('myuserid', res.data.userid);
                        if (next==='signup') navigate('/');
                        else if (next) navigate('/' + next + '/');
                        else navigate('/');
                    };
                });
            };
        })
        .catch(res => {
            if (res.request.status === 400) {
                alert('メールアドレスまたはパスワードに誤りがあります。');
            }
        })
        .finally(() => setLoading(false));
    }

    return (
        <>
            <HeadBlock title="ログイン" />
            <HeaderPlane />
            <Flex mt={10} justify="center" height="flex" size="full">
                <VStack spacing={6} py={4} px={10} align='stretch' w={450}>
                    <Heading as="h1" size="lg" textAlign="center">
                        ログイン
                    </Heading>
                    <FormControl isRequired>
                        <FormLabel htmlFor='email'>メールアドレス</FormLabel>
                        <Input id='email' type='email' onChange={emailchange} value={email} focusBorderColor='#CC5083'/>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor='password'>パスワード</FormLabel>
                        <InputGroup>
                            <Input id='password' type={show ? 'text' : 'password'} onChange={passwordchange} onKeyPress={(e) => enterClick(e)} value={password} focusBorderColor='#CC5083'/>
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick} _focus={{ boxShadow: "none"}}>
                                    {show ? <ViewIcon /> : <ViewOffIcon /> }
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <Spacer />
                    {/* <Checkbox colorScheme='pink'>ログイン状態を保持</Checkbox> */}
                    {/* <Stack spacing={6} py={4} px={10}></Stack> */}
                    { loading ? (
                        <Button
                            bg={"#CC5083"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            // loadingText='ログイン'
                            variant='solid'
                            onClick={send_data}
                            _focus={{ boxShadow: "none"}}
                            disabled={email === "" || password === ""}
                            isLoading
                        >ログイン</Button>
                    ) : (
                        <Button
                            bg={"#CC5083"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            // loadingText='ログイン'
                            variant='solid'
                            onClick={send_data}
                            _focus={{ boxShadow: "none"}}
                            disabled={email === "" || password === ""}
                        >ログイン</Button>
                    )}
                    {/* <Stack spacing={6} py={4} px={10}></Stack> */}
                    <Text align="center">
                        会員登録がまだの方は {''}
                        <Link color='teal.500' onClick={to_signup} _focus={{ boxShadow: "none"}}>こちら</Link>
                    </Text>
                    <Text align="center">
                        <Link color='gray.800' onClick={forget} _focus={{ boxShadow: "none"}} fontSize='sm' >パスワードを忘れた場合</Link>
                    </Text>
                </VStack>
            </Flex>
        </>
    );
};
