import React from 'react';
import { Td, Tr } from '@chakra-ui/react';

export const TableLine = (props) => {
    const {left, center, center2, right} = props;
    return (
        <Tr>
            <Td maxW='240px' overflow='auto' flexWrap='nowrap'>{left}</Td>
            <Td isNumeric>{center}</Td>
            <Td isNumeric>{center2}</Td>
            <Td isNumeric >{right}</Td>
        </Tr>
    );
};