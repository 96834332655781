import { AspectRatio, Box, Button, Center, Flex, Heading, HStack, Image, Icon, VStack, Text, Avatar, useToast, useBreakpointValue, Spacer, Stack, Link, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverFooter, Spinner, TagLeftIcon, TagLabel, Tag } from '@chakra-ui/react';
import React from 'react';
import axios from 'axios';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import "react-quill/dist/quill.core.css";
import 'highlight.js/styles/monokai-sublime.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link as ReachLink, useNavigate, useLocation } from "react-router-dom";
import { FaRegHeart, FaHeart, FaFilm, FaTwitter, FaLine, FaShieldAlt, FaUnlockAlt, FaLink, FaFacebook, FaRegCopy, FaGraduationCap, FaSuitcase, FaCogs, FaBook, FaCommentsDollar, FaChair, FaHeadphonesAlt, FaTicketAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import CheckoutForm from './settlement/CheckoutForm';
import ServiceLogo from '../../images/nextswanLogo.png';
import { LineChart, LineChartMobile } from '../../components/atoms/LineChart';
import { ShowDateSmall } from '../../components/atoms/ShowDate';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';
import { CheckCircleIcon, Search2Icon, QuestionOutlineIcon } from '@chakra-ui/icons';
import { AboutPoint, AboutStep } from '../about/About';
import { ShowPriceBlack } from '../../components/atoms/ShowPriceS';

Modal.setAppElement('#root')
const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};
const customVideoStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        maxHeight              : '98vh',
        overflow              : 'scroll', 
    }
};

// Stripeのテスト用の公開可能キー
// const stripePromise = loadStripe("pk_test_51KjxcUFwZPjr5Y2qpaIzTHh1KmOrefvYgt3V9nNnIiFpYzgdxJMOnVwQOaaloMQrXOf5eWeMneYJxyh1xlfqdrUI009RnkA5jr");
const stripePromise = loadStripe("pk_live_51KjxcUFwZPjr5Y2ql0ByH9anQkshj4BsySMgXTZiRNT0OIjP2MNcKh6pse7YUOnh7M7AclU1t2Un1BWM2TxwcC0J00rXzEZuaN");

export default function PostDetail (props) {
    // モーダルの開閉状態
    const [isModal, setIsModal] = React.useState(false);
    const [isVideoModal, setIsVideoModal] = React.useState(false);
    const [postid, setPostid] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [profile, setProfile] = React.useState({
        username: '',
        description: '',
        icon: '',
    });
    const [post, setPost] = React.useState({
        title: '',
        body: '',
        date: '',
        is_purchase: '',
        is_login: '',
        is_me: '',
        userid: '',
        thumbnail: '',
        soldOut: true,
        limit: '',
        sold: '',
        start: '',
        academia: false,
        business: false,
        tech: false,
        career: false,
        money: false,
        lifestyle: false,
        culture: false
    });
    const [post_info, setPost_info] = React.useState({
        price: '',
        favorite: '',
        move: [],
        remain: '',
    });
    const [movie, setMovie] = React.useState({
        s3url: '',
    });
    const [stripe_reserve, setStripe_reserve] = React.useState({
        price: '',
        limit: '',
        email: '',
        saved: false,
    });
    //以下のcheckFavoriteがtrueの時、購入してからでないといいねできない旨を伝えるポップアップが出る
    const [checkFavorite, setCheckFavorite] =  React.useState(false);

    const location = useLocation();
    const navigate = useNavigate()

    const load_post_info = () => {
        let post_id = location.pathname.split("/")[2]
        const post_info_data = new FormData();
        // console.log(postid);
        post_info_data.append("post_id", post_id);
        axios.post("/api/post_info/", post_info_data, {withCredentials: true})
        .then(res => {
            // console.log(res.data);
            setPost_info({
                price: res.data.price,
                favorite: res.data.favorite,
                move: res.data.move,
                remain: res.data.remain,
        });
        });
    }

    React.useEffect(() => {
        var interval;
        setLoading(true);
        let post_id = location.pathname.split("/")[2]
        setPostid(post_id);
        const load_post = async () => {
            const post_data = new FormData();
            
            post_data.append("post_id", post_id);
            await axios.post("/api/article/", post_data, { withCredentials: true })
            .then(res => {
            if (res.request.status === 200) {
                // console.log(res.data);
                setPost({
                    title: res.data.title,
                    body: res.data.body,
                    date: res.data.create_time,
                    is_purchase: res.data.is_purchase,
                    is_login: res.data.is_login,
                    userid: res.data.userid,
                    thumbnail: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + post_id + "." + (res.data.thumbnail_url ? res.data.thumbnail_url : "png"),
                    is_me: res.data.is_me,
                    limit: res.data.limit,
                    start: res.data.start,
                    spy: res.data.spy,
                    academia: res.data.tag_academia,
                    business: res.data.tag_business,
                    tech: res.data.tag_tech,
                    career: res.data.tag_career,
                    money: res.data.tag_money,
                    lifestyle: res.data.tag_lifestyle,
                    culture: res.data.tag_culture
                });
                setLike(res.data.is_favorite);
                setPost_info({
                    price: res.data.price,
                    favorite: res.data.like,
                    move: res.data.move,
                    remain: res.data.remain,
                });
                if (res.data.price !== 0 && !res.data.is_purchase) setCheckFavorite(true);
                setProfile({
                    username: res.data.username,
                    description: res.data.user_intro,
                    icon: (res.data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + res.data.uuid + res.data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                });
                setMovie({
                    s3url: res.data.movie,
                });
                interval = setInterval(load_post_info, 30000);
            }
            })
            .finally(() => setLoading(false));
        }
        load_post();

        
        return () => {
        clearInterval(interval);
    }
    }, []);

    const need_login = () => {
        navigate('/login?next=p/' + postid);
    }

    const payment_reserve = async () => {
        setIsModal(true)
        let post_id = location.pathname.split("/")[2]
        setPostid(post_id);
        const pay_reserve_data = new FormData();
        pay_reserve_data.append("post_id", post_id);
        await axios.post('/api/payment_reserve/', pay_reserve_data, { withCredentials: true })
        .then(res => {
            if (res.request.status === 200) {
                // console.log(res.data);
                setStripe_reserve({
                    price: res.data.price,
                    limit: res.data.limit,
                    email: res.data.email,
                    saved: res.data.saved,
                });
            }
        });
    }

    // Stripeのクライアントキー(環境変数を使ってテストできるけど、セキュリティ的にサーバーから受け取りたい)
    // Elementsのoptionsに渡す
    /*const options = {
        clientSecret: '{{CLIENT_SECRET}}'
    }*/


    // クリップボードにコピー
    const toast = useToast()
    const copyTextToClipboard = (text) => {
        navigator.clipboard.writeText(text)
        .then(function() {
            // console.log('Async: Copying to clipboard was successful!');
            toast({
                title: "URLをコピーしました",
                duration: 5000,
                status: 'success',
                isClosable: true
            })
        }, function(err) {
            // console.error('Async: Could not copy text: ', err);
        });
    }
    const isMobile = useBreakpointValue({ base: true, md: false });
    const titleSize = useBreakpointValue({ base: 'md', lg: 'lg' });

    // いいねの処理
    const [like, setLike ] = React.useState(false);
    const heartClick = async () => {
        if (!post.is_me && post.is_login && (post.is_purchase || post_info.price === 0)) {
            const favorite_data = new FormData()
            favorite_data.append("post_id", postid);
            await axios.post("/api/like/", favorite_data, { withCredentials: true })
            .then(res => {
                if (res.request.status === 200) {
                    setLike(!like);
                    // console.log(res);
                    setPost_info({
                        favorite: res.data.like,
                        price: res.data.price,
                        remain: post_info.remain,
                        move: res.data.move,
                    });
                }
            });
        }
    }

    const send_share = async () => {
        const share_data = new FormData();
        share_data.append("post_id", postid);
        await axios.post("/api/share/", share_data, { withCredentials: true });
    }

    //いいね数が5以上の時、購入してからでないといいねできない旨を伝えるポップアップ
    function likeAlert () {
        if (!post.is_login) {
            return(
                <Popover >
                    <PopoverTrigger>
                        <Box>
                            <Icon as={FaRegHeart} w={{base: 8, md: 12}} h={{base: 8, md: 12}} pt={{base: 1, md: 0}} onClick={heartClick} position='relative' color="#CC5083" />
                        </Box>
                    </PopoverTrigger>
                    <PopoverContent w='250px' align='center' >
                        <PopoverArrow />
                        <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                        <Text align='center' fontWeight='semibold' pt={2} >コンテンツは、</Text>
                        <Text align='center' fontWeight='semibold'pt={2} pb={2}>ログインするといいねできます。</Text>
                        <PopoverFooter>
                            <Button
                                bg={'#CC5083'}
                                color='white'
                                _hover={{ opacity: 0.8 }}
                                _focus={{ boxShadow: "none"}}
                                onClick={need_login}
                                >ログイン
                            </Button>
                        </PopoverFooter>
                    </PopoverContent>
                </Popover>
            )
        } else if (!post.is_me) {
            return(
                <Popover >
                    <PopoverTrigger>
                        <Box>
                            { (!checkFavorite) ? like ? (
                                <Icon as={FaHeart} cursor='pointer' w={{base: 8, md: 12}} h={{base: 8, md: 12}} pt={{base: 1, md: 0}} onClick={heartClick} position='relative' color="#CC5083" /> 
                            ) : ( 
                                <Icon as={FaRegHeart} cursor='pointer' w={{base: 8, md: 12}} h={{base: 8, md: 12}} pt={{base: 1, md: 0}} onClick={heartClick} position='relative' color="#CC5083" />
                            ) : (
                                <Icon as={FaRegHeart} cursor='pointer' w={{base: 8, md: 12}} h={{base: 8, md: 12}} pt={{base: 1, md: 0}} position='relative' color="#CC5083" />
                            )}
                        </Box>
                    </PopoverTrigger>
                    {/* { checkFavorite && post_info.remain!==0 ? (
                        <PopoverContent w='250px' align='center' >
                            <PopoverArrow />
                            <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                            <Text align='center' fontWeight='semibold' pt={2} >現在この記事は有料です。</Text>
                            <Text align='center' fontWeight='semibold'pt={2} pb={2}>購入するといいねできます。</Text>
                            <PopoverFooter>
                                <Button 
                                    onClick={payment_reserve}
                                    size='md'
                                    bg={'#CC5083'}
                                    color='white'
                                    _hover={{ opacity: 0.8 }}
                                    _focus={{ boxShadow: "none"}}
                                    >購入する
                                </Button>
                            </PopoverFooter>
                        </PopoverContent>
                    ) : ('')}
                    { checkFavorite && post_info.remain===0 ? (
                        <PopoverContent w='320px' align='center' >
                            <PopoverArrow />
                            <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                            <Text align='center' fontWeight='semibold' pt={2} >この記事は売り切れました。</Text>
                            <Text align='center' fontWeight='semibold'pt={2} pb={2}>Nextswan リセールでお探しください。</Text>
                            <PopoverFooter>
                                <Button 
                                    as={ReachLink}
                                    to={'/m/s?q=' + post.title}
                                    size='md'
                                    bg={'#E67D5A'}
                                    color='white'
                                    _hover={{ opacity: 0.8 }}
                                    _focus={{ boxShadow: "none"}}
                                    >Nextswan リセールへ
                                </Button>
                            </PopoverFooter>
                        </PopoverContent>
                    ) : ('')} */}
                </Popover>
            )
        } else {
            return(
                <Popover >
                    <PopoverTrigger>
                        <Box>
                            <Icon as={FaRegHeart} w={{base: 8, md: 12}} h={{base: 8, md: 12}} pt={{base: 1, md: 0}} onClick={heartClick} position='relative' color="#CC5083" />
                        </Box>
                    </PopoverTrigger>
                    <PopoverContent w='320px' align='center'>
                        <PopoverArrow />
                        <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                        <Text align='center' fontWeight='semibold' pt={2} >自分の投稿はいいねできません。</Text>
                        <Text align='center' fontWeight='semibold'pt={2} pb={2}>投稿を他の人に共有してみましょう！</Text>
                    </PopoverContent>
                </Popover>
            )
        }
    }

    //リセール開始判定用の時間たち
    const today = new Date();
    const year = today.getFullYear();
    const month0 = today.getMonth();
    const month = ('0' + month0).slice(-2);
    const day0 = today.getDate();
    const day = ('0' + day0).slice(-2);
    const startResale = new Date(post.start);
    startResale.setHours(0);
    const todayDate = new Date (year, month, day);
    const dayGaps = ((startResale-todayDate)/1000/60/60/24);

    if (loading) {
        return(
            <>
                <HeaderPlane />
                <Center h="100vh">
                    <Spinner 
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='pink.500'
                        size='xl'
                    />
                </Center>
            </>
        )
    }else{
        return (
            <>
                <HeadBlock title={post.title} />
                <HeaderPlane />
                <hr />
                {/* 決済モーダル */}
                <Modal style={customStyles} isOpen={isModal} onRequestClose={() => setIsModal(false)}>
                    <Center>
                        <VStack spacing="15px">
                            <Image src={ServiceLogo} width="300px" />
                            <Elements stripe={stripePromise}>
                                <CheckoutForm price={stripe_reserve.price} post_id={postid} saved={stripe_reserve.saved}/>
                            </Elements>
                        </VStack>
                    </Center>
                </Modal>
                {/* chicksモーダル */}
                {/* <Modal isOpen={isVideoModal} onRequestClose={() => setIsVideoModal(false)}> */}
                    {/* <Center> */}
                        {/* <Stack> */}
                            {/* <Heading fontSize={{base: '2xl', md: '3xl'}} align='center' mt={2}>宣伝用ショート動画</Heading> */}
                            {/* <Text fontSize='sm' align='center'>(クリエイターにのみ表示されます)</Text> */}
                            {/* <Flex>
                                <Spacer/>
                                <VStack>
                                    <Spacer/>
                                    <Link color='black' fontSize={{base: 'xs', md: 'sm'}} onClick={() => setIsVideoModal(false)}>
                                        戻る
                                    </Link>
                                    <Spacer/>
                                </VStack>
                                <Spacer/>
                                <Button 
                                    bg='#CC5083'
                                    color='white'
                                    fontSize='sm'
                                    // onClick={post_public}
                                    _hover={{ opacity: 0.5 }}
                                    _focus={{ boxShadow: "none"}}
                                    >
                                    <DownloadIcon/>&nbsp;保存する
                                </Button>
                                <Spacer/>
                            </Flex> */}
                            {/* <video style={{width:"auto", height:"98vh"}} src="https://nextswan-static.s3.ap-northeast-1.amazonaws.com/chicks/test.mp4" controls autoplay muted loop playsinline></video> */}
                        {/* </Stack> */}
                    {/* </Center> */}
                {/* </Modal> */}
                {/* 中央のコンテンツ */}
                <Flex pt={5} justify="center" mx={{base: '20px', md: '100px'}}>        
                    <AspectRatio w="640px" ratio={16 / 9}>
                        <Image src={post.thumbnail} style={{ objectFit: 'cover', width: '100%' }} />
                    </AspectRatio>
                </Flex>
                <Center pt="30px" mx={{base: '0px', md: '100px'}}>
                    <Stack maxW={{base: "95vw", md: '700px'}}>
                        {/* タイトル */}
                        <Heading size={titleSize} flexWrap='wrap' >{ post.title }</Heading>
                        {/* いいね・投稿時間 */}
                        <HStack left={0} >
                            <Icon as={FaRegHeart} />
                            <Text fontSize='sm'>{post_info.favorite}</Text>
                            { post.date ? <ShowDateSmall time = {post.date} pl={3}/> : ('') }
                            <Spacer/>
                            {/* 残り部数 */}
                            {post.limit === null ? "" :
                                <Flex minW='145px' >
                                    <Spacer/>
                                    <Text fontSize='sm'>
                                        残り
                                    </Text>
                                    <Text fontWeight='bold' fontSize='sm' color='#CC5083'>
                                        {post_info.remain}
                                    </Text>
                                    <Text fontSize='sm'>
                                        /{post.limit}部
                                    </Text>
                                </Flex>
                            }
                        </HStack>
                        
                        <Flex align="center">
                            {/* ユーザー名 */}
                            <Flex>
                                <Avatar size="sm" src={ profile.icon } mt={2} />
                                <Link as={ReachLink} to={'/' + post.userid} _focus={{ boxShadow: "none"}}>
                                    <Text size="lg" mx={3} pt={3} maxW='540px' noOfLines={2} >{ profile.username }</Text>
                                </Link>
                            </Flex>
                            <Spacer />
                            {/* 価格表示欄 */}
                            { post.is_purchase && post_info.price > 0 ?
                            <Button
                                pointerEvents='none'
                                cursor='unset'
                                bg={"#E9B5D2"}
                                color={'white'}
                                align="center"
                                size='sm'
                                _focus={{ boxShadow: "none"}}
                            >購入済み
                            </Button> :
                            <Button
                                pointerEvents='none'
                                cursor='unset'
                                bg={"#E6AFCF"}
                                color={'white'}
                                align="center"
                                size='sm'
                                _focus={{ boxShadow: "none"}}
                            >{post_info.price}円
                            </Button>
                            }
                        </Flex>
                        <Flex align="center">
                            {/* タグ */}
                            { post.academia ? (
                                <Tag size='md' variant='outline' colorScheme='pink'>
                                    <TagLeftIcon as={FaGraduationCap} />
                                    <TagLabel>アカデミア</TagLabel>
                                </Tag>
                            ): "" }
                            { post.business ? (
                                <Tag size='md' variant='outline' colorScheme='pink'>
                                    <TagLeftIcon as={FaSuitcase} />
                                    <TagLabel>ビジネス</TagLabel>
                                </Tag>
                            ): "" }
                            { post.tech ? (
                                <Tag size='md' variant='outline' colorScheme='pink'>
                                    <TagLeftIcon as={FaCogs} />
                                    <TagLabel>テクノロジー</TagLabel>
                                </Tag>
                            ): "" }
                            { post.career ? (
                                <Tag size='md' variant='outline' colorScheme='pink'>
                                    <TagLeftIcon as={FaBook} />
                                    <TagLabel>キャリア</TagLabel>
                                </Tag>
                            ): "" }
                            { post.money ? (
                                <Tag size='md' variant='outline' colorScheme='pink'>
                                    <TagLeftIcon as={FaCommentsDollar} />
                                    <TagLabel>マネー</TagLabel>
                                </Tag>
                            ): "" }
                            { post.lifestyle ? (
                                <Tag size='md' variant='outline' colorScheme='pink'>
                                    <TagLeftIcon as={FaChair} />
                                    <TagLabel>ライフスタイル</TagLabel>
                                </Tag>
                            ): "" }
                            { post.culture ? (
                                <Tag size='md' variant='outline' colorScheme='pink'>
                                    <TagLeftIcon as={FaHeadphonesAlt} />
                                    <TagLabel>カルチャー</TagLabel>
                                </Tag>
                            ): "" }
                            <Spacer />
                            {/* 投稿詳細ページに、リセールでの出品可否を表示。*/}
                            { post.start !== null && todayDate >= startResale ?
                                <Box
                                    px={2}
                                    py={1}
                                    pointerEvents='none'
                                    cursor='unset'
                                    bg={"#DDFFDD"}
                                    color={'black'}
                                    align="center"
                                    size='sm'
                                    borderRadius={50}
                                    _focus={{ boxShadow: "none"}}
                                >
                                    <HStack>
                                        <CheckCircleIcon color='teal.400' />
                                        <Text fontSize='sm' >リセールが許可されています</Text>
                                    </HStack>
                                </Box>
                            : "" }
                            {/* リセール禁止 */}
                            { post.start === null ?
                                <Box
                                    px={2}
                                    py={1}
                                    pointerEvents='none'
                                    cursor='unset'
                                    bg='gray.300'
                                    color={'black'}
                                    align="center"
                                    size='sm'
                                    borderRadius={50}
                                    _focus={{ boxShadow: "none"}}
                                >
                                    <HStack>
                                        <Icon as={FaShieldAlt} color='brack' />
                                        <Text fontSize='sm' >リセールが許可されていません</Text>
                                    </HStack>
                                </Box>
                            : "" }
                            { todayDate < startResale ?
                                <Box
                                    px={2}
                                    py={1}
                                    pointerEvents='none'
                                    cursor='unset'
                                    bg='gray.300'
                                    color={'black'}
                                    align="center"
                                    size='sm'
                                    borderRadius={50}
                                    _focus={{ boxShadow: "none"}}
                                >
                                    <HStack>
                                        <Icon as={FaUnlockAlt} color='yellow.300' />
                                        <Text fontSize='sm' >{dayGaps}日後にリセールが許可されます</Text>
                                    </HStack>
                                </Box>
                            : "" }
                        </Flex>

                        {/* 購入していない時の表示 */}
                        <Box pt="20px" mx={{base: '0px', md: '100px'}}>
                            <div class="ql-snow"><div className="ql-editor" dangerouslySetInnerHTML={{__html: post.body}}/></div>
                            <Box h={10} />
                            <hr style={{ border: 'none', borderTop: '2px dashed' }} />
                            <VStack pt="20px">
                                { !post.is_login || (!post.is_purchase && post_info.price!==0) ? (
                                <>
                                    <HStack>
                                        <Text fontSize="sm" color="gray.600">この先を読むには......</Text>
                                        <Text fontSize="sm" color="gray.600">(約</Text>
                                        <Text fontWeight="bold" fontSize="sm" color="gray.600">{post.spy}</Text>
                                        <Text fontSize="sm" color="gray.600">文字)</Text>
                                    </HStack>
                                    <HStack>
                                        <Text fontSize="lg" >現在の価格 </Text>
                                        <Text fontSize="3xl" color="#CC5083" pb='4px' >{ post_info.price }</Text>
                                        <Text fontSize="lg" >円</Text>
                                    </HStack>
                                </>) : ("") }
                                {/* 購入ボタン */}
                                { post_info.remain===0 && !post.is_purchase ? (
                                    <>
                                        <Button
                                            pointerEvents='none'
                                            cursor='unset'
                                            width="300px"
                                            bg={"#E9B5D2"}
                                            color={'white'}
                                            variant='solid'
                                            _focus={{ boxShadow: "none"}}
                                        >売り切れ</Button>
                                        {/* 後で変更 */}
                                        <Flex w='300px' >
                                            <Spacer/>
                                            <Link as={ReachLink} to={'/m/s?q=' + post.title} _focus={{ boxShadow: "none"}}>
                                                <HStack>
                                                    <Text as='u' fontSize='sm'>
                                                        Nextswan リセールでさがす
                                                    </Text>
                                                    <Search2Icon color='#CC5083' w={4} h={4} />
                                                </HStack>
                                            </Link>
                                        </Flex>
                                    </>
                                ) : "" }
                                {/* { post_info.remain!==0 && !post.is_purchase && post_info.price!==0 && post.is_login ? (
                                    <>
                                        <Button
                                            width="300px"
                                            bg={"#CC5083"}
                                            color={'white'}
                                            _hover={{ opacity: 0.8 }}
                                            variant='solid'
                                            _focus={{ boxShadow: "none"}}
                                            onClick={ payment_reserve }
                                            >今すぐ購入する</Button>
                                        { post.start !== null &&
                                            <HStack>
                                                <Icon as={FaTicketAlt} color='#CC5083' w={6} h={6} />
                                                <Text fontSize="lg" >購入特典</Text>
                                                <Text fontSize='sm'>
                                                    特典として、<span class="marker_line_pink40">リセールチケット</span>がついています
                                                </Text>
                                                <Popover isLazy trigger="hover">
                                                    <PopoverTrigger>
                                                        <QuestionOutlineIcon
                                                            cursor='pointer'
                                                            color="#797979"
                                                            mx={2}
                                                        />
                                                    </PopoverTrigger>
                                                    <PopoverContent w='300px' align='center'>
                                                        <PopoverArrow />
                                                        <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                                                        <VStack>
                                                        <Text align='center' fontSize='sm' pt={2} ></Text>
                                                        <Text align='left' fontSize='sm' pt={2} w='240px'>このコンテンツはクリエイターがリセールを許可しています。そのため、あとでリセールチケットを使用して他のユーザーに販売できます。</Text>
                                                        <Text align='left' fontSize='xs' pt={2} pb={4}>
                                                            詳しくは
                                                            <Link
                                                                as={ReachLink}
                                                                to="/about"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                _focus={{ boxShadow: "none"}}
                                                            >
                                                                こちら
                                                            </Link>
                                                        </Text>
                                                        </VStack>
                                                    </PopoverContent>
                                                </Popover>
                                            </HStack>
                                        }
                                        { post.limit ? 
                                            <Flex w='300px' >
                                                <Spacer/>
                                                <Text fontSize='sm'>
                                                    残り
                                                </Text>
                                                <Text fontWeight='bold' fontSize='sm' color='#CC5083'>
                                                    {post_info.remain}
                                                </Text>
                                                <Text fontSize='sm'>
                                                    /{post.limit}部
                                                </Text>
                                            </Flex>
                                        :"" }
                                        <Box h='20px'/>
                                    </>
                                ) : ("")} */}
                                {/* ログインしていない場合はこちらのボタン */}
                                { post_info.remain!==0 && !post.is_login ? (
                                    <>
                                        <Button
                                            width="300px"
                                            bg={"#CC5083"}
                                            color={'white'}
                                            _hover={{ opacity: 0.8 }}
                                            variant='solid'
                                            _focus={{ boxShadow: "none"}}
                                            onClick={need_login}
                                        >ログインしてから続きを読む
                                        </Button>
                                        { post.start !== null &&
                                            <HStack>
                                                {/* <Icon as={FaTicketAlt} color='#CC5083' w={6} h={6} /> */}
                                                {/* <Text fontSize="lg" >購入特典</Text> */}
                                                <Text fontSize='sm'>
                                                    特典として、<span class="marker_line_pink40">リセールチケット</span>がついています
                                                </Text>
                                                <Popover isLazy trigger="hover">
                                                    <PopoverTrigger>
                                                        <QuestionOutlineIcon
                                                            cursor='pointer'
                                                            color="#797979"
                                                            mx={2}
                                                        />
                                                    </PopoverTrigger>
                                                    <PopoverContent w='300px' align='center'>
                                                        <PopoverArrow />
                                                        <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                                                        <VStack>
                                                        <Text align='center' fontSize='sm' pt={2} ></Text>
                                                        <Text align='left' fontSize='sm' pt={2} w='240px'>このコンテンツはクリエイターがリセールを許可しています。そのため、あとでリセールチケットを使用して他のユーザーに販売できます。</Text>
                                                        <Text align='left' fontSize='xs' pt={2} pb={4}>
                                                            詳しくは
                                                            <Link
                                                                as={ReachLink}
                                                                to="/about"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                _focus={{ boxShadow: "none"}}
                                                            >
                                                                こちら
                                                            </Link>
                                                        </Text>
                                                        </VStack>
                                                    </PopoverContent>
                                                </Popover>
                                            </HStack>
                                        }
                                        { post.limit ? 
                                            <Flex w='300px' >
                                                <Spacer/>
                                                <Text fontSize='sm'>
                                                    残り
                                                </Text>
                                                <Text fontWeight='bold' fontSize='sm' color='#CC5083'>
                                                    {post_info.remain}
                                                </Text>
                                                <Text fontSize='sm'>
                                                    /{post.limit}部
                                                </Text>
                                            </Flex>
                                        :"" }
                                    </>
                                ) : ("")}

                                {/* 価格変動のグラフ */}
                                <Box h={10}/>
                                { isMobile ?  <LineChartMobile move={post_info.move}  /> : <LineChart move={post_info.move} />}
                            </VStack>  
                            { post.is_purchase &&
                                <Flex>
                                    <Spacer/>
                                    <HStack>
                                        <Text size="sm" pt={1}>現在の価格:</Text>
                                        <ShowPriceBlack price={post_info.price}/>
                                    </HStack>
                                </Flex>
                            }
                            <Box h='50px' />
                            {/* 著者プロフィール */}
                            <HStack as={ReachLink} to={'/' + post.userid} _focus={{ boxShadow: "none"}}>
                                <Avatar size='md' src={ profile.icon } />
                                <Stack spacing="5px" pl={3}>
                                    <Text fontSize="md" flexWrap='wrap' noOfLines={2}>
                                        {profile.username}
                                    </Text>
                                    <Text fontSize="sm" noOfLines={2} color="gray.600">
                                        {profile.description}
                                    </Text>
                                </Stack>
                            </HStack>
                            <Box h='100px' />
                            {/* aboutページ */}
                            <VStack pt="20px">
                                <Heading as='h2' size='xl' pt='30px' pb='50px'>
                                    Nextswanとは？
                                </Heading>
                                <AboutPoint/>
                                <AboutStep/>
                            </VStack>
                        </Box>
                    </Stack> 
                </Center>

                {/* いいねボタンなどの固定 */}
                {isMobile ? 
                    <>
                        {movie.s3url &&
                            <Box style={{ position: 'fixed', top: '65px', right: '20px'}} borderRadius='50%' bg='white' shadow='xl' w={14} h={14} p={2} >
                                <Link href={movie.s3url} target="_blank" rel="noreferrer" _focus={{ boxShadow: "none"}}>
                                {/* <Link _focus={{ boxShadow: "none"}} onClick={openVideo}> */}
                                    <Icon as={FaFilm} color='gray.500' w={10} h={10} />
                                </Link>
                            </Box>
                        }
                        <Center>
                            <HStack style={{ position: 'fixed', bottom: '30px'}} spacing={6} >
                                {/* <Icon as={FaLink} color='#E9B5D2' w={9} h={9} onClick={() => copyTextToClipboard('https://meltly.co.jp/')} /> */}
                                <Box borderRadius='50%' bg='white' shadow='lg' w={12} h={12} p={2} >
                                    <Link href={"https://twitter.com/share?text=" + encodeURIComponent(post.title) + "&url=https://nextswan.com/p/" + postid + "&hashtags=Nextswan"}  onClick={send_share}>
                                        <Icon as={FaTwitter} color='gray.400' pb={1} pr={1} w={9} h={9} />
                                    </Link>
                                </Box>
                                <Box borderRadius='50%' bg='white' shadow='lg' w={12} h={12} p={2} >
                                    <Link href={"https://social-plugins.line.me/lineit/share?url=https://nextswan.com/p/" + postid}  onClick={send_share}>
                                        <Icon as={FaLine} color='gray.400' pb={1} pr={1} w={9} h={9} />
                                    </Link>
                                </Box>
                                <Box borderRadius='50%' bg='white' shadow='lg' w={12} h={12} p={2} >
                                    <Icon as={FaRegCopy} cursor='pointer' color='gray.400' pt={1} pl={1} w={7} h={7} onClick={() => copyTextToClipboard('https://nextswan.com/p/' + postid)}  />
                                </Box>
                                {/* <Icon as={FaFacebook} color='#E9B5D2' w={9} h={9} /> */}
                                <Spacer />
                                <Spacer />
                                <Box borderRadius='50%' bg='white' shadow='lg' w={12} h={12} p={2} >
                                    {likeAlert()}
                                </Box>
                            </HStack>
                        </Center>
                    </>
                :
                    <VStack position='fixed' right={{base: '30px', lg: '80px', xl: '160px'}} bottom='140px' /*style={{ position: 'fixed', right: '100px', bottom: '120px'}}*/ >
                        {movie.s3url &&
                            <VStack pb="24px">
                                {/* <Link _focus={{ boxShadow: "none"}} onClick={openVideo}> */}
                                <Link href={movie.s3url} target="_blank" rel="noreferrer" _focus={{ boxShadow: "none"}}>
                                    <Icon as={FaFilm} color='gray.500' w={10} h={10} />
                                </Link>
                            </VStack>
                        }
                        <VStack spacing={4}>
                            <Icon as={FaLink} cursor='pointer' color='gray.400' w={7} h={7} mb={1} onClick={() => copyTextToClipboard('https://nextswan.com/p/' + postid)} />
                            <Link href={"https://twitter.com/share?text=" + encodeURIComponent(post.title) + "&url=https://nextswan.com/p/" + postid + "&hashtags=Nextswan"} target="_blank" rel="noreferrer" _focus={{ boxShadow: "none"}} onClick={send_share}>
                                <Icon as={FaTwitter} color='gray.400' w={7} h={7} />
                            </Link>
                            <Link href={"https://social-plugins.line.me/lineit/share?url=https://nextswan.com/p/" + postid} target="_blank" rel="noreferrer" _focus={{ boxShadow: "none"}} onClick={send_share}>
                                <Icon as={FaLine} color='gray.400' w={7} h={7} />
                            </Link>
                            <Link href={'https://www.facebook.com/share.php?u=https://nextswan.com/p/' + postid} target="_blank" rel="noreferrer" _focus={{ boxShadow: "none"}} onClick={send_share}>
                                <Icon as={FaFacebook} color='gray.400' w={7} h={7} />
                            </Link>
                        </VStack>
                        <VStack pt="16px">
                            {likeAlert()}    
                            <Text fontSize='xl'>{ post_info.favorite }</Text>
                        </VStack>
                    </VStack>
                }
            </>
        )
    }
}