// 出品する記事を選ぶ画面。
import { AspectRatio, Box, Center, Flex, Heading, HStack, Icon, Image, Spacer, Spinner, Stack, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import React, { memo, useEffect } from 'react';
import { FaRegHeart } from 'react-icons/fa';
import { Link as ReachLink } from 'react-router-dom';
import axios from 'axios'
import { ShowTime } from "../../components/atoms/ShowTime";
import { MarketHeaderSub } from "./marketConponents/MarketHeader";
import { HeadBlock } from "../../components/organisms/layout/HeadBlock";

// 記事のbox。
const ChooseListingBox = memo(() => {
    let Posts = [];
    const [posts, setPosts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const isMobile = useBreakpointValue({ base: true, md: false });

    // 出品できる記事を取ってくる。このuseEffectはNextswan本体のパクリなので変更お願いします
    useEffect(() => {
        setLoading(true);
        const load_post = async () => {
            await axios.get('/api/list/paid/', { withCredentials: true })
                .then(res => {
                    if (res.request.status === 200) {
                        // console.log(res.data);
                        for (let i=0;i<res.data.count;i++) {
                            let data = res['data'][i]
                            // console.log(data);
                            if (typeof data === "undefined") {
                                continue;
                            }
                            Posts.push({
                                postId: data.id,
                                title: data.title,
                                date: data.create_time,
                                imageUrl: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + data.id + "." + (data.thumbnail_url ? data.thumbnail_url : "png"),
                                likescount: data.favorite,
                                icon: (data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + data.uuid + data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                                userName: data.username,
                            });
                        }       
                    }
                    setPosts(Posts);
                })
                .finally(() => {
                    setLoading(false)
                    // console.log(posts);
                });
            }
        load_post()
    }, []);
    
    if (loading) {
        return(
        <Center h="100vh">
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='#E67D5A'
                size='xl'
            />
        </Center>
        )
    } else {
        return (
            posts.map((post, key) => (
                <HStack as={ReachLink} to={'/m/' + post.postId + '/listing'} pb={3} key={key} >
                    <Box position={"relative"} w={{base: '34vw', md:'228px'}} ml={5} mr={2} >
                        <AspectRatio ratio={16 / 9}>
                            <Image
                                borderRadius={4}
                                src={post.imageUrl}
                                alt=""
                            />
                        </AspectRatio>
                    </Box>
                    <Box w={{base: '46vw', md:'35vw'}} mr={5}>
                        <Stack lineHeight='normal'>
                            <Box>
                                <Text fontSize="sm" fontWeight="bold" textAlign={"left"} noOfLines={2}>
                                    {post.title}
                                </Text>
                            </Box>
                            { isMobile ? "" : <Spacer/>}
                            <HStack>
                                <Image src={post.icon} alt="" borderRadius="50%" width={{ base: 4, md: 6 }} height={{ base: 4, md: 6 }} style={{ objectFit: 'cover'}} ml={1} />
                                <Text fontSize="sm" fontWeight="gray" textAlign={"left"} mt={-4}
                                    >{post.userName}
                                </Text>
                            </HStack>
                            { isMobile ? "" : <Spacer/>}
                            <HStack>
                                <Icon as={FaRegHeart} color={'#CC5083'} w={4} h={4} ml={1} />
                                <Text color={'#CC5083'} pr={1} >{post.likescount}</Text>
                                <ShowTime date0 = {post.date} />
                            </HStack>
                        </Stack>
                    </Box>
                </HStack>
            ))
        );
    };
});

// ページ全体のUI
export default function ChooseListing () {
    const isMobile = useBreakpointValue({ base: true, md: false })
    const [posts, setPosts] = React.useState([]);
    // const [loading, setLoading] = React.useState(false);
    return(
        <Box >
            <HeadBlock title="出品できるコンテンツ一覧"/>
            <MarketHeaderSub />
            <Flex textAlign={'center'} mx={{base: '20px', md: '0px'}}>
                <Spacer mt={0}/>
                <VStack alignContent={'center'}>
                    <Box h='30px'/>
                    { isMobile ?
                        <>
                        <Heading as="h1" size="lg" textAlign="center" >出品するコンテンツを</Heading>
                        <Heading as="h1" size="lg" textAlign="center" >選んでください。</Heading>
                        <Box h={2}/>
                        <Text as="h1" size="2xl" textAlign="center" >(リセールが許可されているコンテンツのみ</Text>
                        <Text as="h1" size="2xl" textAlign="center" >出品できます)</Text>
                        </>
                    :
                        <>
                        <Heading as="h1" size="lg" textAlign="center" >　出品するコンテンツを選んでください。</Heading>
                        <Text as="h1" size="2xl" textAlign="center" >(リセールが許可されているコンテンツのみ出品できます)</Text>
                        </>
                    }
                    <Box h='30px'/>
                    <ChooseListingBox posts={posts}/>
                </VStack>
                <Spacer mt={0}/>
            </Flex>
        </Box>
    );
    // }
}