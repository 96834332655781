import { Button, Box, FormControl, FormLabel, Icon, Heading, Text, Input, Textarea, Stack, HStack, Flex, Avatar, Spacer } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import axios from 'axios';
import { HeaderPlane } from '../../components/organisms/layout/HeaderPlane';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaInstagram, FaFacebook, FaTwitter, FaLink } from 'react-icons/fa';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
// import ReactCrop from 'react-image-crop/';
// import 'react-image-crop/dist/ReactCrop.css';

const ProfileEdit = (props) => {

    const [loading, setLoading] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [userId, setUserId] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [twitterLink, setTwitterLink] = React.useState('');
    const [instagramLink, setInstagramLink] = React.useState('');
    const [facebookLink, setFacebookLink] = React.useState('');
    const [otherLink, setOtherLink] = React.useState('');
    // ユーザーIDのエラーを炙り出す変数
    // availableIdがtrueなら、入力されたIDは使用可能
    const [ availableId, setAvailableId ] = React.useState(true);
    // invalidCharacterがtrueなら、そもそも文字列が使用不可（重複かどうかに関わらず）
    const [ invalidCharacter, setInvalidCharacter] = React.useState(false);

    // プロフィールアイコンの表示
    const [ isHover, setIsHover] = React.useState(false);
    const [ avatar, setAvatar ] = React.useState("https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png");    



    const change_username = (e) => setUsername(() => e.target.value);
    const change_description = (e) => setDescription(() => e.target.value);
    const change_twitterLink = (e) => setTwitterLink(() => e.target.value);
    const change_instagramLink = (e) => setInstagramLink(() => e.target.value);
    const change_facebookLink = (e) => setFacebookLink(() => e.target.value);
    const change_otherLink = (e) => setOtherLink(() => e.target.value);

    const location = useLocation();

    useEffect(() => {
        const access_db = async () => {
            const userid = location.pathname.split("/")[1];
            const data = new FormData();
            data.append("user_id", userid);
            
            await axios.post('/api/user_profile/', data, { withCredentials: true})
                .then(res => {
                    if (res.request.status === 200) {
                        if (res.data.error) {
                            alert("ユーザが存在しません");
                            return 0;
                        }
                        setUsername(res.data.username);
                        setUserId(res.data.user_id);
                        setDescription(res.data.introduction);
                        setTwitterLink(res.data.SNS_Twitter);
                        setInstagramLink(res.data.SNS_Instagram);
                        setFacebookLink(res.data.SNS_Facebook);
                        setOtherLink(res.data.SNS_Other);
                        if (res.data.icon) setAvatar("https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/"+res.data.user_uuid+res.data.icon+"?"+new Date().getTime());
                    }
                });
            }
        access_db()
    }, [])

    const navigate = useNavigate()

    const send_data = async () => {
        if (availableId && !invalidCharacter){
            localStorage.setItem('myuserid', userId);

            setLoading(true);
            const data = new FormData();
            data.append("username", username);
            data.append("userid", userId);
            data.append("introduction", description);
            data.append("SNS_Twitter", twitterLink);
            data.append("SNS_Instagram", instagramLink);
            data.append("SNS_Facebook", facebookLink);
            data.append("SNS_Other", otherLink);

            await axios.post('/api/edit_mypage/', data, { withCredentials: true })
            .then(res => {
                if (res.request.status === 200) {
                    navigate('/' + localStorage.getItem('myuserid') +'/');
                    
                };
            });
        }
    }


    const handleFileChange = async (e) => {
        const file = new FormData()
        file.append("icon", e.target.files[0]);
        if (e.target.files.length !== 0) {
            setAvatar(URL.createObjectURL(e.target.files[0]))
            await axios.post('/api/upload_icon/', file, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
        }
    }

    const userNameLength = username.length;
    const bioLength = description.length;


    const change_userid = (e) => {
        let userid = e.target.value
        setUserId(() => userid);
        let userid_data = new FormData();
        userid_data.append("userid", userid);
        axios.post("/api/check_userid/", userid_data, { withCredentials: true})
        .then(res => {
            setAvailableId(res.data.availableId);
            setInvalidCharacter(res.data.invalidCharacter);
        });
    }

    return (
        <>
            <HeaderPlane />
            <Box pt='10px' pb='10px' mx={{base:'40px', md:'100px', lg: '200px', xl: '300px'}}>
                <Stack spacing={6}>
                    <Flex>
                        <Heading size='lg'>プロフィール編集</Heading>
                        <Spacer />
                        { loading ? (
                        <Button
                            bg={"#CC5083"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            onClick={send_data}
                            _focus={{ boxShadow: "none"}}
                            isLoading
                        >保存
                        </Button>
                        ) : (
                        <Button
                            bg={"#CC5083"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            onClick={send_data}
                            _focus={{ boxShadow: "none"}}
                        >保存
                        </Button>
                        )}
                    </Flex>
                    <Stack>
                        <Text size='md'>プロフィールアイコン</Text>
                        <label>
                            <Avatar
                                size='xl'
                                src={avatar}
                                style={{boxShadow: isHover ? '1px 1px 5px 1px #000' : 'none', transform: isHover ? 'translateY(-2px)' : 'none' }}
                                onMouseEnter={() => setIsHover(true)}
                                onMouseLeave={() => setIsHover(false)}
                                cursor='pointer' />
                            <Input type='file' accept='image/*' style={{ display: 'none' }} onChange={handleFileChange} />
                        </label>
                    </Stack>
                    {/* to二宮くん: onchangeを使ってそれぞれstate保存いるかも */}
                    <FormControl>
                        <FormLabel htmlFor="username">ユーザーネーム</FormLabel>
                        <Input id="username" defaultValue={username} onChange={change_username} focusBorderColor='pink.500'/>
                        { userNameLength > 64 ? (
                            <HStack>
                                <WarningIcon color='red.500'/>
                                <Text color='red.500'>ユーザーネームは64文字以内にしてください。</Text>
                            </HStack>
                        ) : ('')} 
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor="userid">ユーザーID</FormLabel>
                        <Input id="userid" defaultValue={userId} onChange={change_userid} focusBorderColor='pink.500'/>
                        {/* ユーザーIDのエラーを返す。112行目参照 */}
                        { availableId ? !invalidCharacter ? (
                            // 重複してないかつ無効文字でない
                            <HStack>
                                <CheckCircleIcon color='teal.400'/>
                                <Text color='teal.400'>このIDは使用可能です。</Text>
                            </HStack>
                        ) : (
                            // 重複してないかつ無効文字である
                            <HStack>
                                <WarningIcon color='red.500'/>
                                <Text color='red.500'>この文字列は使用できません。他の文字列をお試しください。</Text>
                            </HStack>
                        ) : (
                            // 重複している（無効文字かどうかは関係ない）
                            <HStack>
                                <WarningIcon color='red.500'/>
                                <Text color='red.500'>このユーザーIDは使用できません。他の文字列をお試しください。</Text>
                            </HStack>
                        )
                        }
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="bio">プロフィール文</FormLabel>
                        <Textarea id="bio" defaultValue={description} onChange={change_description} focusBorderColor='pink.500'/>
                        { bioLength <= 200 ? ( 
                            <Text align='right' color='gray'>{bioLength}/200</Text>
                        ) : (
                            <Text align='right' color='red'>プロフィール文は200文字以内にしてください。{bioLength}/200</Text>
                        )}
                    </FormControl>
                    <Heading size='md' textAlign='left'>SNSリンク(idではなくリンクを記入してください)</Heading>
                    <HStack spacing={5}>
                        <Icon as={FaTwitter} w={6} h={6} color='#606060' />
                        <Input defaultValue={twitterLink} onChange={change_twitterLink} focusBorderColor='pink.500'/>
                    </HStack>
                    <HStack spacing={5}>
                        <Icon as={FaInstagram} w={6} h={6} color='#606060' />
                        <Input defaultValue={instagramLink} onChange={change_instagramLink} focusBorderColor='pink.500'/>
                    </HStack>
                    <HStack spacing={5}>
                        <Icon as={FaFacebook} w={6} h={6} color='#606060' />
                        <Input defaultValue={facebookLink} onChange={change_facebookLink} focusBorderColor='pink.500'/>
                    </HStack>
                    <HStack spacing={5}>
                        <Icon as={FaLink} w={6} h={6} color='#606060' />
                        <Input defaultValue={otherLink} onChange={change_otherLink} focusBorderColor='pink.500'/>
                    </HStack>
                </Stack>
            </Box>
        </>
    )
}

export default ProfileEdit;