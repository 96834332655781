import React from 'react'
import { Helmet } from 'react-helmet-async';

export const HeadBlock = (props) => {
    const { title, description } = props;

    return (
        <Helmet>
            <title>{title ? title : 'Nextswan'}</title>
            <meta name="description" content={description ? description : "クリエイターとユーザーの双方に利益を還元するリセーラブルコンテンツ投稿プラットフォーム。良いコンテンツが正当に評価されて流通し続ける場所です。"} />
        </Helmet>
    )
}