import React, { useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Button, FormControl, FormLabel, Input, InputGroup, InputRightElement, Spacer, VStack, HStack, Text } from "@chakra-ui/react";
import { HeaderPlane } from '../../../components/organisms/layout/HeaderPlane';
import { CheckCircleIcon, ViewIcon, ViewOffIcon, WarningIcon } from '@chakra-ui/icons';

const PasswordChange = () => {
    const [loading, setLoading] = React.useState(false);
    
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const handleClick = () => setShow(!show);
    const handleClick1 = () => setShow1(!show1);
    const navigate = useNavigate();
    const [password, setPassword] = React.useState('');
    const [password1, setPassword1] = React.useState('');
    const [password2, setPassword2] = React.useState('');
    const [next, setNext] = React.useState('');
    
    const [availablePassword, setAvailablePassword] = React.useState({
        passwordLength: false, /*最低8文字*/
        common: false, /*よく使われる*/
        onlyNumber: false, /*数字のみ*/
        familiarEmail: false /*email(&ユーザー名)と似ている*/
    })
    const passwordchange = (e) => {
        setPassword(() => e.target.value)
    }
    const passwordchange1 = (e) => {
        let password = e.target.value
        setAvailablePassword({
        passwordLength: password.length >= 8,
        common: true,
        onlyNumber: isNaN(password),
        familiarEmail: true,
        });
        setPassword1(() => e.target.value);
    }

    const enterClick = (e) => {
        if (e.key === 'Enter') {
            send_data()
        }
    }

    const send_data = async () => {
        setLoading(true);
        const data = new FormData();
        data.append("password", password1);

        await axios.post('/api/login/', data, { withCredentials: true })
        .then(res => {
            if (res.request.status === 200) {
                // console.log(res.data);
                // mypageにuserid問い合わせ
                axios.get('/api/mypage/', {  withCredentials: true})
                .then(res => {
                    // console.log(res.data);
                    if (res.request.status === 200) {
                        localStorage.setItem('myuserid', res.data.userid);
                        if (next==='signup') navigate('/');
                        else if (next) navigate('/' + next + '/');
                        else navigate('/');
                    };
                });
            };
        })
        .catch(res => {
            if (res.request.status === 400) {
                alert('このパスワードは無効です。');
            }
        })
        .finally(() => setLoading(false));
    }

    return(
        <>
            <HeaderPlane />
            <Flex mt={10} justify="center" height="flex" size="full">
                <VStack spacing={6} py={4} px={10} align='stretch' w={450}>
                    <Heading as="h1" size="lg" textAlign="center">
                        パスワードの変更
                    </Heading>
                    <FormControl isRequired>
                        <FormLabel htmlFor='password'>現在のパスワード</FormLabel>
                        <InputGroup>
                            <Input id='password' type={show ? 'text' : 'password'} onChange={passwordchange} onKeyPress={(e) => enterClick(e)} value={password} focusBorderColor='#CC5083'/>
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick} _focus={{ boxShadow: "none"}}>
                                    {show ? <ViewIcon /> : <ViewOffIcon /> }
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor='password'>新しいパスワード</FormLabel>
                        <InputGroup>
                            <Input id='password' type={show1 ? 'text' : 'password'} onChange={passwordchange1} onKeyPress={(e) => enterClick(e)} value={password1} focusBorderColor='#CC5083'/>
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick1} _focus={{ boxShadow: "none"}}>
                                    {show1 ? <ViewIcon /> : <ViewOffIcon /> }
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    { availablePassword.passwordLength && availablePassword.common && availablePassword.onlyNumber && availablePassword.familiarEmail
                        ? (
                            <HStack>
                                <CheckCircleIcon color='teal.400'/>
                                <Text color='teal.400'>このパスワードは使用可能です。</Text>
                            </HStack>
                        ) : ""
                    }
                    { password1 !== '' && !(availablePassword.passwordLength && availablePassword.common && availablePassword.onlyNumber && availablePassword.familiarEmail)
                        ? (
                            <HStack>
                                <WarningIcon color='red.500'/>
                                <Text color='red.500'>このパスワードは使用できません。</Text>
                            </HStack>
                        ) : ""
                    }
                    <Spacer />
                    { loading ? (
                        <Button
                            bg={"#CC5083"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            variant='solid'
                            onClick={send_data}
                            _focus={{ boxShadow: "none"}}
                            disabled={password === "" || password1 === "" || password2 === ""}
                            isLoading
                        >パスワードを変更する</Button>
                    ) : (
                        <Button
                            bg={"#CC5083"}
                            color={'white'}
                            _hover={{ opacity: 0.8 }}
                            variant='solid'
                            onClick={send_data}
                            _focus={{ boxShadow: "none"}}
                            disabled={password === "" || password1 === "" || password2 === ""}
                        >パスワードを変更する</Button>
                    )}
                </VStack>
            </Flex>
        </>
    )
};
export default PasswordChange