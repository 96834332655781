// market出品されたコンテンツの詳細ページ。購入画面などの役割も
import { AspectRatio, Box, Button, Center, Flex, Heading, HStack, Image, VStack, Text, Avatar, Spacer, Stack, Link, Spinner, List, ListItem, Divider, FormControl, Textarea, TableContainer, Table, Tbody, Tr, Td, useBreakpointValue, useToast, Icon, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton } from '@chakra-ui/react';
import React, { useState } from 'react';
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link as ReachLink, useNavigate, useLocation } from "react-router-dom";
import { FaUserTie, FaRegStar } from 'react-icons/fa';
import Modal from 'react-modal';
import ServiceLogo from '../../images/nextswanMarketLogo.png';
import { FaTwitter, FaLine, FaLink, FaFacebook, FaRegCopy } from 'react-icons/fa';
import { MarketHeader } from './marketConponents/MarketHeader';
import MarketCheckoutForm from './marketSettlements/MarketCheckoutForm';
import { ShowTime } from '../../components/atoms/ShowTime';
import { FaPaperPlane } from "react-icons/fa";
import { ShowDate } from '../../components/atoms/ShowDate';
import { HeadBlock } from '../../components/organisms/layout/HeadBlock';
import soldOutImage from "../../images/soldout.png";
import { AboutPoint, AboutStep } from '../about/About';
import { QuestionOutlineIcon, Search2Icon } from '@chakra-ui/icons';
import { ShowPriceGray, ShowPriceOrange } from '../../components/atoms/ShowPriceS';

Modal.setAppElement('#root')
const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
};

// Stripeのテスト用の公開可能キー
// const stripePromise = loadStripe("pk_test_51KjxcUFwZPjr5Y2qpaIzTHh1KmOrefvYgt3V9nNnIiFpYzgdxJMOnVwQOaaloMQrXOf5eWeMneYJxyh1xlfqdrUI009RnkA5jr");
const stripePromise = loadStripe("pk_live_51KjxcUFwZPjr5Y2ql0ByH9anQkshj4BsySMgXTZiRNT0OIjP2MNcKh6pse7YUOnh7M7AclU1t2Un1BWM2TxwcC0J00rXzEZuaN");

export default function MarketPostDetail (props) {
    // モーダルの開閉状態
    const [isModal, setIsModal] = React.useState(false);
    const [marketid, setMarketid] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [post, setPost] = React.useState({
        postid: '',
        thumbnail_url: '',
        title: '',
        icon: '',
        userid: '',
        username: '',
        favorite: '',
        time: '',
        price: '',
        market_price: '',
        is_login: false,
        is_me: true,
        soldOut: false,
    });
    const [bookmark, setBookmark] = React.useState(false);
    const isMobile = useBreakpointValue({ base: true, md: false });
    const titleSize = useBreakpointValue({ base: 'md', lg: 'lg' });
    const [comments, setComments] = React.useState([]);
    const [isComment, setIsComment] = React.useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        setLoading(true);
        let market_id = location.pathname.split("/")[2]
        setMarketid(market_id);
        const load_post = async () => {
            let market_data = new FormData();
            market_data.append("market_id", market_id);
            await axios.post("/api/market/detail/", market_data, { withCredentials: true })
            .then(res => {
            if (res.request.status === 200) {
                // console.log(res.data);
                setPost({
                    postid: res.data.post_id,
                    title: res.data.title,
                    icon: (res.data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + res.data.uuid + res.data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                    userid: res.data.userid,
                    username: res.data.username,
                    favorite: res.data.favorite,
                    time: res.data.time,
                    price: res.data.price,
                    market_price: res.data.market_price,
                    thumbnail_url: "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/thumnail/" + res.data.post_id + "." + (res.data.thumbnail_url ? res.data.thumbnail_url : "png"),
                    is_login: res.data.is_login,
                    stripe: res.data.stripe,
                    is_me: res.data.is_me,
                    soldOut: res.data.soldOut,
                    remain: res.data.remain,
                    limit: res.data.limit,
                    purchase: res.data.is_purchase,
                });
                setBookmark(res.data.keep);
            }
            })
            .finally(() => setLoading(false));
        }
        const load_comment = async () => {
            let comment_data = new FormData();
            comment_data.append("market_id", market_id);
            await axios.post("/api/market/get_comment/", comment_data, {withCredentials: true})
            .then(res => {
                let Comments = [];
                for (let i=0;i<res.data.count;i++) {
                    let data = res['data'][i]
                    setIsComment(res.data.count);
                    // console.log(data);
                Comments.push({
                        isSeller: data.is_seller,
                        userName: data.username,
                        message: data.body,
                        created_at: data.time,
                        icon: (data.icon ? "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/" + data.uuid + data.icon : "https://nextswan-static.s3.ap-northeast-1.amazonaws.com/img/user_icon/default.png"),
                    });
                }
                setComments(Comments);
            });
        }
        load_post();
        load_comment();
    }, []);

    const need_login = () => {
        navigate('/login?next=m/' + marketid);
    }

    const payment_reserve = async () => {
        setIsModal(true);
    }

    //コメント入力時のinput操作
    const [input, setInput] = useState('')
    const handleInputChange = (e) => {
        setInput(e.target.value);
        // console.log(comments);
    }
    const send_comment = async () => {
        let comment_data = new FormData()
        comment_data.append("market_id", marketid);
        comment_data.append("body", input);
        await axios.post("/api/market/send_comment/", comment_data, {withCredentials: true})
        .then(res => {
            window.location.reload();
        });
    }

    const send_keep = async () => {
        let keep_data = new FormData();
        keep_data.append("market_id", marketid);
        await axios.post("/api/market/keep/", keep_data, {withCredentials: true})
        .then(res => {
            // if (res.data.cancel) alert('お気に入りから削除しました');
            // if (res.data.register) alert('お気に入りに追加しました');
        });
    }

    const send_share = async () => {
        const share_data = new FormData();
        share_data.append("market_id", marketid);
        await axios.post("/api/share/", share_data, { withCredentials: true });
    }

    const toast = useToast()
    const copyTextToClipboard = (text) => {
        navigator.clipboard.writeText(text)
        .then(function() {
            // console.log('Async: Copying to clipboard was successful!');
            toast({
                title: "コピーしました",
                duration: 5000,
                status: 'success',
                isClosable: true
            })
        }, function(err) {
            // console.error('Async: Could not copy text: ', err);
        });
    }

    if (loading) {
        return(
            <>
                <MarketHeader />
                <Center h="100vh">
                    <Spinner 
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='#E67D5A'
                    size='xl'
                    />
                </Center>
            </>
        )
    }else{
        return (
            <>
                <HeadBlock title={post.title + " - Nextswan リセール"}/>
                <MarketHeader />
                <hr />

                {/* 決済モーダル */}
                <Modal style={customStyles} isOpen={isModal} onRequestClose={() => setIsModal(false)}>
                    <Center>
                        <VStack spacing="15px">
                            <Image src={ServiceLogo} width="300px" />
                            <Elements stripe={stripePromise}>
                                <MarketCheckoutForm price={post.market_price} market_id={marketid} saved={post.stripe}/>
                            </Elements>
                        </VStack>
                    </Center>
                </Modal>
                
                {/* 中央のコンテンツ */}
                {/* <Flex> */}
                {/* { isMobile ? "" : <Flex flex='24%'/> } */}
                <VStack>
                    <Flex pt={5} justify="center" mx={{base: '20px', md: '100px'}}>
                    { !post.soldOut ?
                        <AspectRatio as={ReachLink} to={'/p/' + post.postid} _focus={{ boxShadow: "none"}} w={{base: '90vw', md: '640px'}} ratio={16 / 9}>
                            <Image src={post.thumbnail_url} style={{ objectFit: 'cover', width: '100%' }} />
                        </AspectRatio>
                    :
                        <Box as={ReachLink} to={'/p/' + post.postid} textAlign="center" position={"relative"}>
                            <AspectRatio w={{base: "95vw", md: "640px"}} ratio={16 / 9} >
                                <Image
                                    src={post.thumbnail_url}
                                    style={{ objectFit: 'cover', width: '100%' }}
                                    filter='grayscale(100%)'
                                />
                            </AspectRatio>
                            <Image
                                src={soldOutImage}
                                position={'absolute'}
                                bottom={8}
                            />
                        </Box>
                    }
                    </Flex>
                    <Center pt="30px" mx={{base: '0px', md: '100px'}}>
                        <Stack w={{base: "95vw", md: '700px'}}>
                            <Link as={ReachLink} to={'/p/' + post.postid} _focus={{ boxShadow: "none"}}>
                                <Heading size={titleSize} flexWrap='wrap' >{ post.title }</Heading>
                            </Link>
                            <Flex pt="8px" align="center">
                                <Avatar as={FaUserTie} p={1} mt={2} w={9} h={9} bg='#E67D5A' color='white' />
                                <Text size="lg" mx={3} pt={2} noOfLines={2}>出品者は匿名です</Text>
                                <Spacer/>
                                <Flex>
                                    { bookmark ? (
                                        <Avatar
                                            as={ FaRegStar }
                                            size="sm"
                                            p={1}
                                            color='white'
                                            bgColor='#E67D5A'
                                            cursor='pointer'
                                            _hover={{ opacity: 0.8 }}
                                            //お気に入りに保存のメソッド
                                            onClick={() => {
                                                setBookmark(!bookmark);
                                                send_keep();
                                            }}
                                        />
                                    ) : (
                                        <Avatar
                                            as={ FaRegStar }
                                            size="sm"
                                            p={1}
                                            color='#CCC'
                                            bgColor='white'
                                            cursor='pointer'
                                            _hover={{ opacity: 0.8 }}
                                            //お気に入りに保存のメソッド
                                            onClick={() => {
                                                setBookmark(!bookmark);
                                                send_keep();
                                            }}
                                        />
                                    )}
                                </Flex>
                            </Flex>
                            <Box h={2} />
                            <Flex left={0}>
                                <Spacer/>
                                <Text color='gray.600' fontSize='sm'>出品時間:</Text>
                                { post.time ? <ShowDate time = {post.time} pl={3}/> : ('') }
                            </Flex>
                            <TableContainer pt={4}>
                                <Table variant='simple' overflowX='auto' flexWrap='nowrap'>
                                    <Tbody overflowX='auto' >
                                        <Tr>
                                            <Td maxW='240px' overflow='auto' flexWrap='nowrap'>コンテンツの著者</Td>
                                            <Td>
                                                <Link as={ReachLink} to={'/' + post.userid} _focus={{ boxShadow: "none"}}>
                                                    <HStack>
                                                        <Avatar size="xs" w={10} h={10} src={ post.icon } mt={2} />
                                                        <Text maxW='360px' size="lg" mx={3} pt={2} noOfLines={2} >{post.username}</Text>
                                                    </HStack>
                                                </Link>
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td maxW='240px' overflow='auto' flexWrap='nowrap'>Nextswanでのいいね数</Td>
                                            <Td>{post.favorite}</Td>
                                        </Tr>
                                        {/* ここにpost.remainが必要 */}
                                        { post.limit!==null ?
                                        <Tr>
                                            <Td maxW='240px' overflow='auto' flexWrap='nowrap'>Nextswanでの販売数上限</Td>
                                            <Td>{post.limit}</Td>
                                        </Tr>
                                        :
                                        <Tr>
                                            <Td maxW='240px' overflow='auto' flexWrap='nowrap'>Nextswanでの販売数上限</Td>
                                            <Td>制限なし</Td>
                                        </Tr>
                                        }
                                        { post.remain===0 ?
                                        <Tr>
                                            <Td maxW='240px' overflow='auto' flexWrap='nowrap'>Nextswanでの在庫状況</Td>
                                            <Td>売り切れ</Td>
                                        </Tr>
                                        : "" }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                            <Box pt="20px">
                                <Flex>
                                    <Spacer/>
                                    <HStack align='right' >
                                        <ShowPriceOrange price={post.market_price} />
                                        <Text fontSize="xl" pt={3}>円</Text>
                                    </HStack>
                                </Flex>
                                <Flex pt={2}>
                                    <Spacer/>
                                    <HStack>
                                        <Text fontSize="md" color="#797979">プライマリー価格</Text>
                                        <Popover isLazy trigger="hover">
                                            <PopoverTrigger>
                                                <QuestionOutlineIcon
                                                    cursor='pointer'
                                                    color="#797979"
                                                />
                                            </PopoverTrigger>
                                            <PopoverContent w='240px' align='center' borderRadius={10}>
                                                <PopoverArrow />
                                                <PopoverCloseButton _focus={{ boxShadow: "none"}}/>
                                                <Text align='center' pt={2} >プライマリー価格とは、</Text>
                                                <Text align='center' pt={2} >Nextswanで直接</Text>
                                                <Text align='center' pt={2} pb={2}>購入する場合の価格です。</Text>
                                            </PopoverContent>
                                        </Popover>
                                        <Text fontSize="md" color="#797979">:&nbsp;</Text>
                                        <ShowPriceGray price={post.price}/>
                                    </HStack>
                                </Flex>
                                {/* <Center pt={10} >
                                    { post.soldOut ? (
                                        <VStack>
                                            <Button
                                                pointerEvents='none'
                                                cursor='unset'
                                                width="300px"
                                                bg={"#E6A18A"}
                                                color={'white'}
                                                variant='solid'
                                                _focus={{ boxShadow: "none"}}
                                            >売り切れ</Button>
                                            <Flex w='300px' >
                                                <Spacer/>
                                                <Link as={ReachLink} to={'/m/s?q=' + post.title} _focus={{ boxShadow: "none"}}>
                                                    <HStack>
                                                        <Text as='u' fontSize='sm'>
                                                            Nextswan リセールでさがす
                                                        </Text>
                                                        <Search2Icon color='#E67D5A' w={4} h={4} />
                                                    </HStack>
                                                </Link>
                                            </Flex>
                                        </VStack>
                                    ) : "" }
                                    { !post.soldOut && post.purchase ? (
                                        <Button
                                            pointerEvents='none'
                                            cursor='unset'
                                            width="300px"
                                            bg={"#E6A18A"}
                                            color={'white'}
                                            variant='solid'
                                            _focus={{ boxShadow: "none"}}
                                        >同じコンテンツを所有しています</Button>
                                    ) : "" }
                                    { !post.soldOut && !post.purchase && !post.is_me && post.is_login ? (
                                        <Button
                                            align='center'
                                            width="300px"
                                            bg={"#E67D5A"}
                                            color={'white'}
                                            _hover={{ opacity: 0.8 }}
                                            variant='solid'
                                            _focus={{ boxShadow: "none"}}
                                            onClick={ payment_reserve }
                                        >今すぐ購入する
                                        </Button>
                                    ) : "" }
                                    { !post.soldOut && !post.purchase && !post.is_me && !post.is_login ? (
                                        <Button
                                            width="300px"
                                            bg={"#E67D5A"}
                                            color={'white'}
                                            _hover={{ opacity: 0.8 }}
                                            variant='solid'
                                            _focus={{ boxShadow: "none"}}
                                            onClick={need_login}
                                        >ログインして購入する
                                        </Button>
                                    ) : "" }
                                </Center> */}
                            </Box>
                            <Box pt="50px">
                                { post.is_login || isComment ? <Text fontSize="3xl" pb='4px' >コメント</Text> : ""}
                                { post.is_login ? (
                                <Center>
                                    {/* コメントの入力フォーム */}
                                    <HStack pt={5} align='center' w='400px'>
                                        <FormControl>
                                            <Textarea id='name' value={input} size='lg' placeholder='コメントする' onChange={handleInputChange} focusBorderColor='#E67D5A'/>
                                        </FormControl>
                                        <Button
                                            width="50px"
                                            bg={"#E67D5A"}
                                            color={'white'}
                                            type='submit'
                                            _hover={{ opacity: 0.8 }}
                                            _focus={{ boxShadow: "none"}}
                                            leftIcon={<FaPaperPlane color='white'/>}
                                            onClick={send_comment}
                                        >
                                        </Button>
                                    </HStack>
                                </Center>
                                ) : "" }
                                <List>
                                    {comments.map((value, key) => {
                                        return (
                                            // コメント欄の実装
                                            <Box key={key}>
                                                <Flex py={3}>
                                                    <ListItem alignItems="flex-start" py={2} pr={3}>
                                                        {/* 出品者なら共通のデフォルトアイコン、それ以外は各自で設定したアイコン */}
                                                        { value.isSeller ? (
                                                            <Avatar as={FaUserTie} p={1} alt="" bg='#E67D5A' color='white' />
                                                        ) : (
                                                            <Avatar alt="" src={ value.icon } />
                                                        )}
                                                    </ListItem>
                                                    <Stack pt={2}>
                                                        <HStack>
                                                            {/* 出品者は匿名のため「出品者」と表示し、それ以外は各自のユーザーネームを表示 */}
                                                            <Text fontSize='sm'>
                                                                {value.isSeller ? '出品者' : value.userName}
                                                            </Text>
                                                            <ShowTime date0={value.created_at} size='sm'/>
                                                        </HStack>
                                                        <Text>
                                                            {value.message}
                                                        </Text>
                                                    </Stack>
                                                </Flex>
                                                <Divider w='100%' />
                                            </Box>
                                        );
                                    })}
                                </List>
                            </Box>
                            {/* aboutページ */}
                            <VStack pt="20px">
                                <Heading as='h2' size='xl' pt='50px' pb='30px'>
                                    Nextswanとは？
                                </Heading>
                                <AboutPoint color='orange.300'/>
                                <AboutStep color='orange.300'/>
                            </VStack>
                        </Stack>
                    </Center>
                </VStack>
                {isMobile ? 
                    <Center>
                        <HStack style={{ position: 'fixed', bottom: '30px'}} spacing={6} >
                            {/* <Icon as={FaLink} color='#E9B5D2' w={9} h={9} onClick={() => copyTextToClipboard('https://meltly.co.jp/')} /> */}
                            <Box borderRadius='50%' bg='white' shadow='lg' w={12} h={12} p={2} >
                                <Link href={"https://twitter.com/share?text=" + encodeURIComponent(post.title) + "&url=https://nextswan.com/m/" + marketid + "&hashtags=Nextswan"} onClick={send_share}>
                                    <Icon as={FaTwitter} color='gray.400' pb={1} pr={1} w={9} h={9} />
                                </Link>
                            </Box>
                            <Box borderRadius='50%' bg='white' shadow='lg' w={12} h={12} p={2} >
                                <Link href={"https://social-plugins.line.me/lineit/share?url=https://nextswan.com/m/" + marketid} onClick={send_share}>
                                    <Icon as={FaLine} color='gray.400' pb={1} pr={1} w={9} h={9} />
                                </Link>
                            </Box>
                            <Box borderRadius='50%' bg='white' shadow='lg' w={12} h={12} p={2} >
                                <Icon as={FaRegCopy} cursor='pointer' color='gray.400' pt={1} pl={1} w={7} h={7} onClick={() => copyTextToClipboard('https://nextswan.com/m/' + marketid)}  />
                            </Box>
                        </HStack>
                    </Center>
                :
                    <VStack position='fixed' right={{base: '30px', lg: '80px', xl: '160px'}} bottom='200px' /*style={{ position: 'fixed', right: '100px', bottom: '120px'}}*/ >
                        <VStack spacing={4}>
                            <Icon as={FaLink} cursor='pointer' color='gray.400' w={9} h={9} mb={1} onClick={() => copyTextToClipboard('https://nextswan.com/m/' + marketid)} />
                            <Link href={"https://twitter.com/share?text=" + encodeURIComponent(post.title) + "&url=https://nextswan.com/m/" + marketid + "&hashtags=Nextswan"} target="_blank" rel="noreferrer" _focus={{ boxShadow: "none"}} onClick={send_share}>
                                <Icon as={FaTwitter} color='gray.400' w={9} h={9} />
                            </Link>
                            <Link href={"https://social-plugins.line.me/lineit/share?url=https://nextswan.com/m/" + marketid} target="_blank" rel="noreferrer" _focus={{ boxShadow: "none"}} onClick={send_share}>
                                <Icon as={FaLine} color='gray.400' w={9} h={9} />
                            </Link>
                            <Link href={'https://www.facebook.com/share.php?u=https://nextswan.com/m/' + marketid} target="_blank" rel="noreferrer" _focus={{ boxShadow: "none"}} onClick={send_share}>
                                <Icon as={FaFacebook} color='gray.400' w={9} h={9} />
                            </Link>
                        </VStack>
                    </VStack>
                }
            </>
        )
    }
}